import moment from "moment";
import React, { Component } from "react";
import { FormValidate } from "../../../../components/formValidate";
import CustomButton from "../../../../components/inputs/button";
import DatePickerField from "../../../../components/inputs/datepicker";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";
import { admin_event_add } from "../../admin.api";
import "./dialog.css";

let required = [
  "event_title_en",
  "event_title_ma",
  "event_date",
  "events_desc_en",
  "paid",
  "events_desc_ma",
  "event_title_timing",
  "event_image",
  "event_contact_no",
  "location_en",
  "location_ma",
  "booking_start_date",
  "booking_close_date",
  // "available_seats",
  "bank_details",
  "total_seats",
];
let OthRequired = [
  "event_title_en",
  "event_title_ma",
  "event_date",
  "events_desc_en",
  "event_image",
  "events_desc_ma",
  "event_title_timing",
  "oraganized_by_en",
  "oraganized_by_ma",
  "event_contact_no",
  "location_en",
  "location_ma",

  // "available_seats",

  // "total_seats",
];

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      fileChanged: false,
      snackMessage: "",

      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  componentDidMount = () => {};
  checkStatus;
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleEventDate = (e) => {
    //
  };
  handleDatePickerChange = (e, name) => {
    let date = e.value;
    if (date) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: date,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: null,
        },
      });
    }
  };
  handleFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // if (height === 400 || width === 400) {
          if (height === 258 || width === 318) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          this.setState({
            fileChanged: true,
            formData: {
              ...this.state.formData,
              file_name: e.target.files[0]?.name,
              [e.target.name]: e.target.files[0],
            },
          });
        } else {
          this.setState({
            snackMessage: "Incorrect image dimension",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }, 200);
    } else {
      this.setState({
        snackMessage: "File should be in .jpg / .jpeg / .png format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  checkDates = () => {
    let isAddable = false;
    if (this.state.formData.event_organized_type === "kspace") {
      let evDate = moment(
        this.state.formData.event_date,
        "DD-MM-YYYY",
        true
      ).format();
      let stDate = moment(
        this.state.formData.booking_start_date,
        "DD-MM-YYYY",
        true
      ).format();
      let enDate = moment(
        this.state.formData.booking_close_date,
        "DD-MM-YYYY",
        true
      ).format();
      if (evDate > enDate) {
        if (enDate > stDate) {
          isAddable = true;
        } else {
          isAddable = false;
          this.setState({
            snackMessage:
              "Please select valid dates for Booking Start and End Date",
            isSnackError: true,
            snackOpen: true,
          });
        }
      } else {
        isAddable = false;
        this.setState({
          snackMessage:
            "Event Date should be greater than the Booking End Date",
          isSnackError: true,
          snackOpen: true,
        });
      }
    } else {
      isAddable = true;
    }
    return isAddable;
  };
  handleSubmit = (e) => {
    let reqFields =
      this.state.formData.event_organized_type === "kspace"
        ? required
        : OthRequired;

    const error = FormValidate(this.state.formData, reqFields);

    const { edit, formData } = this.state;
    e.preventDefault();
    console.log(error);
    if (
      formData.event_image !== null &&
      formData.event_image !== undefined &&
      formData.event_image !== ""
    ) {
      this.setState({ isActive: true });
      let flag = this.checkDates();

      if (flag) {
        if (formData.event_contact_no.length === 10) {
          if (Object.keys(error).length === 0) {
            const form = new FormData();
            form.append("event_title_en", formData.event_title_en);
            form.append("event_title_ma", formData.event_title_ma);
            form.append("event_date", formData.event_date);
            if (
              formData.booking_start_date !== undefined &&
              formData.booking_start_date !== null &&
              formData.booking_start_date !== ""
            ) {
              form.append("booking_start_date", formData.booking_start_date);
            }

            if (
              formData.booking_close_date !== undefined &&
              formData.booking_close_date !== null &&
              formData.booking_close_date !== ""
            ) {
              form.append("booking_close_date", formData.booking_close_date);
            }
            form.append("events_desc_en", formData.events_desc_en);

            form.append("event_title_timing", formData.event_title_timing);
            form.append("events_desc_ma", formData.events_desc_ma);
            form.append("location_en", formData.location_en);
            form.append("location_ma", formData.location_ma);
            if (
              formData.total_seats !== undefined &&
              formData.total_seats !== null &&
              formData.total_seats !== ""
            ) {
              form.append("total_seats", formData.total_seats);
              form.append("available_seats", formData.total_seats);
            }
            form.append("event_contact_no", formData.event_contact_no);
            form.append("file_name", formData.file_name);
            if (formData.event_organized_type === "kspace") {
              form.append("bank_details", formData.bank_details);
            }

            if (formData.event_organized_type === "kspace") {
              form.append("oraganized_by_en", "kSpace");
              form.append("oraganized_by_ma", "കെസ്‌പെയ്‌സ്");
            } else {
              form.append("oraganized_by_en", formData.oraganized_by_en);
              form.append("oraganized_by_ma", formData.oraganized_by_ma);
            }
            if (formData.paid) {
              form.append("paid", formData.paid);
            } else {
              form.append("paid", 0);
            }
            if (formData.free) {
              form.append("free", formData.free);
            } else {
              form.append("free", 0);
            }
            if (formData.event_organized_type === "kspace") {
              form.append("event_organized_type", "kspace");
            } else {
              form.append("event_organized_type", "others");
            }
            if (formData.id) form.append("event_id", formData.id);
            form.append(
              "event_image",
              this.state.fileChanged ? formData.event_image : null
            );

            admin_event_add(form)
              .then((res) => {
                this.setState({
                  submitted: true,

                  snackMessage: res.message,
                  isSnackError: false,
                  snackOpen: true,
                });
              })
              .catch((error) => {
                this.setState({
                  submitted: false,
                  snackMessage: error.message,
                  isSnackError: true,
                  snackOpen: true,
                });
              });
          } else {
            this.setState({
              submitted: false,
              snackMessage: "Please fill all mandatory fields",
              isSnackError: true,
              snackOpen: true,
            });
          }
        } else {
          this.setState({
            submitted: false,
            snackMessage: "Enter a valid 10 digit Contact Number",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please select a valid event Image",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    this.setState({ formData: { ...this.state.formData, [name]: newValue } });
  };
  handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    this.setState({ formData: { ...this.state.formData, [name]: newValue } });
  };
  manageOrgType = (e) => {
    let value = e.target.value;
    if (value === "kspace") {
      this.setState({
        formData: { ...this.state.formData, event_organized_type: "kspace" },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, event_organized_type: "others" },
      });
    }
  };
  render() {
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;

    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia-event-create dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Events" : view ? "Event" : "Add Events"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tilebar">
                  <hr />
                </div>

                <div className="row event-book-form">
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Event Name[Eng]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.event_title_en ?? ""}
                      name="event_title_en"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Event Name[Mal]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.event_title_ma ?? ""}
                      name="event_title_ma"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <div className="fieldset">
                      <label className="control-label mylabel">
                        Organized By
                      </label>
                      <select
                        className="form-select input-group.mandatory disabled"
                        aria-label="Default select example"
                        name="main_category"
                        onClick={(e) => this.manageOrgType(e)}
                      >
                        <option
                          value="kspace"
                          selected={formData.event_organized_type === "kspace"}
                        >
                          kSpace
                        </option>
                        <option
                          value="other"
                          selected={formData.event_organized_type === "others"}
                        >
                          Others
                        </option>
                      </select>
                    </div>
                  </div>
                  {formData.event_organized_type === "kspace" && (
                    <div className="col-6 fieldset">
                      <TextField
                        id="outlined-basic"
                        disabled={view}
                        label="Event Fee"
                        variant="outlined"
                        onChange={(e) => this.handleNumberChange(e)}
                        value={formData.paid ?? ""}
                        name="paid"
                        type="text"
                        required
                      />
                    </div>
                  )}
                  {formData.event_organized_type === "others" ? (
                    <React.Fragment>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Organizer[Eng]"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.oraganized_by_en ?? ""}
                          name="oraganized_by_en"
                          required
                        />
                      </div>

                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Organizer[Mal]"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.oraganized_by_ma ?? ""}
                          name="oraganized_by_ma"
                          required
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="col-12">
                      <TextField
                        id="outlined-basic"
                        multiline
                        disabled={view}
                        label="Bank Details"
                        variant="outlined"
                        onChange={(e) => this.handleChange(e)}
                        value={formData.bank_details ?? ""}
                        name="bank_details"
                        required
                      />
                    </div>
                  )}

                  <div className="col-6 fieldset">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Event Time"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.event_title_timing ?? ""}
                      name="event_title_timing"
                      required
                    />
                  </div>

                  <div className="col-6 col-set">
                    <DatePickerField
                      // required
                      value={formData.event_date ?? null}
                      minDate={tomorrow}
                      disabled={view}
                      label="Event Date"
                      required
                      onChange={(e) =>
                        this.handleDatePickerChange(e, "event_date")
                      }
                    />
                  </div>
                  {formData.event_organized_type === "kspace" && (
                    <>
                      <div className="col-6">
                        <DatePickerField
                          required
                          value={formData.booking_start_date ?? null}
                          minDate={tomorrow}
                          disabled={view}
                          label="Booking Start Date"
                          // className="W-100"
                          // required
                          onChange={(e) =>
                            this.handleDatePickerChange(e, "booking_start_date")
                          }
                        />
                      </div>
                      <div className="col-6">
                        <DatePickerField
                          required
                          value={formData.booking_close_date ?? null}
                          minDate={tomorrow}
                          disabled={view}
                          label="Booking End Date"
                          // required
                          onChange={(e) =>
                            this.handleDatePickerChange(e, "booking_close_date")
                          }
                        />
                      </div>
                    </>
                  )}
                  {formData.event_organized_type === "kspace" && (
                    <div className="col-6">
                      <TextField
                        id="outlined-basic"
                        disabled={view}
                        label="Total Seats"
                        variant="outlined"
                        pattern="[0-9]"
                        // onChange={(e) => this.handleChange(e)}
                        onChange={(e) => this.handleNumberChange(e)}
                        value={formData.total_seats ?? ""}
                        name="total_seats"
                        type="text"
                        required={
                          formData.event_organized_type === "others"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}

                  {/* <div
                    className={
                      formData.event_organized_type === "kspace"
                        ? "col-3"
                        : "col-6"
                    }
                  >
                     <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Available Seats"
                      variant="outlined"
                      pattern="[0-9]"
                      // onChange={(e) => this.handleChange(e)}
                      onChange={(e) => this.handleNumberChange(e)}
                      value={formData.available_seats ?? ""}
                      name="available_seats"
                      type="text"
                      required
                    /> 
                  </div> */}
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Location[Eng]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.location_en ?? ""}
                      name="location_en"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Location[Mal]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.location_ma ?? ""}
                      name="location_ma"
                      required
                    />
                  </div>

                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Contact Number"
                      variant="outlined"
                      maxLength="10"
                      minLength="10"
                      pattern="[0-9]{10}"
                      onChange={(e) => this.handlePhoneNumberChange(e)}
                      value={formData.event_contact_no ?? ""}
                      name="event_contact_no"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Description[Eng]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.events_desc_en ?? ""}
                      name="events_desc_en"
                      multiline
                      required
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Description[Mal]"
                      variant="outlined"
                      onChange={(e) => this.handleChange(e)}
                      value={formData.events_desc_ma ?? ""}
                      name="events_desc_ma"
                      multiline
                      required
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="outlined-basic"
                      disabled={view}
                      label="Image [height:258px, width : 318px ,format: .jpg , .jpeg , .png]"
                      name="event_image"
                      type="file"
                      variant="outlined"
                      required={formData.file_name ? false : true}
                      fileName={formData.file_name}
                      // value={formData.event_image ?? ""}
                      onChange={(e) => this.handleFileChange(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 event-dia-action">
                    <CustomButton
                      label={edit ? "Update" : "Add"}
                      disabled={submitted}
                      onClick={(e) => this.handleSubmit(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
