import moment from "moment";
import React, { useState, useEffect } from "react";

import CustomIcons from "../../../icons/kIcons.component";

export default function EventTile(props) {
  const [data, setData] = useState(props.data);
  const [url, setUrl] = useState(props.url);
  let lang = props.state.language;

  useEffect(() => {
    setData(props.data);
    setUrl(props.url);
  }, [props.data, props.url]);
  const fetchDetails = (id) => {
    if (props.fromHome) {
      sessionStorage.setItem("continueFrom", "events");
    }
    let path = `/event/${id}`;
    props.history.push(path);
  };
  return (
    <div className="event-tile-box-container">
      <div className="event-preview-img">
        <img src={`${data[lang]?.event_image}`} className="event-prv" alt="" />
      </div>

      {/* <div className="tri-container">
          <div className="right-tri"></div>
          <div className="left-tri"></div>
        </div> */}
      <div className=" ">
        <div className="row new-event-info-details">
          <div className="col-6">
            <div className="d-flex">
              <div className="icon-to-center">
                <img
                  src="/resources/img/location.png"
                  className="event-icons"
                />
              </div>
              <div className="event-venue" title={data[lang]?.location}>
                {data[lang]?.location}
              </div>
            </div>
          </div>
          <div className="col-6">
            {" "}
            <div className="d-flex justify-content-end">
              <div className="icon-to-center">
                <img src="/resources/img/date.png" className="event-icons" />
              </div>
              <div className="event-venue">{data[lang]?.eventDate}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-tile-data-container ">
        <div className="event-tile-box-title">{data[lang]?.Title}</div>

        <div className="event-tile-box-desc">{data[lang]?.Description}</div>
        <div className="priceCont">
          <div
            className={
              data[lang]?.event_organized_type === "kspace"
                ? "d-flex"
                : "d-none"
            }
          >
            <div className="event-fee-title">Fee :</div>
            <div className="event-fee-value-RUPPE">₹</div>
            <div className="event-fee-value">
              {data[lang]?.paid}
              <span className="value-end">/-</span>
            </div>
          </div>
        </div>
        <div>
          <div
            className={
              Number(data[lang]?.paid) > 0
                ? "evnt-tile-btns-cont"
                : "evnt-tile-btn-cont"
            }
          >
            <button
              className="read-more-btn-event"
              onClick={(e) => {
                fetchDetails(data[lang]?.uuid);
              }}
            >
              Read More
            </button>
            {data[lang]?.event_organized_type === "kspace" && (
              <button
                className="book-now-btn-event"
                onClick={(e) => {
                  fetchDetails(data[lang]?.uuid);
                }}
              >
                Book Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
