import { Button } from "bootstrap";
import React from "react";
import { useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import "../style.css";

export default function View(props) {
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  console.log(props.basicData);
  const [agree, setAgree] = useState(false);
  const handleCheckChange = (e) => {
    let checked = e.target.checked;
    console.log(checked);
    setAgree(checked);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.action(2);
  };
  console.log(props.basicData?.work_experience?.file_name);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="view-container">
          <h6>
            <b>Basic Details</b>
          </h6>

          <hr />

          <div className="data">
            <div className="row">
              <div className="col-3 field-titles">Manpower Type</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.category ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Full Name</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.full_name ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Age</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.age ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Gender</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.gender === 1
                  ? "Male"
                  : props.basicData?.gender === 2
                  ? "Female"
                  : "Other"}
              </div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Photo</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.file_name ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Address</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.address}</div>
            </div>

            <div className="row">
              <div className="col-3 field-titles">Email</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.email ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Phone Number</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.mobile ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Area of Expertise</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.expertise_area?.map((area, index) => (
                  <>
                    {index !== 0 && <> |&nbsp;</>}
                    {area.expertise_name}
                  </>
                ))}
              </div>
            </div>
            {props.update === true ? (
              <div className="row">
                <div className="col-3 field-titles">Uploaded Photo</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(
                        `${result}${props.basicData?.photo}`,
                        "_blank"
                      );
                    }}
                    className="detailFile"
                  >View</a>
                  
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Uploaded Photo</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{props.basicData?.file_name ?? ""}</div>
              </div>
            )}
            {/* <div className="row">
              <div className="col-3 field-titles">
                Any other area of interest
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.area_of_interest?.map((area, index) => (
                  <>
                    {index !== 0 && <> |&nbsp;</>}
                    {area.area_of_interest_name}{" "}
                  </>
                ))}
              </div>
            </div> */}
            {/* <div className="row">
            <div className="col-3 field-titles">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div> */}
          </div>
          <h6>
            <b>Educational Qualifications</b>
          </h6>

          <hr />

          <div className=" mobix data">
            <div className="row">
              {props.basicData?.educational_qualifications?.map((item) => (
                <div className="data-cards">
                  <div className="col-12 mb-1">
                    <div className="row">
                      <div className="col-3 field-titles">
                        Examination passed
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.examination_passed}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Board/University</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.board_of_university}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Year of Passing</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.year_of_pass}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Mark/grade</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.mark_grade}</div>
                    </div>
                    {props.update === true ? (
              <div className="row">
                <div className="col-3 field-titles">Uploaded File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(
                        `${result}${item?.file_upload_certificate}`,
                        "_blank"
                      );
                    }}
                    className="detailFile"
                  >View</a>
                  
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Uploaded File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{item.file_name}</div>
              </div>
            )}
                    {/* {props.update === true && (
                      <div className="row">
                        <div className="col-3 field-titles">Uploaded File</div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">
                          <a
                            href={`${result}/${item.file_name}`}
                            target="_blank"
                          ></a>
                          View
                        </div>
                      </div>
                    )}
                    {!props.update && (
                      <div className="row">
                        <div className="col-3 field-titles">Uploaded File</div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">{item.file_name}</div>
                      </div>
                    )} */}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h6>
            <b>Experience Details</b>
          </h6>

          <hr />
          <div className="data">
            <div className="row">
              {props.basicData?.work_experience?.map((item) => (
                <div className="mobix data-cards">
                  <div className="col-12 mb-1">
                    <div className="row">
                      <div className="col-3 field-titles">
                        Organization with Address
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.organization_name}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Experience</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.experience}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Nature of Work</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.nature_of_work}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Designation</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.designation}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">
                        Reason for Leaving
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.reason_for_leaving}</div>
                    </div>
                    {/* <div className="row">
                      <div className="col-3 field-titles">Uploaded File</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.file_name}</div>
                    </div> */}
                    {props.update === true ? (
              <div className="row">
                <div className="col-3 field-titles">Uploaded File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(
                        `${result}${item?.experience_certificate}`,
                        "_blank"
                      );
                    }}
                    className="detailFile"
                  >View</a>
                  
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Uploaded File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{item.file_name}</div>
              </div>
            )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex">
            <div>
              <center>
                <CommonForm
                  fieldType="checkbox"
                  value={agree}
                  required
                  checked={agree}
                  onChange={(e) => handleCheckChange(e)}
                  label=""
                  {...props}
                />
              </center>
            </div>
            <div>
              I hereby declare that the details furnished above are true and
              correct to the best of my knowledge and belief. In case any of the
              above information is found to be false or untrue or misleading or
              misrepresenting, I am aware that I may be held liable for it.
            </div>
          </div>
          <div className="footer-buttons mt-3">
            <div
              className="secondary-btn"
              onClick={(e) => {
                props.action(1);
              }}
            >
              GO BACK
            </div>

            <button className="btn primary-btn" type="submit">
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
