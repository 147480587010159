import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import { EmailValidate } from "../../../../../components/formValidate";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { is_email_registered } from "../../../user.api";
import Swal from "sweetalert2";
let reuired = [
  // "company_name",
  // "person_name",
  // "address",
  // "email",
  // "abstract_project_proposal",
  // "ex_man_power",
  // "capital_investment",
  // "upload_proposal",
];
let flag = false;
export default function BasicDetails(props) {
  const [formData, setFormData] = useState(props.formData);

  const [keyvalue, setKeyvalue] = useState(0);
  const [count, setCount] = useState(1);
  const [error, setError] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  useEffect(() => {
    setFormData(props.formData);
    setKeyvalue((k) => k + 1);
  }, [props.formData]);
  // useEffect(() => {
  //   if (!formData.expertise_area) {
  //     setFormData({
  //       ...formData,
  //       expertise_area: [
  //         {
  //           expertise_name: "",
  //         },
  //       ],
  //     });
  //   }
  // }, [formData, formData.expertise_area]);

  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setError({ ...error, [e.target.name]: erroremail });
    }
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  const addExpertice = (i) => {
    let data = { ...formData };

    data.expertise_area.push({
      expertise_name: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleNextSubmit = (e) => {
    e.preventDefault();
    let emailData = { email: formData.email };
    if (props.update === false) {
      is_email_registered(emailData)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          // setIsActive(false);
          props.action(0, formData);
          setSnackOpen(true);
        })
        .catch((error) => {
          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setSnackOpen(true);
          // setIsActive(false);
        });
    } else {
      props.action(0, formData);
    }
    // const errors = FormValidate(formData, reuired);
    // if (Object.keys(errors).length === 0) {

    // } else {
    //   setSnackMessage("Please fill all reuired fields");
    //   setIsSnackError(false);
    //   setSnackOpen(true);
    // }
  };
  const handleValid = (type) => {
    let flags = false;

    if (type === "domain") {
      if (props.domainData) {
        props.domainData.forEach((item) => {
          if (item.checked) flags = true;
        });
      }
    } else if (type === "areaofinterest") {
      props.areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    } else if (type === "language") {
      props.languageData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleCheckChange = (e, type, item) => {
    flag = false;
    if (type === "domain") {
      let data = [...props.domainData];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      props.setDomain(data);
    } else if (type === "areaofinterest") {
      let data = [...props.areaOfInterest];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      props.setAreaofInterest(data);
    } else if (type === "language") {
      let data = [...props.languageData];

      data[item]["checked"] = e.target.checked;
      props.setLanguageData(data);
    }
  };
  const handleOtherChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleOtherLangChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let mb = size / Math.pow(1024, 2);

    if (mb < 1.5) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setSnackMessage("File size is greater than 5MB");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  // useEffect(() => {
  //   if (!formData.otherLanguages) {
  //     setFormData({
  //       ...formData,
  //       otherLanguages: [{ language: "" }],
  //     });
  //   }
  // }, [formData, formData.otherLanguages]);
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };
  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: Number(e.target.value),
    };
    setFormData(data);
  };
  const consentFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      is_willing_to_travel: Number(e.target.value),
    };
    setFormData(data);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.otherLanguages[i] = {
      ...data.otherLanguages[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const addLanguage = (i) => {
    let data = { ...formData };

    data.otherLanguages.push({ language: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const removeLanguage = (i) => {
    let data = { ...formData };
    data.otherLanguages.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };

  return (
    <div className="">
      <form onSubmit={handleNextSubmit}>
        <div className="col-xs-12 col-sm-12 mt-1 mb-1">
          <CommonForm
            fieldType="text"
            label="Full Name"
            required
            name="full_name"
            value={formData?.full_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <CommonForm
              fieldType="text"
              label="Age"
              required
              name="age"
              maxLength="3"
              value={formData?.age ?? ""}
              onChange={(e) => {
                numberChange(e);
              }}
              {...props}
            />
          </div>
          <div className="col-xs-12 col-sm-6 mt-3 d-flex justify-content-start align-items-center">
            <div className="control-label ">Gender :</div>
            <div className="d-flex ">
              <CommonForm
                fieldType="radio"
                label="Male"
                required
                name="gender"
                value={1}
                checked={formData?.gender == 1}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Female"
                required
                name="gender"
                checked={formData?.gender == 2}
                value={2}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Other"
                required
                name="gender"
                checked={formData?.gender == 3}
                value={3}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 my-1">
          <CommonForm
            fieldType="text"
            label="Address"
            multiline
            required
            className="no-margin"
            name="address"
            value={formData?.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12 my-1">
          <CommonForm
            fieldType="email"
            label="Email"
            // disabled
            value={formData?.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            required
            name="email"
            {...props}
          />
        </div>

        <div className="my-2">
          <div className="header-label">Languages Known</div>
          <div
          // className="check-box-cont"
          >
            <div className="check-box-cont">
              {props.languageData &&
                props.languageData.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    required={
                      !formData.any_other_lang && !handleValid("language")
                    }
                    value={item.checked}
                    checked={item.checked}
                    onChange={(e) => handleCheckChange(e, "language", index)}
                    label={item.language}
                    {...props}
                  />
                ))}

              <CommonForm
                fieldType="checkbox"
                checked={formData.any_other_lang}
                value={formData.any_other_lang}
                name="any_other_lang"
                onChange={handleOtherLangChange}
                label={"Others"}
                {...props}
              />
            </div>
            {formData.any_other_lang && (
              <>
                {formData.otherLanguages?.map((e, i) => (
                  <>
                    <div>
                      <CommonForm
                        fieldType="text"
                        label="Language"
                        required
                        name="language"
                        value={e?.language}
                        onChange={(e) => {
                          handleMultiChange(e, i);
                        }}
                        {...props}
                      />
                    </div>

                    <div className="more-btn-cnt">
                      {formData.otherLanguages.length === i + 1 && (
                        <button
                          style={{ marginLeft: 5 }}
                          type="button"
                          {...props}
                          className="btn btn-primary"
                          onClick={(e) => addLanguage(i)}
                        >
                          Add More
                        </button>
                      )}
                      {formData.otherLanguages.length > 1 && (
                        <button
                          {...props}
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => removeLanguage(i)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <div>
          <div className="header-label">Domain Type</div>
          <div className="check-box-cont">
            {props.domainData &&
              props.domainData.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  value={item.checked}
                  required={!formData.any_other_flag && !handleValid("domain")}
                  checked={item.checked}
                  onChange={(e) => handleCheckChange(e, "domain", index)}
                  label={item.domain_name}
                  {...props}
                />
              ))}

            <CommonForm
              fieldType="checkbox"
              onChange={handleOtherChange}
              label="Any Other"
              name="any_other_flag"
              checked={formData.any_other_flag}
              value={formData.any_other_flag}
              {...props}
            />
          </div>
          {/* if any other field checked ,below is mandatory */}
          {formData.any_other_flag && (
            <div>
              <CommonForm
                fieldType="text"
                placeholder="Please specify the Domain.."
                required
                name="any_other_domain"
                value={formData.any_other_domain ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          )}
          {/* ////////////////////////////// */}
        </div>

        <div>
          {formData.expertise_area?.map((e, i) => (
            <div className="section-datas-cont mt-1">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <CommonForm
                fieldType="text"
                label="Area of Expertise"
                required
                value={e?.expertise_name}
                onChange={(e) => {
                  handleExperticeChange(e, i);
                }}
                className="no-margin"
                name="expertise_name"
                {...props}
              />
              {/* </div> */}

              <div className="more-btn-cnt">
                {formData.expertise_area.length === i + 1 && (
                  <button
                    style={{ marginLeft: 5 }}
                    type="button"
                    {...props}
                    className="btn btn-primary"
                    onClick={(e) => addExpertice(i)}
                  >
                    Add More
                  </button>
                )}
                {formData.expertise_area.length > 1 && (
                  <button
                    {...props}
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => removeExpertice(i)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* <div className="fieldset">
          <div className="header-label">Any other area of interest</div>
          <div className="check-box-cont">
            {console.log(props.areaOfInterest)}
            {props.areaOfInterest &&
              props.areaOfInterest?.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  required={!handleValid("areaofinterest")}
                  value={item.checked}
                  checked={item.checked}
                  onChange={(e) =>
                    handleCheckChange(e, "areaofinterest", index)
                  }
                  label={item.area_of_interest_name}
                  {...props}
                />
              ))}
          </div>
        </div> */}
        <div className="header-label">
          Willingness to travel within India / abroad
        </div>
        <div className="check-box-cont">
          <div className="d-flex ">
            <CommonForm
              fieldType="radio"
              label="Yes"
              required
              name="is_willing_to_travel"
              value={1}
              checked={formData?.is_willing_to_travel === 1}
              onChange={(e) => consentFieldChange(e)}
              {...props}
            />
            <CommonForm
              fieldType="radio"
              label="No"
              required
              name="is_willing_to_travel"
              checked={formData?.is_willing_to_travel === 0}
              value={0}
              onChange={(e) => consentFieldChange(e)}
              {...props}
            />
          </div>
        </div>
        {/* ///////////////////////////////////// */}
        {/* ///actions ////////////// */}
        <div className="footer-buttons">
          <button type="submit" className="primary-btn basic-submit">
            SUBMIT TO NEXT STEP
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
