import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../components/table/table";
import "./view.css";
import { get_resList_list, admin_resper_delete } from "../admin.api";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleAdviserList = () => {
    setIsActive(true);
    get_resList_list(adminType).then((res) => {
      console.log(res);
      let tdata = res.data;
      let searchData = [];
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
        // searchData.push(item?.expert_manpower);
      });
      setdata(tdata);
      setIsActive(false);
    });
  };
  const handleAddNew = (row) => {
    let path = `/resource-add`;
    props.history.push(path);
  };

  const handleView = (data) => {
    let path = `/resource-view/${data.user_id}`;
    props.history.push(path);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_resper_delete(data.id)
    
      .then((res) => {
        // setSnackMessage(res.message);
        setSnackMessage("Resource Personnel deleted successfully");
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleAdviserList();
    }
  };
  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  useEffect(() => {
    handleAdviserList();
  }, []);

  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <h6>
          <b>Resource Personnel</b>
        </h6>
        <hr />
        {/* ---------admin--------------- */}
        {adminType === 1 && (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button>
            {/* <div className="row font-z mt-2">
              <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "full_name" },
                { title: "Email", field: "email" },
                { title: "Photo", field: "file_upload", type:"image" },
                { title: "Phone Number", field: "phone_no" },
                // { title: "Area of Expertise", field: "category" },

                {
                  title: "Status",
                  field: "approval_status",
                  type: "regstatusType",
                 
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
        {adminType === 2 && (
          <>
            {/* <div className="row font-z"> */}
            {/* <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "full_name" },
                { title: "Email", field: "email" },
                { title: "Photo", field: "file_upload", type:"image" },
                { title: "Phone Number", field: "phone_no" },
                // { title: "Area of Expertise", field: "category" },

                {
                  title: "Status",
                  field: "approval_status",
                  type: "regstatusType",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Resource Personnel ?"
          data={activeData}
          message="Are you sure to delete this Resource Personnel?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
