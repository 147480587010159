import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../components/table/table";
import ResourseCard from "../../../../components/page/tiles/resourcecard";
import { user_res_per_details, user_resource_detail } from "../../user.api";

import "../style.css";
import BreadCrumbsComp from "../../../../components/navigator/breadcrumbs";
let count = 100;
export default function ResourcePersonalDetails(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const getDetails = (id) => {
    user_resource_detail(id).then((res) => {
      let tdata = res.data[0];

      setData(tdata);
      setIsActive(false);
      console.log(tdata)
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);

  return (
    <div className="tender bg-tender">
      <BreadCrumbsComp section="resoursePerssonalDetails" />
      <div className="container p-50">
        <div className="res-page-cont-details">
          <div className="res-det-con">
            <div className="row tender-head ">
              <h4> Resource Personnel Details </h4>
              <div>
                <div className="container-ul">
                  <hr className="top hr" />
                  <hr className="bottom hr" />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xs-12 ">
                <div className="profile-box ">
                  <div className="res-bannerContainer d-flex ">
                    <div className="res-ban-image">
                      <img
                        src="/resources/img/userdashbanner.png"
                        className="w-100 res-ban-img"
                        alt=""
                      />
                    </div>
                    <div className="resbandata">
                      <div className="res-ban-data">
                        <div>
                          <img
                            src={`${result}${data?.file_upload}`}
                            className="res-profile-img"
                            alt=""
                          />
                        </div>
                        <div className="profile-res-con">
                          <div className="res-person-name">
                            {data?.full_name}
                            {/* tedst */}
                          </div>
                          <div className="qualification-container">
                            <div className="res-exp">Expert in</div>
                            <div className="exp-values">
                              {data?.area_of_expertise && (
                                <>
                                  {data?.area_of_expertise?.map(
                                    (item, index) => (
                                      <>
                                        {index !== 0 && <>&nbsp;|&nbsp;</>}
                                        {item.expertise_name}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="res-about-sec">
                    <div className="res-abo-head">About</div>
                    <hr />
                    <div className="res-about-cont">
                      <div className="row">
                        <div className="col-xs-11 col-md-4">Email</div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.email}
                        </div>

                        <div className="col-xs-11 col-md-4">Age</div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">{data?.age}</div>

                        <div className="col-xs-11 col-md-4">Gender</div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.gender == 1
                            ? "Male"
                            : data?.gender == 2
                            ? "Female"
                            : "Others"}
                        </div>

                        {/* <div className="col-xs-11 col-md-4">
                          Area of Interest
                        </div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.area_of_interest && (
                            <>
                              {data?.area_of_interest?.map((item, index) => (
                                <>
                                  {index !== 0 && <>&nbsp;|&nbsp;</>}
                                  {item.area_of_interest_name}
                                </>
                              ))}
                            </>
                          )}
                        </div> */}
                        <div className="col-xs-11 col-md-4">
                          Languages Known
                        </div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.languages &&
                            data?.languages.map((item, i) => (
                              <>
                                {i > 0 && <span> | </span>}
                                {<span>{item?.language_name}</span>}
                              </>
                            ))}
                        </div>

                        <div className="col-xs-11 col-md-4">Domain Types</div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.domain_types &&
                            
                              data?.domain_types
                            .map((item, i) => (
                              <>
                                {i > 0 && <span> | </span>}
                                {<span>{item.domain_name}</span>}
                              </>
                            ))}
                        </div>
                        <div className="row-padding">
                          <div className="col-12 max-bold">
                            Educational Qualification
                          </div>

                          <div className="col-12  overflow-auto">
                            {" "}
                            <CustomTable
                              className="areatable"
                              {...props}
                              columns={[
                                {
                                  title: " Examination passed",
                                  field: "examination_passed",
                                },
                                {
                                  title: "Board/University",
                                  field: "board_of_university",
                                },
                                {
                                  title: "Year of Passing",
                                  field: "year_of_pass",
                                },
                                { title: "Mark/grade", field: "mark_grade" },
                              ]}
                              data={data?.education ?? []}
                            />
                          </div>
                        </div>
                        <div className="row-padding">
                          <div className="col-12  max-bold">
                            Experience Details
                          </div>

                          <div className="col-12 overflow-auto">
                            {" "}
                            <CustomTable
                              className="areatable"
                              {...props}
                              columns={[
                                {
                                  title: "Organization",
                                  field: "organization_name",
                                },
                                { title: "Experience", field: "experience" },
                                {
                                  title: "Nature of  Work",
                                  field: "nature_of_work",
                                },
                                { title: "Designation", field: "designation" },
                              ]}
                              data={data?.experience ?? []}
                            />
                          </div>
                        </div>
                        <div className="col-xs-11 col-md-4">
                          Willingness to travel within India / abroad
                        </div>
                        <div className="col-xs-1 col-md-1">:</div>
                        <div className="col-xs-12 col-md-7">
                          {data?.travell_interest == 1
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
