import React, { useState, useEffect } from "react";
import Snackbar from "../../../../components/snackbar/snackbar";

import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../../components/table/table";
import "./view.css";
import { institutions_list, super_insti_delete } from "../../admin.api";
import Loader from "../../../../components/loader/loader";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleEventList = () => {
    setIsActive(true);
    institutions_list().then((res) => {
      let tdata = res.data;
      console.log(tdata);
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);

  const handleView = (data) => {
    let path = "/academicinstitution-view/" + data.id;
    props.history.push(path);
  };
  const handleAddNew = () => {
    let path = "/institution-add";
    props.history.push(path);
  };
  const handleEdit = () => {};

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };

  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    super_insti_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Academic Institutions</b>
        </h6>
        <hr />
        {/* --------------admin----- */}

        <CustomTable
          {...props}
          columns={[
            { title: "Institution Name", field: "institution_name" },
            {
              title: "Date of Registration",
              field: "registration_date",
              type: "date",
            },
            { title: "Headquarters", field: "headquaters_detail" },
            { title: "Status", field: "approval_status", type: "regstatusType" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },

                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  // { type: "delete", handleClick: handleDelete },
                ]
          }
          // handleDownload={handleDownload}
        />
      </div>

      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Academic Institution ?"
          data={activeData}
          message="Are you sure to delete this Institution?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
