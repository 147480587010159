import React from "react";
import CheckBox from "../inputs/checkbox";
import RadioSelect from "../inputs/radio";
import TextField from "../inputs/textfield";
import DatePickerField from "../inputs/datepicker";

export default function CommonForm(props) {
  switch (props.fieldType) {
    case "text":
      return <TextField type="text" {...props} />;
    case "number":
      return <TextField type="number" {...props} />;
    case "email":
      return <TextField type="email" {...props} />;

    case "file":
      return <TextField type="file" {...props} />;

    case "multiline":
      return <TextField multiline {...props} />;
    case "checkbox":
      return <CheckBox {...props} />;
    case "select":
      return <CheckBox {...props} />;
    case "radio":
      return <RadioSelect {...props} />;
    case "date":
      return <DatePickerField {...props} />;
    default:
      return null;
  }
}
