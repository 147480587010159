import React, { useState } from "react";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import "./layout.style.css";

export default function Layout(props) {
  const [menuState, setMenuState] = useState(false);
  const [prof, setProf] = useState(false);
  const children = props.children;
  const setMenu = (value) => {
    setMenuState(value);
    setProf(false);
  };

  return (
    <div className="layout">
      <div className="header-container">
        <Header
          {...props}
          menuState={menuState}
          setMenu={setMenu}
          prof={prof}
          setProf={setProf}
        />
      </div>

      <div className="body-container" onClick={(e) => setMenu(false)}>
        <div className=" body-items " onClick={(e) => setMenu(false)}>
          <div style={{ display: "block" }}>
            {children}
            <Footer {...props} />
          </div>
        </div>
        {/* <div className="footer-container">
      
        </div> */}
      </div>
    </div>
    // </div>
  );
}
