import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/table/table";
import "./view.css";
import { get_manpower_details, update_manpower_status } from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleEdit = () => {};
  const handleDownload = (item) => {
    let path = `${result}${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();
  const getDetails = (id) => {
    get_manpower_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadBiodata = () => {
    let path = `${result}${data.biodata}`;
    window.open(path, "_blank");
  };
  const handleDownloadExpCert = (data) => {
    let path = `${result}${data}`;
    window.open(path, "_blank");
  };

  const saMessage = (status) => {
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let mandetails = data[0]?.id;
        console.log(message.value);
        let mdata = {};
        if (adminType === 1) {
          if (status === 1) {
            mdata = {
              status_id: status,
              em_id: mandetails,
              admin_message: message.value,
            };
          } else {
            mdata = {
              status_id: status,
              em_id: mandetails,
              user_message: message.value,
            };
          }
        } else {
          mdata = {
            status_id: status,
            em_id: mandetails,
            super_admin_message: message.value,
          };
        }
        // let data = { status: status, super_admin_message: message.value };
        setIsActive(true);
        update_manpower_status(mdata)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
      }
    });
  };
  const handleAdminActions = (status) => {
    let mandetails = data[0]?.id;
    let mdata = { status_id: status, em_id: mandetails };
    setIsActive(true);
    update_manpower_status(mdata)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/manpower-list");
    }
  };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />

      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Expert Manpower Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Manpower Type</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.category}</div>
          </div>
          <div className="row">
            <div className="col-3">Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.full_name}</div>
          </div>
          <div className="row">
            <div className="col-3">Age</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.age}</div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data[0]?.gender === "1"
                ? "Male"
                : data[0]?.gender === "2"
                ? "Female"
                : data[0]?.gender === "3"
                ? "Other"
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data[0]?.phone_no}</div>
          </div>
          {/* <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              Malayalam&nbsp;&nbsp;|&nbsp;&nbsp;English&nbsp;&nbsp;|&nbsp;&nbsp;Hindi
            </div>
          </div> */}

          <div className="row">
            <div className="col-3">Area of Expertise:</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data[0]?.area_of_expertise &&
                data[0]?.area_of_expertise.length !== 0 && (
                  <>
                    {data[0]?.area_of_expertise?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.expertise_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data.area_of_interest &&
                data[0]?.area_of_interest.length !== 0 && (
                  <>
                    {data[0]?.area_of_interest?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.area_of_interest_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div> */}

          <div className="row">
            <div className="col-3">Educational Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7  mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: " Examination passed", field: "examination_passed" },
                  { title: "Board/University", field: "board_of_university" },
                  { title: "Year of Passing", field: "year_of_pass" },
                  { title: "Mark/grade", field: "mark_grade" },
                  {
                    title: "Certificate",
                    field: "file_upload_certificate",
                    type: "download",
                  },
                ]}
                data={data[0]?.education ?? []}
                handleDownload={handleDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">Experience Details</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data[0]?.experience &&
                data[0]?.experience.length !== 0 && (
                  <>
                    {data[0]?.experience?.map((item, index) => (
                      <div className="white">
                        <div>
                          <b>Organization with Address</b>
                          <p>{item.organization_name}</p>
                        </div>
                        <div>
                          <b>Experience</b>
                          <p>{`${item.experience} Years`}</p>
                        </div>
                        <div>
                          <b>Nature of Work</b>
                          <p>{item.nature_of_work}</p>
                        </div>
                        <div>
                          <b>Designation</b>
                          <p>{item.designation}</p>
                        </div>
                        <div>
                          <b>Reason for Leaving</b>
                          <p>{item.reason_for_leaving}</p>
                        </div>
                        <div>
                          <b>Certificate</b>
                          <p>
                            <button
                              className="btn btn-approve btn-success"
                              onClick={(e) =>
                                handleDownloadExpCert(
                                  item.experience_certificate
                                )
                              }
                            >
                              Download File
                            </button>
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">
              Are you currently attached / committed to any Organization ? Give
              details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">jjjjj</div>
          </div> */}
          <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img
                className="res_photo"
                src={`${result}${data[0]?.photo}`}
                alt=""
              />
            </div>
          </div>
          {data[0]?.admin_message && data[0]?.admin_message !== "" && (
            <div className="row">
              <div className="col-3">Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data[0]?.admin_message}</div>
            </div>
          )}
          {data[0]?.super_admin_message &&
            data[0]?.super_admin_message !== "" && (
              <div className="row">
                <div className="col-3">Super Admin Message</div>
                <div className="col-1">:</div>
                <div className="col-7">{data[0]?.super_admin_message}</div>
              </div>
            )}

          {adminType === 1 && (
            <>
              {(data[0]?.approval_status === 0 || data[0]?.approval_status === 3) && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(1)}
                  >
                    Submit for Acceptance
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(5)}
                  >
                    Revert
                  </button>

                  {/* <button type="button" className="btn btn-reject btn-danger">
                Reject
              </button> */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {" "}
              {data[0]?.approval_status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={(e) => handleAdminActions(2)}
                    className="btn btn-approve btn-success mr-2"
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    onClick={(e) => saMessage(3)}
                    className="btn btn-approve btn-warning mr-2"
                  >
                    Revert
                  </button>
                  <br />
                  <button
                    type="button"
                    onClick={(e) => handleAdminActions(4)}
                    className="btn btn-reject  btn-danger "
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
