import React, { useEffect, useState } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import CustomTable from "../../../components/table/table";
import { newCompanyStatus } from "./userStatus";
import {
  admin_applicationss_list,
  admin_messsage_view,
  superadmin_app_list,
  admin_msg_status_change,
  getUserStatuses,
  admin_applications_search,
  super_newcomp_delete,
  admin_domains_list,
} from "../admin.api";
import Notification from "./notification/notification";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";

import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import "./view.css";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState(null);
  const [activeDomain, setActiveDomain] = useState(null);
  const [domains, setDomains] = useState([]);
  const [msgData, setmsgData] = useState([]);

  let superColumns = [
    { title: "Company Name", field: "company_name" },
    { title: "Application ID", field: "id" },
    { title: "Email", field: "email" },
    {
      title: "Messages",
      field: "to_super_admin_read_status",
      type: "notification",
    },

    {
      title: "Application Status",
      field: "application_status",
      type: "regstatusType",
    },
  ];
  let adminColumns = [
    { title: "Company Name", field: "company_name" },
    { title: "Application ID", field: "id" },
    { title: "Email", field: "email" },
    { title: "Messages", field: "to_admin_read_status", type: "notification" },

    {
      title: "Application Status",
      field: "application_status",
      type: "regstatusType",
    },
  ];
  const [tableColumns, setTableColumns] = useState(adminColumns);
  const [openNotification, setOpenNotification] = useState(false);
  const [activeRow, setActiveRow] = useState({});
  const notificationOpen = (data) => {
    admin_messsage_view(data.uuid).then((res) => {
      setmsgData(res.data);
    });
    setOpenNotification(true);
  };
  const notificationClose = (data) => {
    setOpenNotification(false);
    admin_msg_status_change(activeRow.uuid).then((res) => {
      getReuestList();
    });
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = (type) => {
    if (type === 2) {
      setTableColumns(superColumns);
    } else if (type === 1) {
      setTableColumns(adminColumns);
    }
  };

  const handleView = (item) => {
    if (adminType === 1) {
      props.history.push(`/company-request-view/${item.uuid}`);
    } else if (adminType === 2) {
      props.history.push(`/superadmin-company-request-view/${item.uuid}`);
    }
  };
  const handleNotification = (data) => {
    setActiveRow(data);
    notificationOpen(data);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    // alert("provide api for delete");
    setIsActive(true);
    super_newcomp_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const getReuestList = () => {
    setIsActive(true);
    if (adminType === 1) {
      admin_applicationss_list().then((res) => {
        setData(res.data);
        setIsActive(false);
      });
    } else {
      superadmin_app_list().then((res) => {
        setData(res.data);
        setIsActive(false);
      });
    }
  };
  const getStatusList = () => {
    // getUserStatuses().then((res) => {
      setStatuses(newCompanyStatus);
      // setIsActive(false);
    // });
  };
  const getUserDomains = () => {
    admin_domains_list().then((res) => {
      setDomains(res.data);
    });
  };
  const handleSearch = () => {
    let searchData = {
      status: activeStatus ?? "",
      domain: activeDomain ?? "",
    };
    admin_applications_search(searchData).then((res) => {
      setData(res.data);
    });
  };
  useEffect(() => {
    getReuestList();
    onSearch(adminType);
  }, []);
  useEffect(() => {
    getStatusList();
    
  }, []);
  useEffect(() => {
    getUserDomains();
  }, []);
  const manageDomains = (e) => {
    console.log(e.target.value);
    setActiveDomain(e.target.value);
  };
  const manageStatus = (e) => {
    console.log(e.target.value);
    setActiveStatus(e.target.value);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      getReuestList();
    }
  };
  console.log(activeStatus);
  console.log(activeDomain);
  return (
    <div>
      {openNotification && (
        <Notification
          open={openNotification}
          close={notificationClose}
          msgData={msgData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />

      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>New Proposals</b>
        </h6>
        <hr />

        <div className="row font-z">
          <div className="col-3">
            <select
              className="form-select"
              aria-label="Default select example"
              // value={activeStatus}
              onChange={(e) => manageStatus(e)}
            >
              <option value={""}>Select Status</option>
              {statuses.map((item) => (
                <option
                  value={item.user_status_code}
                  onClick={(e) => manageStatus(e)}
                >
                  {item.user_status_title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <select
              className="form-select"
              aria-label="Default select example"
              // value={activeDomain}
              onChange={(e) => manageDomains(e)}
            >
              <option onClick={(e) => manageDomains(e)} value={""}>
                Select Domain
              </option>
              {domains.map((item) => (
                <option value={item.id} onClick={(e) => manageDomains(e)}>
                  {item.domain_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-1 d-flex">
            <SearchButton {...props} onClick={handleSearch} />
          </div>
        </div>
        <CustomTable
          {...props}
          // columns={superColumns}
          columns={tableColumns}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView }
                ]
          }
          handleNotification={handleNotification}
        />
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Company ?"
          data={activeData}
          message="Are you sure to delete this Proposal?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
