import React from "react";
import CustomTable from "../../../../../../components/table/table";
import "../style.css";

export default function View(props) {
  console.log(props.basicData);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  {
    console.log(props?.basicData);
  }
  return (
    <div>
      <div className="view-container">
        <h6>
          <b>Institution Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Name of the Institution</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.institution_name ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Registered Office Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.address ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Date of Registration</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.registration_date ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Total Number of Students</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.total_students}</div>
          </div>

          {props.update === true &&
          typeof props?.basicData?.file_registration_proof === "string" ? (
            <div className="row">
              <div className="col-3 field-titles">
                Uploaded Proof of Registration
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                <a
                  onClick={() => {
                    window.open(
                      `${result}${props?.basicData?.file_registration_proof}`,
                      "_blank"
                    );
                  }}
                  className="detailFile"
                >
                  View
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3">Uploaded Proof of Registration</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.basicData.file_name_proof}</div>
            </div>
          )}
          <div className="row">
            <div className="col-3">Headquarters Details</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.headquaters_detail ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Name and Designation of Authorized Person with contact details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.contact_person_details ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Courses Offered</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.course_details?.map((dom, i) => (
                <>
                  {i === 0 ? (
                    <>{dom.course_name} </>
                  ) : (
                    <> |&nbsp; {dom.course_name} </>
                  )}
                </>
                // <>{dom.course_name} |&nbsp;</>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Brief note on Institution activities</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.notes_institutions ?? ""}
            </div>
          </div>

          {props.update === true &&
          typeof props?.basicData?.file_submitted_record === "string" ? (
            <div className="row">
              <div className="col-3 field-titles">
                Submitted request letter in institution Letter Head of the
                Institution/Signing Authority.
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                <a
                  onClick={() => {
                    window.open(
                      `${result}${props?.basicData?.file_submitted_record}`,
                      "_blank"
                    );
                  }}
                  className="detailFile"
                >
                  View
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3">
                {" "}
                Submitted request letter in institution Letter Head of the
                Institution/Signing Authority.
              </div>
              <div className="col-1">:</div>
              <div className="col-7">
                {props.basicData.file_name_request ?? ""}
              </div>
            </div>
          )}

          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div> */}
        </div>

        <div className="footer-buttons mt-3">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(1);
            }}
          >
            GO BACK
          </div>
          <div
            className="primary-btn"
            onClick={(e) => {
              props.action(2);
            }}
          >
            REGISTER
          </div>
        </div>
      </div>
    </div>
  );
}
