import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import CheckBox from "../../../../../components/inputs/checkbox";
import TextField from "../../../../../components/inputs/textfield";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_tender_add, admin_tender_update } from "../../../admin.api";
import "./dialog.css";

let required = [
  "tender_title",
  "organized_by",
  "value_of_tender",
  "description",
  "link",
];

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  handleCheckChange = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;

    if (checked) {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 1 },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 0 },
      });
    }
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };

  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      if (edit) {
        admin_tender_update(formData.uuid, formData)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_tender_add(this.state.formData)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleSelect = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        parent_id: e.target.value,
      },
    });
  };

  render() {
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia-tender-admin">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Tender" : view ? "Tender" : "Add Tender"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebars">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div className="content-scroll-xx">
                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.tender_title ?? ""}
                            name="tender_title"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            label="Organized by"
                            name="organized_by"
                            variant="outlined"
                            disabled={view}
                            value={formData.organized_by ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            name="value_of_tender"
                            onChange={(e) => this.handleChange(e)}
                            label="Tender Value"
                            disabled={view}
                            value={formData.value_of_tender ?? ""}
                            variant="outlined"
                            type="number"
                            required
                          />
                        </div>

                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            name="description"
                            onChange={(e) => this.handleChange(e)}
                            label="Description"
                            disabled={view}
                            value={formData.description ?? ""}
                            variant="outlined"
                            multiline
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            name="link"
                            onChange={(e) => this.handleChange(e)}
                            label="Link"
                            disabled={view}
                            max="20"
                            value={formData.link ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6 checkboxStatus">
                          <CheckBox
                            id="outlined-basic"
                            name="tender_publish_status"
                            label="Enable"
                            onChange={(e) => this.handleCheckChange(e)}
                            disabled={view}
                            value={
                              formData.tender_publish_status === 1
                                ? true
                                : false
                            }
                            checked={
                              formData.tender_publish_status === 1
                                ? true
                                : false
                            }
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
