import React from "react";

export default function TextField(props) {
  return (
    <div
      className={`${
        props.view === "straight" ? "d-flex align-items-center " : ""
      }`}
    >
      {props.label && (
        <label
          className={` 
            ${
              props.error
                ? "form-label-error mylabel control-label "
                : "control-label mylabel mb-2"
            } 
            ${props.view === "straight" ? "col-3" : ""}
            `}
        >
          {props.label}
        </label>
      )}

      <div
        className={
          props.required ? "mandatory input-group mb-2" : "input-group mb-2"
        }
      >
        {props.multiline ? (
          <textarea
            {...props}
            autoComplete="off"
            maxLength={props.maxLength}
            className={
              props.error
                ? `form-control errorinput textareastyle mb-2`
                : ` form-control textareastyle mb-2`
            }
          />
        ) : props.type === "file" && props.fileName ? (
          <div className="upload-field">
            <label
              for={`${props.id} `}
              className={props.required ? `errorfile` : ``}
            >
              <input
                {...props}
                autoComplete="off"
                className={
                  props.error
                    ? ` form-control errorinput mb-2`
                    : ` form-control mb-2`
                }
              />
              {props.fileName}
            </label>
          </div>
        ) : (
          <input
            {...props}
            autoComplete="off"
            className={
              props.error
                ? ` form-control errorinput mb-2`
                : ` form-control mb-2`
            }
          />
        )}
      </div>
    </div>
  );
}
