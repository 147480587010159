import React from "react";
import CustomTable from "../../../../../components/table/table";
import "../style.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

export default function View(props) {
  const handleEdit = () => {};
  const setAreaData = (data) => {
    let tdata = [];

    data.forEach((item) => {
      if (
        item.location_id !== null &&
        item.location_id !== "" &&
        item.location_id !== undefined
      ) {
        tdata.push(item);
      }
    });

    console.log(tdata);
    return tdata;
  };
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);


  return (
    <div className="demox">
      <div className="conti view-containe">
        <h6>
          <b>Basic Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Company Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.company_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Designation</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_designation}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.address ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternative Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.alternate_email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Abstract Project Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Product Profile</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.product_profiles?.map((item) => (
                <>
                  <div className="col-12 mb-1">
                    <b>{item.product_name ?? ""}</b>
                  </div>
                  <div className="col-12 mb-1 ">
                    {" "}
                    <div>{ReactHtmlParser(item.product_desc)}</div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Domain Type</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.domain_types && (
                <>
                  {props.basicData?.domain_types?.map((dom,i) => (
                    <>
                      {i === 0 ? (
                        <>{dom.domain_name} {" "}</>
                      ) : (
                        <>|&nbsp;{dom.domain_name} </>
                      )}
                    </>
                  ))}
                </>
              )}
              {(props.basicData?.any_other_flag === "1" ||
                props.basicData?.any_other_flag) && (
                <>
                {props.basicData?.domain_types.length === 0 ?
                <>
                {props.basicData?.any_other_domain}</> : <>
                |&nbsp; {props.basicData?.any_other_domain}</> }
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Capital Investment in lakhs</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.capital_investment ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Expected Manpower </div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.ex_man_power ?? ""}</div>
          </div>
          {props.update === true &&
          typeof props?.basicData?.upload_proposal === "string" ? (
            <div className="row">
              <div className="col-3 field-titles">Document Uploaded</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                <a
                  onClick={() => {
                    window.open(
                      `${result}${props?.basicData?.upload_proposal}`,
                      "_blank"
                    );
                  }}
                  className="detailFile"
                >
                  View
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3 field-titles">Document Uploaded</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props?.basicData?.file_name}</div>
            </div>
          )}
          {/* <div className="row">
            <div className="col-3">Document Uploaded</div>
            <div className="col-1">:</div>
            <div className="col-7 ">{props.basicData?.file_name}</div>
          </div> */}
        </div>
        <h6>
          <b>Area Requirements</b>
        </h6>

        <hr />
        <div className="data">
          {props.basicData.area_type === "built_up_space" && (
            <div className="row pb-3">
              <div className="col-3">Built-up space on Rent per Month</div>
              <div className="col-1">:</div>
              <div className="col-7 ">
                <CustomTable
                  className="areatable"
                  {...props}
                  columns={[
                    { title: "No", field: "id", type: "dynId" },
                    { title: "Location", field: "location" },
                    { title: "Area in sq.ft", field: "area_in_sqft" },
                  ]}
                  data={setAreaData(props.basicData?.area_management)}
                />
              </div>
            </div>
          )}
          {props.basicData.area_type === "equipped_facility" && (
            <div className="row pb-3">
              <div className="col-3">Equipped Facility per Month </div>
              <div className="col-1">:</div>
              <div className="col-7">
                <CustomTable
                  className="areatable"
                  {...props}
                  columns={[
                    { title: "No", field: "id", type: "dynId" },
                    { title: "Location", field: "location" },
                    { title: "Area in sq.ft", field: "area_in_sqft" },
                  ]}
                  data={setAreaData(props.basicData?.area_management)}
                />
              </div>
            </div>
          )}
          {props.basicData.area_type === "land_on" && (
            <div className="row pb-3">
              <div className="col-3">Land on 33 years Lease </div>
              <div className="col-1">:</div>
              <div className="col-7">
                <CustomTable
                  className="areatable"
                  {...props}
                  columns={[
                    { title: "No", field: "id", type: "dynId" },
                    { title: "Location", field: "location" },
                    { title: "Area  in Acres", field: "area_in_acres" },
                  ]}
                  data={setAreaData(props.basicData?.area_management)}
                />
              </div>
            </div>
          )}
          {props.basicData.area_type === "joint_venture" && (
            <div className="row pb-3">
              <div className="col-3">Joint Venture</div>
              <div className="col-1">:</div>
              <div className="col-7 venure">
                {props.basicData?.area_management?.map((item) => (
                  <>
                    {item.location_id !== null &&
                      item.location_id !== "" &&
                      item.location_id !== undefined && (
                        <div className="row venture">
                          <div className="col-12 mb-1">
                            {/* <label>Location&nbsp;:&nbsp;</label> */}
                            <b>{item.location}</b>
                          </div>
                          <div className="col-12 mb-1 text-justify">
                            <label>Area&nbsp;&nbsp;</label>
                            <b>{item.area_in_sqft}</b>
                          </div>
                          <div className="col-12 mb-1 text-justify">
                            <label>Investment Model&nbsp;:&nbsp;</label>
                            <b> {item.investment_model}</b>
                          </div>
                          <div className="col-12 mb-1 text-justify">
                            <label>Management Plan&nbsp;:&nbsp;</label>
                            <b> {item.management_plan}</b>
                          </div>
                        </div>
                      )}
                  </>
                ))}
              </div>
            </div>
          )}
          {props.basicData.area_type === "own_land" && (
            <div className="row pb-3">
              <div className="col-3">Own land </div>
              <div className="col-1">:</div>
              <div className="col-8">
                <CustomTable
                  className="areatable"
                  {...props}
                  columns={[
                    { title: "Location", field: "location" },
                    { title: "Available Area", field: "area_in_sqft" },
                    { title: "Land Address", field: "land_address" },
                    // { title: "Available Area", field: "area" },
                    { title: "Nature Of Holding", field: "nature_of_holding" },
                  ]}
                  data={setAreaData(props.basicData?.area_management)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="footer-buttons">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(3);
            }}
          >
            GO BACK
          </div>
          <div
            className="primary-btn"
            onClick={(e) => {
              props.action(4);
            }}
          >
            REGISTER
          </div>
        </div>
      </div>
    </div>
  );
}
