import React from "react";
import CustomTable from "../../../../../../components/table/table";
import "../style.css";
import moment from "moment";

export default function View(props) {
  console.log(props);

  return (
    <div>
      <div className="view-container">
        <h6>
          <b>Basic Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Full Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.full_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Date of Birth</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.date_of_birth
                ? moment(props.basicData.date_of_birth, "DD-MM-YYYY").format(
                    "DD-MMM-YYYY"
                  )
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.gender ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.address}</div>
          </div>

          <div className="row ">
            <div className="col-11">Educational Qualification</div>

            <div className="col-11 pb-1">
              <>
                <CustomTable
                  {...props}
                  columns={[
                    { title: "Examination passed ", field: "exam_passed" },
                    { title: "Board/University", field: "board" },
                    { title: "Year of Passing", field: "year_passed" },
                    { title: "Mark", field: "mark" },
                    { title: "Certificate", field: "file_name" },
                  ]}
                  data={props.basicData?.qualifications ?? []}
                />
              </>
            </div>
          </div>

          <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <span>
                {props.languageData?.map((lang, i) => (
                  <>
                    {lang.checked && (
                      <>
                        <span>{lang.language} </span>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    )}
                  </>
                ))}

                {props.basicData?.any_other_lang && (
                  <>
                    {props.basicData?.otherLanguages.map((lang, i) => (
                      <>
                        <span>{lang.language} </span>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    ))}
                  </>
                )}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.file_name ?? ""}</div>
          </div>

          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div> */}
        </div>

        <div className="footer-buttons mt-3">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(1);
            }}
          >
            GO BACK
          </div>
          <div
            className="primary-btn"
            onClick={(e) => {
              props.action(2);
            }}
          >
            REGISTER
          </div>
        </div>
      </div>
    </div>
  );
}
