import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import CheckBox from "../../../../../components/inputs/checkbox";
import TextField from "../../../../../components/inputs/textfield";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_publications_create } from "../../../admin.api";
import "./dialog.css";
import CommonForm from "../../../../../components/commonform/commonForm";

let required = [
  "publication_title",
  "link",
  "publisher",
  "description",
  "upload_image",
];

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      fileChanged: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  handleCheckChange = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;

    if (checked) {
      this.setState({
        formData: { ...this.state.formData, publication_status: 1 },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, publication_status: 0 },
      });
    }
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };

  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;

    const form = new FormData();
    if (formData.publication_title)
      form.append("publication_title", formData.publication_title);
    if (formData.link) form.append("link", formData.link);
    if (formData.publisher) form.append("publisher", formData.publisher);
    if (formData.description) form.append("description", formData.description);
    if (formData.publication_status)
      form.append("publication_status", formData.publication_status);

    form.append(
      "upload_image",
      this.state.fileChanged ? formData.upload_image : null
    );
    if (formData.id) form.append("publication_id", formData.id);

    e.preventDefault();

    if (Object.keys(error).length === 0) {
      if (edit) {
        admin_publications_create(form)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_publications_create(form)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleSelect = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        parent_id: e.target.value,
      },
    });
  };
  handleFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0].name.split(".").pop();
let name=e.target.files[0].name
    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;

          if (height === 100 && width === 100) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          this.setState({
            fileChanged: true,

            formData: {
              ...this.state.formData,
              file_name:name,
              [e.target.name]: e.target.files[0],
            },
          });
        } else {
          this.setState({
            snackMessage: "Incorrect image dimension",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }, 200);
    } else {
      this.setState({
        snackMessage: "File should be in .jpg / .jpeg / .png format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  render() {
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="publication-box">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit Publication"
                        : view
                        ? "Publication"
                        : "Add Publication"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar"></div>
                  <form action="">
                    <div className="row book-form">
                      <div className="content-scrolls">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.publication_title ?? ""}
                            name="publication_title"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Link"
                            name="link"
                            variant="outlined"
                            disabled={view}
                            value={formData.link ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                           <div className="file-up-helper">
            *link must start with "https://" or "http://" ( eg: https://kspace.in/ )
          </div>
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Publisher"
                            name="publisher"
                            variant="outlined"
                            disabled={view}
                            value={formData.publisher ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Description"
                            name="description"
                            variant="outlined"
                            multiline
                            disabled={view}
                            value={formData.description ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>

                        <div className="col-12">
          <CommonForm
            fieldType="file"
            required={!formData?.file_name ? true : false}
            label="Image"
            disabled={view}
            name="upload_image"
            id="photo"
            fileName={formData?.file_name}
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={this.handleFileChange}
          />
          <div className="file-up-helper">
            *Dimension:width=100,height=100, format: .jpg, .jpeg , .png
          </div>
        </div>
                        <div className="col-6 checkboxStatus">
                          <CheckBox
                            id="outlined-basic"
                            name="publication_status"
                            label="Enable"
                            onChange={(e) => this.handleCheckChange(e)}
                            disabled={view}
                            value={
                              formData.publication_status === 1 ? true : false
                            }
                            checked={
                              formData.publication_status === 1 ? true : false
                            }
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
