import apis from "../../api/api.actions";
import { parseQueryParams } from "../../api/parseParams";
let userpath = "v1/user";
let publicpath = "v1/common";
export const common_file_upload = (data) =>
  apis.createData(`${publicpath}/file/upload`, data).then((res) => res);

export const user_dash_list = (data) =>
  apis.getDatas(`${userpath}/user-dashboard`).then((res) => res);
export const user_techprop_list = (data) =>
  apis
    .getDatas(`${publicpath}/technology-transfer/list-transfers`)
    .then((res) => res);
export const user_business_list = (data) =>
  apis.getDatas(`${publicpath}/business-idea/list-proposal`).then((res) => res);
export const studentPropList = (data) =>
  apis.getDatas(`${publicpath}/student-proposals`).then((res) => res);

export const user_busprop_list = (data) =>
  apis.getDatas(`${userpath}/tenders`).then((res) => res);
export const user_tenders_list = (data) =>
  apis.getDatas(`${userpath}/tenders`).then((res) => res);
export const user_career_list = (data) =>
  apis.getDatas(`${publicpath}/career/list`).then((res) => res);
export const is_email_registered = (data) =>
  apis.createData(`email-exists`, data).then((res) => res);

export const user_event_booking = (data) =>
  apis.createData(`${publicpath}/event-booking`, data).then((res) => res);
export const user_create_company = (data) =>
  apis.createData(`${publicpath}/application-request`, data).then((res) => res);
export const user_company_domain_list = (data) =>
  apis.getDatas(`${publicpath}/domains-types`).then((res) => res);
export const update_company_registration = (id, data) =>
  apis.createData(`${userpath}/update-company/${id}`, data).then((res) => res);
export const update_resource_registration = (id, data) =>
  apis
    .createData(`${userpath}/resource-person/update/${id}`, data)
    .then((res) => res);
export const update_expert_registration = (id, data) =>
  apis
    .createData(`${userpath}/expert-man-power/update/${id}`, data)
    .then((res) => res);
export const update_academy_registration = (id, data) =>
  apis
    .createData(`${userpath}/institution/update/${id}`, data)
    .then((res) => res);

export const user_company_details = (data) =>
  apis.getDatas(`${userpath}/company/${data}`).then((res) => res);
export const user_institutions_detail = (data) =>
  apis.getDatas(`${userpath}/institution/${data}`).then((res) => res);
export const user_resource_detail = (data) =>
  apis.getDatas(`${userpath}/resource-person/view/${data}`).then((res) => res);
export const user_expert_detail = (data) =>
  apis.getDatas(`${userpath}/expert-man-power/view/${data}`).then((res) => res);
export const user_area_types_list = (data) =>
  apis.getDatas(`${publicpath}/space-area-types`).then((res) => res);
export const user_locations_list = (data) =>
  apis.getDatas(`${publicpath}/locations`).then((res) => res);

export const user_update_request = (data) =>
  apis.createData(`${userpath}/application-request`, data).then((res) => res);
export const user_new_request = (data) =>
  apis.createData(`${publicpath}/application-request`, data).then((res) => res);
export const create_company_request = (data) =>
  apis
    .createData(`${publicpath}/create-company`, data, {
      headers: { Authorization: "public" },
    })
    .then((res) => res);
export const create_accademic_institution = (data) =>
  apis
    .createData(`${publicpath}/create-institutions`, data, {
      headers: { Authorization: "public" },
    })
    .then((res) => res);
export const user_request = (data) =>
  apis.createData(`${publicpath}/application-request`, data).then((res) => res);
export const user_get_pplication = (data) =>
  apis.getDatas(`${userpath}/get-applications`).then((res) => res);

export const user_company_list_kspace = (data) =>
  apis
    .getDatas(`${publicpath}/companies${parseQueryParams(data)}`)
    .then((res) => res);
export const user_company_list_kerala = (status) =>
  apis
    .getDatas(`${status ? userpath : publicpath}/directory-kerala`)
    .then((res) => res);
export const user_company_list_india = (status) =>
  apis
    .getDatas(`${status ? userpath : publicpath}/directory-india`)
    .then((res) => res);
export const user_company_list_international = (status) =>
  apis
    .getDatas(`${status ? userpath : publicpath}/directory-international`)
    .then((res) => res);
export const get_company_domain_list = (status) =>
  apis
    .getDatas(`${status ? publicpath : publicpath}/domains-types`)
    .then((res) => res);

export const user_company_list_kspace_search = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/search-keyword-kspace`, data)
    .then((res) => res);
export const user_company_list_kerala_search = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/search-keyword-kerala`, data)
    .then((res) => res);
export const user_company_list_india_search = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/search-keyword-india`, data)
    .then((res) => res);
export const user_company_list_international_search = (status, data) =>
  apis
    .createData(
      `${status ? userpath : publicpath}/search-keyword-international`,
      data
    )
    .then((res) => res);
export const user_company_list_kspace_filter = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/domain-filter-kspace`, data)
    .then((res) => res);
export const user_company_list_kerala_filter = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/domain-filter-kerala`, data)
    .then((res) => res);
export const user_company_list_india_filter = (status, data) =>
  apis
    .createData(`${status ? userpath : publicpath}/domain-filter-india`, data)
    .then((res) => res);
export const user_company_list_international_filter = (status, data) =>
  apis
    .createData(
      `${status ? userpath : publicpath}/domain-filter-international`,
      data
    )
    .then((res) => res);
export const user_create_proposal = (data) =>
  apis
    .createData(`${publicpath}/business-idea/project-proposal`, data)
    .then((res) => res);
export const user_create_techproposal = (data) =>
  apis
    .createData(`${publicpath}/technology-transfer/create`, data)
    .then((res) => res);

export const expertManPowerCreateLookup = (data) =>
  apis.getDatas(`${publicpath}/expert-man-power/look-up`).then((res) => res);

export const expertManPowerCreate = (data) =>
  apis
    .createData(`${publicpath}/expert-man-power/create`, data, {
      headers: { Authorization: "public" },
    })
    .then((res) => res);
export const expertManPowerList = (data) =>
  apis.getDatas(`${userpath}/expert-man-power/list`, data).then((res) => res);
export const resourcePersonalList = (data) =>
  apis.getDatas(`${userpath}/resource-person/list`, data).then((res) => res);

export const user_get_manpower_details = (id) =>
  apis.getDatas(`${userpath}/expert-man-power/view/${id}`).then((res) => res);

export const resourcePersonalCreate = (data) =>
  apis
    .createData(`${publicpath}/resource-person/create`, data, {
      headers: { Authorization: "public" },
    })
    .then((res) => res);

export const resPerList = (data) =>
  apis.getDatas(`${userpath}/expert-man-power/list`, data).then((res) => res);

export const user_res_per_details = (id) =>
  apis.getDatas(`${userpath}/expert-man-power/view/${id}`).then((res) => res);

export const institutionList = () =>
  apis.getDatas(`${publicpath}/institutions`).then((res) => res);

export const studentPropCreate = (data) =>
  apis
    .createData(`${userpath}/create-student-proposal`, data)
    .then((res) => res);

export const studentInternCreate = (data) =>
  apis.createData(`${userpath}/create-internship`, data).then((res) => res);

export const inter_list = () =>
  apis.getDatas(`${userpath}/internship-proposals`).then((res) => res);
export const inter_details = (id) =>
  apis.getDatas(`${userpath}/intern-proposal/${id}`).then((res) => res);

export const userPublications = () =>
  apis.getDatas(`${userpath}/publications`).then((res) => res);

export const get_user_resourse_details = (id) =>
  apis.getDatas(`${userpath}/resource-person/view/${id}`).then((res) => res);
