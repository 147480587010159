import { Logout } from "../App";
import api from "./apis";

const apis = {
  auth: async (url, reqData) => await api.post(url, reqData).then((res) => res),
  getDatas: async (url, queryParams) =>
    await api
      .get(url, queryParams)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Logout();
        }
        return Promise.reject(error);
      }),

  createData: async (url, queryParams, data) =>
    await api
      .post(url, queryParams, data ?? null)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Logout();
        }
        return Promise.reject(error);
      }),

  updateData: async (url, queryParams) =>
    await api
      .put(url, queryParams)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Logout();
        }
        return Promise.reject(error);
      }),
  deleteData: async (url, queryParams) =>
    await api
      .delete(url, queryParams)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Logout();
        }
        return Promise.reject(error);
      }),
};
export default apis;
