import React from "react";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import "../home/home.css";
import "../gallery/events.css";

function Disclaimer(props) {
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>{props.state.language === "ma" ? "നിരാകരണം" : "Disclaimer "}</h3>
        </div>
      </div>
      <div className=" mtb-25">
        <div className="container my-5 pt-5 pb-5" style={{ fontSize: "16px" }}>
          <p>
            <b>DISCLAIMER</b>: The information contained on this website is for
            general informational purposes only. The information is provided by
            kSpace and while we strive to keep the information up to date and
            correct, we make no representations or warranties of any kind,
            express or implied, about the completeness, accuracy, reliability,
            suitability or availability with respect to the website or the
            information, products, services, or related graphics contained on
            the website for any purpose. Any reliance you place on such
            information is therefore strictly at your own risk.
          </p>
          <br />
          <p>
            {" "}
            In no event will we be liable for any loss or damage including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this website.
          </p>
          <br />
          <p>
            {" "}
            Through this website you may be able to link to other websites which
            are not under the control of kSpace. We have no control over the
            nature, content, and availability of those sites. The inclusion of
            any links does not necessarily imply a recommendation or endorsement
            of the views expressed within them.
          </p>
          <br />
          <p>
            {" "}
            Every effort is made to keep the website up and running smoothly.
            However, kSpace takes no responsibility for, and will not be liable
            for, the website being temporarily unavailable due to technical
            issues beyond our control.
          </p>
        </div>
      </div>
    </>
  );
}

export default Disclaimer;
