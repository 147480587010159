import React from "react";
import "./style.css";

export default function RadioSelect(props) {
  return (
    <div className="to-center d-flex align-items-center ">
      
      <input type="radio" {...props} className="mx-2 pb-2" />
      {props.label && <label>{props.label}</label>}
      
    </div>
  );
}
