import React, { useEffect, useState, useRef, useMemo } from "react";
import { user_business_list } from "../user.api";
import Loader from "../../../components/loader/loader";
import { data } from "../../../layout/userlayout/components/footer/heatmap";
import TechPropTile from "../technology_proposal/components/techPropTile";
import "./style.css";
import Pagination from "../../../components/page/companyDirectory/companyPagination";
let PageSize = 10;
export default function BusinessProposal(props) {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getData = () => {
    setIsActive(true);
    user_business_list().then((res) => {
      setData(res.data);
      setIsActive(false);
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div ref={myRef}>
      <div className="">
        <Loader open={isActive} />
        <div>
          <div className="header-banner">
            <div className="tit-txt">Business Proposals</div>
          </div>
          <div className="container">
            <div className="proposal-tiles-container">
              {data.length === 0 ? (
                <div className="no-data">
                  <img src="../resources/img/no1.png" alt="" />
                  <h4>No Records Found..!!</h4>
                </div>
              ) : (
                <>
                  {mainData.map((item) => (
                    <TechPropTile data={item} {...props} />
                  ))}
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
