import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomIcons from "../../icons/kIcons.component";
import Loader from "../../loader/loader";
import CheckBox from "../../inputs/checkbox";
import "./compDirectory.style.css";
import SearchButton from "../../inputs/searchButton";
import MoreDetails from "./moreDetails/moreDetails";
import Pagination from "./companyPagination";

// let PageSize = 4;
export default function CommonCompanyDirectory(props) {
  let PageSize = props.perPage;
  const [currentPage, setCurrentPage] = useState(1);

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props.data.slice(firstPageIndex, lastPageIndex);
  }, [props.data, currentPage]);

  const [domain, setDomain] = useState(props.domain);
  const [openView, setOpenView] = useState(false);
  const [activeData, setActiveData] = useState({});
  const myRef = useRef(null);
  const searchChange = (e) => {
    props.setSearch(e.target.value);
  };
  const submitSearch = () => {
    props.searchCompanies();
  };
  const clearSearch = () => {
    props.setSearch("");
  };
  const handleViewDetails = (item) => {
    setOpenView(true);
    setActiveData(item);
  };
  const handleViewClose = () => {
    setOpenView(false);
    setActiveData({});
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };
  useEffect(() => {
    setDomain(props.domain);
  }, [props.domain, props.key]);
  return (
    <div ref={myRef}>
      <div className="">
        <Loader open={props.isActive} />
        <div>
          <div className="header-banner">
            <div className="tit-txt">{props.title}</div>
          </div>
        </div>

        <div className="comp-layout-con">
          {/* <CustomIcons iconName="edit" iconClass=""/> */}

          <div className="comp-outer-cont">
            {/* -left side------------ */}
            <div className="comp-domain-container">
              {/* -left side card------------ */}

              <div className="comp-common-title">Domains</div>
              <div className="comp-domains">
                {domain?.map((item) => (
                  <CheckBox
                    className="domaincheck"
                    label={item.domain_name}
                    checked={item.checked ? true : false}
                    onChange={(e) => {
                      props.FilterChange(e, item);
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="com-dir-cont">
              <div className="">
                <SearchButton
                  type="text"
                  onChange={searchChange}
                  onSearch={submitSearch}
                  clearSearch={clearSearch}
                  value={props.search}
                  placeholder={"Search by Company,category"}
                />
              </div>
              <div className="com-select-filter-cont">
                <div className="com-sel-filters">
                  {props.domain.map((item) => (
                    <>
                      {item.checked && (
                        <div className="comp-filter-item">
                          <div className="comp-filter-item-label">
                            {item.domain_name}
                          </div>
                          <div
                            className="filter-clearButton-cont"
                            onClick={(e) => props.closeFilter(item)}
                          >
                            <CustomIcons
                              iconName="clearInput"
                              iconClass={"filter-clear-button"}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <div
                  className="comp-clear-filter"
                  onClick={(e) => props.clearFilter()}
                >
                  Clear Filter
                </div>
              </div>
              <div className="comp-list-con">
                {mainData && mainData.length !== 0 ? (
                  <>
                    {mainData?.map((item) => (
                      <div className="comp-list-item-cont">
                        <div className="comp-list-item">
                          <div className="comp-item-header">
                            <div className="comp-item-logo">
                              {item?.company_name?.substring(0, 1)}
                            </div>
                            <div className="comp-item-header-data">
                              <div className="comp-item-person-name">
                                {item.person_name}
                              </div>

                              <div className="d-flex">
                                <div>
                                  <CustomIcons
                                    iconName="mail"
                                    iconClass="comp-item-data-icons"
                                  />
                                </div>
                                <div className="text-break">{item.email}</div>
                              </div>

                              {/* <div className="d-flex"> */}
                              {/* <div>
                                  <CustomIcons
                                    iconName="phone1"
                                    iconClass="comp-item-data-icons"
                                  />{" "}
                                </div> */}
                              {/* <div className="text-break">
                                  {" "}
                                  {item.phone_no}
                                </div> */}
                              {/* </div> */}

                              <div className="d-flex">
                                <div>
                                  <CustomIcons
                                    iconName="people"
                                    iconClass="comp-item-data-icons"
                                  />
                                </div>
                                <div className="text-break">
                                  {item.ex_man_power}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="comp-item-comp-title">
                            {item.company_name}
                          </div>
                          <div className="comp-item-dom-cont">
                            {item.any_other_domain !== null &&
                              item.any_other_domain !== "" &&
                              item.any_other_domain !== undefined && (
                                <div className="comp-item-dom">
                                  {`#${item.any_other_domain}`}
                                </div>
                              )}
                            {item.domain_types?.map((dom) => (
                              <div className="comp-item-dom">
                                {`#${dom.domain_name}`}
                              </div>
                            ))}
                          </div>
                          <div className="comp-item-product-prof-cont">
                            <b>Product Profile</b>

                            {item.product_profiles?.map((produ, index) => (
                              <div>
                                <div className="comp-item-prod-prof-title">
                                  {index + 1}.&nbsp;
                                  {produ.product_name}
                                </div>
                                {/* <div className="comp-item-prod-prof-desc">
                                  {produ.product_desc}
                                </div> */}
                              </div>
                            ))}
                          </div>
                          <div className="comp-view-more-cont">
                            <div
                              className="comp-item-view-more-text"
                              onClick={(e) => handleViewDetails(item)}
                            >
                              View Details
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="no-data-found">No Results found</div>
                )}
              </div>
            </div>
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={props.data.length}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      </div>
      <MoreDetails
        open={openView}
        data={activeData}
        handleClose={handleViewClose}
      />
    </div>
  );
}
