import React, { useEffect, useState } from "react";
import { Carousel, Placeholder, Card } from "react-bootstrap";
import CustomIcons from "../../../../components/icons/kIcons.component";
import { public_banner_list } from "../../public.api";
import BannerTile from "./components/bannerTile";
import defaultdata from "./components/bannerDummy.json";
export default function Banner(props) {
  const [data, setdata] = useState([]);
  const [url, setUrl] = useState("");
  const [active, setActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleEventList = () => {
    setActive(true);
    public_banner_list().then((res) => {
      setActive(false);
      setdata(res.data);
      setUrl(res.current_url);
      // props.checkLoaderStatus("bannerActive", false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handlePrevious = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex - 1;
    if (activeIndex === 0) {
      setActiveIndex(lastIndex);
    } else {
      setActiveIndex(index);
    }
  };
  const handleNext = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex + 1;
    if (activeIndex === lastIndex) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <div className="banner-flex-con">
      {/* <div className="leftSliderCon" onClick={(e) => handlePrevious(e)}>
        <CustomIcons iconName="sliderLeft" iconClass="sliderIcon" />
      </div> */}

      {active ? (
        // <Card style={{ width: "18rem" }}>
        //   <Card.Img variant="top" src="holder.js/100px180" />
        //   <Card.Body>
        <div className="w-100">
          <Placeholder as={"div"} animation="glow" bg="info">
            <Placeholder xs={12} className="banner-skelton" />
          </Placeholder>
        </div>
      ) : (
        //   </Card.Body>
        // </Card>
        <Carousel className="w-100">
          {data.length !== 0
            ? data.map((item, index) => (
                <Carousel.Item>
                  <React.Fragment>
                    <React.Fragment>
                      <BannerTile {...props} data={item} baseUrl={url} />
                    </React.Fragment>
                  </React.Fragment>
                </Carousel.Item>
              ))
            : defaultdata.map((item, index) => (
                <Carousel.Item>
                  <React.Fragment>
                    <React.Fragment>
                      <BannerTile {...props} data={item} baseUrl={url} />
                    </React.Fragment>
                  </React.Fragment>
                </Carousel.Item>
              ))}
        </Carousel>
      )}
      {/* <div className="rightSliderCon" onClick={(e) => handleNext(e)}>
        {" "}
        <CustomIcons iconName="sliderRight" iconClass="sliderIcon" />
      </div> */}
    </div>
  );
}
