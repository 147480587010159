import { Button } from "bootstrap";
import React, { useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import CustomTable from "../../../../../components/table/table";
import "../style.css";

export default function View(props) {
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const [agree, setAgree] = useState(false);
  const handleCheckChange = (e) => {
    let checked = e.target.checked;
    console.log(checked);
    setAgree(checked);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    props.action(4);
  };
  // console.log(props.basicData?.expertise_area)
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="view-container">
          <h6>
            <b>Basic Details</b>
          </h6>

          <hr />

          <div className="data">
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Full Name</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.full_name ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Age</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">{props.basicData.age ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Gender</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.gender === 1
                  ? "Male"
                  : props.basicData.gender === 2
                  ? "Female"
                  : props.basicData.gender === 3
                  ? "Other"
                  : ""}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Address</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">{props.basicData.address}</div>
            </div>

            <div className="row">
              <div className="col-11 col-md-3  max-bold">Email</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.email ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Phone Number</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.mobile ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Languages Known</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.languageData?.map((lang, i) => (
                  <>
                    <>
                      {i === 0 ? (
                        <>{lang.language} </>
                      ) : (
                        <> |&nbsp;{lang.language} </>
                      )}
                    </>
                  </>
                ))}

                {props.basicData.any_other_lang && (
                  <>
                    {props.basicData?.otherLanguages?.map((lang) => (
                      <>
                        {props?.languageData?.length === 0 ? (
                          <>{lang.language}</>
                        ) : (
                          <> |&nbsp; {lang.language}</>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Domain Type</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.domainData?.map((dom, index) => (
                  <>
                    {dom.checked && (
                      <>
                        {index === 0 ? (
                          <>{dom.domain_name} </>
                        ) : (
                          <> |&nbsp; {dom.domain_name} </>
                        )}
                      </>
                    )}
                  </>
                ))}
                {props.basicData.any_other_flag && (
                  <>
                    {props.basicData?.any_other_domain && (
                      <>{props.basicData?.any_other_domain}</>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3  max-bold">Area of Expertise</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData?.expertise_area?.map((item, i) => (
                  <>
                    {i === 0 ? (
                      <>{item.expertise_name} </>
                    ) : (
                      <>|&nbsp; {item.expertise_name} </>
                    )}
                  </>
                ))}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-11 col-md-3  max-bold">
                Any other area of interest
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.areaOfInterest?.map((area, index) => (
                  <>
                    {area.checked && (
                      <>{area.area_of_interest_name}&nbsp;|&nbsp;</>
                    )}
                  </>
                ))}
              </div>
            </div> */}
            <div className="row">
              <div className="col-11 col-md-3  max-bold">
                to travel within India / abroad
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.is_willing_to_travel === 1 ? "Yes" : "No"}
              </div>
            </div>
          </div>
          <h6>
            <b>Qualifications</b>
          </h6>

          <hr />

          <div className="back-g data">
            <div className="row">
              <div className="col-11 col-md-3  max-bold">
                Educational Qualifications
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-8">
                {props.basicData?.educational_qualifications?.map(
                  (item, index) => (
                    <>
                      <div className="col-12 mb-1">
                        <div className="nox">
                          <div className="row">
                            <div className="col-1">
                              <div className="max-bold main-head-txt">{`${
                                props.basicData?.educational_qualifications
                                  .length > 1
                                  ? index + 1
                                  : ""
                              }.`}</div>
                            </div>
                            <div className="col-9">
                              <div className="max-bold main-head-txt">
                                {/* {"SSLC ( 2011 )"} */}
                                {`${item.examination_passed} ( ${item.year_of_pass} )`}
                              </div>

                              <div className="sub-items-txt  max-bold mr-2">
                                University :
                              </div>
                              <div className="sub-items-txt ">
                                {item.board_of_university}
                              </div>

                              <div className="sub-items-txt max-bold">
                                {`Mark / Grade : `}
                              </div>
                              <div className="sub-items-txt ">
                                {item.mark_grade}
                              </div>
                              <div className="sub-items-txt max-bold ">
                                Certificate :
                              </div>

                              {props.update === true ? (
                                <div
                                  onClick={() => {
                                    window.open(
                                      `${result}${item?.file_upload_certificate}`,
                                      "_blank"
                                    );
                                  }}
                                  className="sub-items-txt max-bold detailFile"
                                >
                                  View
                                </div>
                              ) : (
                                <div className="sub-items-txt max-bold">
                                  {item.file_name}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-11 col-md-3  max-bold">
                Experience Details{" "}
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-8">
                {props.basicData?.work_experience?.map((item, index) => (
                  <>
                    <div className="col-12 mb-1">
                      <div className="nox">
                        <div className=" row">
                          <div className="col-1">
                            <div className="max-bold main-head-txt">{`${
                              props.basicData?.work_experience.length > 1
                                ? index + 1
                                : ""
                            }.`}</div>
                          </div>
                          <div className="col-11">
                            <div className="sub-items-txt  max-bold">
                              Organization :
                            </div>
                            <div className="sub-items-txt  ">
                              {item.organization_name}
                            </div>
                            <div className="sub-items-txt  max-bold">
                              Designation :
                            </div>
                            <div className=" main-head-txt">
                              {item.designation}
                            </div>
                            <div className="sub-items-txt max-bold">
                              Experience (Yrs):{" "}
                              {/* <span className="">{item.experience}</span> */}
                            </div>
                            <div className="sub-items-txt  ">
                              {item.experience}
                            </div>

                            <div className="sub-items-txt  max-bold">
                              Nature of Work :
                            </div>
                            <div className="sub-items-txt  ">
                              {item.nature_of_work}
                            </div>
                            <div className="sub-items-txt  max-bold">
                              Reason for Leaving :
                            </div>
                            <div className="sub-items-txt  ">
                              {item.reason_for_leaving}
                            </div>
                            <div className="sub-items-txt max-bold">
                              Certificate :
                            </div>

                            {props.update === true ? (
                              <div
                                onClick={() => {
                                  window.open(
                                    `${result}${item?.experience_certificate}`,
                                    "_blank"
                                  );
                                }}
                                className="sub-items-txt max-bold detailFile"
                              >
                                View
                              </div>
                            ) : (
                              <div className="sub-items-txt  max-bold">
                                {item.file_name}
                              </div>
                            )}
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3 max-bold">
                Are you currently attached / committed to any Organization ?{" "}
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-12 col-md-6">
                {props.basicData.organization_details ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-3 max-bold">Uploaded Photo</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-6 col-md-6">
                <img
                  className="w-100"
                  src={`${result}/${props.basicData.photo}`}
                ></img>
                {/* {props.basicData.photo ?? ""} */}
                {/* {console.log(props.basicData)} */}
              </div>
            </div>
          </div>
          <div className="p-10">
            <div className="d-flex">
              <div>
                <center>
                  <CommonForm
                    fieldType="checkbox"
                    value={agree}
                    required
                    checked={agree}
                    onChange={(e) => handleCheckChange(e)}
                    label=""
                    {...props}
                  />
                </center>
              </div>
              <div>
                I hereby declare that the details furnished above are true and
                correct to the best of my knowledge and belief. In case any of
                the above information is found to be false or untrue or
                misleading or misrepresenting, I am aware that I may be held
                liable for it.
              </div>
            </div>
          </div>
          <div className="footer-buttons">
            <div
              className="secondary-btn"
              onClick={(e) => {
                props.action(3);
              }}
            >
              GO BACK
            </div>

            <button className="primary-btn" type="submit">
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
