import React, { useState, useRef, useEffect } from "react";
import Snackbar from "../../../../components/snackbar/snackbar";
import { resourcePersonalCreate } from "../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import BasicDetails from "./components/basicDetails";
import Qualifications from "./components/qualifications";
import View from "./components/viewDetails";
import {
  user_company_domain_list,
  expertManPowerCreateLookup,
  user_new_request,
  update_resource_registration,
  user_resource_detail,
} from "../../user.api";
import "./style.css";
import Loader from "../../../../components/loader/loader";
import jwtDecode from "jwt-decode";

export default function NewResPersonal(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [defaultkey, setdefaultkey] = useState("");
  const myRef = useRef(null);
  const [basicData, setBasicData] = useState({});
  const [domainData, setDomain] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [areaOfInterest, setAreaofInterest] = useState([]);
  const [addCount, setCount] = useState(1);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      fetchDetails(res?.data);
    });
  };

  const action = (id, data, key) => {
    myRef.current.scrollIntoView();

    if (id === 0) {
      setBasicData({ ...data });
      setActiveStep(1);
    } else if (id === 2) {
      setdefaultkey(key);
      setBasicData({ ...data });
      setActiveStep(2);
    } else if (id === 1) {
      setActiveStep(0);
    } else if (id === 3) {
      setActiveStep(1);
    } else if (id === 4) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let language = [];
    let area = [];
    let dom = [];

    // areaOfInterest.forEach((item) => {
    //   if (item.checked) {
    //     area.push(item.uuid);
    //   }
    // });
    languageData.forEach((item) => {
      if (item.checked) {
        language.push(item.language);
      }
    });

    if (basicData.any_other_lang) {
      if (basicData.otherLanguages.length !== 0) {
        basicData.otherLanguages.forEach((item) => {
          language.push(item.language);
        });
      }
    }

    domainData.forEach((item) => {
      if (item.checked) {
        dom.push(item.domain_name);
      }
    });

    if (basicData.any_other_flag) {
      dom.push(basicData.any_other_domain);
    }

    let exp = [];
    let edu = [];
    let expArea = [];
    basicData?.work_experience?.forEach((item) => {
      let data = {
        organization_name: item.organization_name,
        experience: item.experience,
        nature_of_work: item.nature_of_work,
        designation: item.designation,
        reason_for_leaving: item.reason_for_leaving,
        experience_certificate: item.experience_certificate,
      };

      exp.push(data);
    });
    basicData?.expertise_area?.forEach((item) => {
      expArea.push(item.expertise_name);
    });
    basicData?.educational_qualifications?.forEach((item) => {
      let data = {
        examination_passed: item.examination_passed,
        board_of_university: item.board_of_university,
        file_upload_certificate: item.file_upload_certificate,
        mark_grade: item.mark_grade,
        year_of_pass: item.year_of_pass,
      };

      edu.push(data);
    });

    let finalData = {
      full_name: basicData.full_name,
      address: basicData.address,
      age: String(basicData.age),
      email: basicData.email,
      gender: basicData.gender,
      mobile: basicData.mobile,
      name: basicData.name,
      photo: basicData.photo,
      expertise_area: expArea,
      travell_interest: basicData.is_willing_to_travel === 1 ? true : false,
      desc_of_current_work: basicData.organization_details,
      languages_known: language,
      experience: exp,
      education: edu,
      area_of_interest: area,
      domain_types: dom,
    };

    setIsActive(true);
    update_resource_registration(userData?.user?.id, finalData)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Qualifications" },
  ];

  const headlperText = {
    header: "* All fields are Mandatory",
  };

  let userData = jwtDecode(props.state.payload);

  useEffect(() => {
    getDomainTypes();
  }, []);
  const getFileNmae = (url) => {
    let name = null;

    name = url.substring(url.lastIndexOf("/") + 1);
    return name;
  };
  const fetchDetails = (domData) => {
    user_resource_detail(userData?.user?.id).then((res) => {
      // let mobile_number= res?.phone_no,
      let lan = [
        { language: "Malayalam", checked: false },
        { language: "English", checked: false },
        { language: "Hindi", checked: false },
      ];
      let otherDom = [];
      let otherLang = [];
      let Data = res?.data[0];
      lan.forEach((lang) => {
        lang["checked"] = Data?.languages.some(
          (la) => lang.language === la.language_name
        );
      });

      let edu = [];
      let exp = [];
      let olData = [];
      otherLang = Data?.languages?.filter(
        (ar1) => !lan.some((ar2) => ar1?.language_name === ar2?.language)
      );
      console.log(otherLang);
      console.log(otherLang);

      otherLang.forEach((item) => {
        {
          olData.push({ ...item, language: item.language_name });
        }
      });
      Data.education.forEach((item) => {
        let ed = {
          examination_passed: item.examination_passed,
          board_of_university: item.board_of_university,
          year_of_pass: item.year_of_pass,
          mark_grade: item.mark_grade,
          file_upload_certificate: item.file_upload_certificate,
          file_name: item.file_upload_certificate
            ? getFileNmae(item.file_upload_certificate)
            : null,
        };
        edu.push(ed);
      });
      Data.experience.forEach((item) => {
        let ed = {
          organization_name: item.organization_name,
          experience: item.experience,
          nature_of_work: item.nature_of_work,
          designation: item.designation,
          reason_for_leaving: item.reason_for_leaving,
          experience_certificate: item.experience_certificate,
          file_name: item.experience_certificate
            ? getFileNmae(item.experience_certificate)
            : null,
        };
        exp.push(ed);
      });
      otherDom = Data?.domain_types?.filter(
        (ar1) => !domData.some((ar2) => ar1?.domain_name === ar2?.domain_name)
      );

      domData?.forEach((item) => {
        item["checked"] = Data?.domain_types?.some(
          (dom) => item?.domain_name === dom?.domain_name
        );
      });
      setDomain(domData);

      setBasicData({
        ...Data,
        gender: Data.gender ? Number(Data.gender) : 1,
        expertise_area: Data.area_of_expertise,
        educational_qualifications: edu,
        work_experience: exp,
        otherLanguages: [],
        mobile: Data.phone_no,
        organization_details: Data.desc_of_current_work,
        is_willing_to_travel: Data.travell_interest
          ? Number(Data.travell_interest)
          : 1,
        photo: Data.file_upload,
        file_name: Data.file_upload ? getFileNmae(Data.file_upload) : null,
        any_other_flag: otherDom?.length > 0 ? true : false,
        any_other_domain: otherDom?.length > 0 ? otherDom[0].domain_name : null,
        any_other_lang: olData?.length > 0 ? true : false,
        otherLanguages: olData,
      });
      setLanguageData(lan);
      setIsActive(false);
      setCount(addCount + 1);
    });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      // handleChangedata(0);
      setDomain([]);
      // props.logout();
      props.history.push("/dashboard");
    }
  };

  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">Resource Personnel Registration</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            <div>
              {activeStep < 2 && (
                <>
                  <div className="header-helper-text">
                    {headlperText.header}
                  </div>
                  <div className="d-flex">
                    {navLabel.map((item, index) => (
                      <div
                        className={
                          index % 2 === 1
                            ? item.activeStep === activeStep
                              ? "headerbuttoneven active"
                              : "headerbuttoneven"
                            : item.activeStep === activeStep
                            ? "headerbuttonodd active"
                            : "headerbuttonodd"
                        }
                      >
                        <div
                          className={
                            index % 2 === 1 ? "indexCont even" : "indexCont "
                          }
                        >
                          {index + 1}
                        </div>
                        <div> {item.name}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="fomy form-inner-sections">
                {activeStep === 0 && (
                  <BasicDetails
                    {...props}
                    data={basicData}
                    update={true}
                    action={action}
                    formData={basicData}
                    domainData={domainData}
                    areaOfInterest={areaOfInterest}
                    setDomain={setDomain}
                    languageData={languageData}
                    setLanguageData={setLanguageData}
                    setAreaofInterest={setAreaofInterest}
                  />
                )}
                {activeStep === 1 && (
                  <Qualifications
                    {...props}
                    key={addCount}
                    data={basicData}
                    defaultkey={defaultkey}
                    action={action}
                  />
                )}
                {activeStep === 2 && (
                  <View
                    {...props}
                    update={true}
                    basicData={basicData}
                    domainData={domainData}
                    languageData={languageData}
                    areaOfInterest={areaOfInterest}
                    action={action}
                  />
                )}
              </div>
              <Snackbar
                message={snackMessage}
                error={isSnackError}
                open={snackOpen}
                close={snackbarClose}
              />
              {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
