import React, { Component } from "react";
import CustomIcons from "../../../components/icons/kIcons.component";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { user_career_list } from "../../user/user.api";
import "./tenders.css";
import { useEffect } from "react";
import { useState } from "react";

function Careers(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    user_career_list().then((res) => {
      setData(res?.data);
      // console.log(res?.data)
     
    });
  }, []);
  console.log(data);
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>{props.state.language === "ma" ? "Careers" : "Careers"}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="table-container">
          <table className="">
            {data.length > 0 ? (
              <tr className="">
                <th className="table-head">Title</th>
                <th className="table-head ">Description</th>
                <th className="table-head ">Comments</th>
              </tr>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: "20px" }}
              >
                No Recent Postings
              </div>
            )}
            {console.log(data)}
            {data.map((card, i) => {
              return (
                <>
                  <tr>
                    <td className="m-2 text-left">{card.title}</td>
                    <td className="m-2 text-left">{card.description}</td>
                    <td className="m-2 text-left parserfont" >{card.comments ? ReactHtmlParser(card.comments) : "no comments"}</td>
                    {/* <td className="m-2">
                      <a href={`${card.file}`} target="_blank">
                        Download File
                      </a>
                    </td> */}
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}
export default Careers;
