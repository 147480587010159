import React from "react";
import "./style.css";

export default function CheckBox(props) {
  return (
    <div className="to-center d-flex align-items-center mb-1">
      <input type="checkbox" {...props} className="my-2 mx-2 " />

      {props.label && <label>{props.label}</label>}
    </div>
  );
}
