import React, { useEffect, useState } from "react";

import CommonCompanyDirectory from "../../../../components/page/companyDirectory/commonCompanyDirectory";
import {
  get_company_domain_list,
  user_company_list_kspace,
  user_company_list_kspace_filter,
  user_company_list_kspace_search,
} from "../../user.api";

export default function View(props) {
  const [searchData, setSearchData] = useState([]);

  const [domain, setDomain] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [key, setKey] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");

  const getCompanies = () => {
    console.log(filterData);

    let data = {
      keyword: search,
      type: "international",
      domain_type: filterData.toString(),
      // perPage: 4,
    };
    setIsActive(true);
    user_company_list_kspace(data).then((res) => {
      let dir = res.data;
      setIsActive(false);

      setSearchData(dir);

      setIsActive(false);
    });
  };

  const FilterChange = (e, item) => {
    let checked = e.target.checked;
    console.log("w1");

    let currId = item.id;

    let actFilter = filterData;
    let domData = domain;
    domData.forEach((items) => {
      if (items.id === currId) {
        items["checked"] = checked;
      }
    });
    setDomain(domData);

    // setSearch("");
    if (checked) {
      console.log("w1");
      actFilter.push(item.id);
      setFilterData(actFilter);
      // filterCompanies();
    } else {
      console.log("w1");
      actFilter.forEach((item, index) => {
        if (item === currId) {
          if (index > -1) {
            actFilter.splice(index, 1);
          }
        }
      });
      setFilterData(actFilter);
    }
    setKey(key + 1);
    getCompanies();
  };
  const closeFilter = (sel) => {
    let currId = sel.id;

    let actFilter = filterData;
    let domData = domain;
    domData.forEach((items) => {
      if (items.id === currId) {
        items["checked"] = false;
      }
    });
    setDomain(domData);
    actFilter.forEach((item, index) => {
      if (item.id === currId) {
        if (index > -1) {
          actFilter.splice(index, 1);
        }
      }
    });
    setFilterData(actFilter);
    setKey(key + 1);
  };
  const clearFilter = () => {
    let domData = domain;
    domData.forEach((items) => {
      items["checked"] = false;
    });
    setDomain(domData);
    setFilterData([]);
  };
  // const filterCompanies = () => {
  //   setIsActive(true);
  //   if (filterData.length > 0) {
  //     let fil = { domain_id: filterData };
  //     user_company_list_kspace_filter(
  //       props.state.isUserAuthenticated,
  //       fil
  //     ).then((res) => {
  //       let dir = res?.directory_filter;

  //       setSearchData(dir);
  //       setIsActive(false);
  //     });
  //   } else {
  //     getCompanies();
  //   }
  // };
  const getDomains = () => {
    setIsActive(true);
    get_company_domain_list(props.state.isUserAuthenticated).then((res) => {
      setDomain(res.data);
      // setCount(addCount + 1);
      setIsActive(false);
    });
  };

  useEffect(() => {
    getCompanies();
    getDomains();
  }, []);

  return (
    <CommonCompanyDirectory
      {...props}
      data={searchData}
      FilterChange={FilterChange}
      searchCompanies={getCompanies}
      setSearch={setSearch}
      closeFilter={closeFilter}
      clearFilter={clearFilter}
      title={"Companies International"}
      search={search}
      domain={domain}
      key={key}
      perPage={4}
      isActive={isActive}
    />
  );
}
