import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/loader";

import Snackbar from "../../../../components/snackbar/snackbar";
import {
  user_company_domain_list,
  user_get_pplication,
  user_update_request,
} from "../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import AreaDetails from "./components/areaDetails";
import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";
export default function UpdateCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [basicData, setBasicData] = useState({});
  const [areaData, setAreaData] = useState({});
  const [domainData, setDomain] = useState([]);
  const [addCount, setCount] = useState(1);
  const [data, setData] = useState({});
  const myRef = useRef(null);
  const [openView, setOpenView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [areamange, setareaManage] = useState({});
  const [fileChanged, setFileChanged] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [keyValue, setKeyValue] = useState(0);

  const getDomainTypes = (basedata) => {
    user_company_domain_list().then((res) => {
      let data = res.data;
      if (res.data?.length !== 0) {
        basedata?.domain_types?.forEach((item) => {
          data?.forEach((items) => {
            if (items.id === item.domain_type_id) {
              items["checked"] = true;
              // setBasicData({ ...basedata, any_other_flag: false });
            }
          });
        });

        setDomain(data);
      }
    });
  };
  const handleDomain = () => {
    if (basicData?.domain_types) {
      setCount(addCount + 1);
    }
  };
  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      let dom = [];
      domainData.forEach((item) => {
        if (item.checked)
          dom.push({ domain_name: item.domain_name, id: item.id });
      });
      setActiveStep(1);
      setBasicData({ ...basicData, ...data, domain_types: dom });
      //
    } else if (id === 2) {
      setActiveStep(2);
      setdefaultkey(key);
      setareaManage(data);
      setBasicData({ ...basicData, ...data });
    } else if (id === 1) {
      setActiveStep(0);
    } else if (id === 3) {
      setareaManage({
        area_type: basicData.area_type,
        area_management: basicData.area_management,
      });
      setActiveStep(1);
    } else if (id === 4) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    let areatype = [];
    if (basicData.area_management) {
      basicData?.area_management?.forEach((item, index) => {
        if (item.location_id) {
          areatype[index] = item;
        }
      });
    }
    form.append("company_name", basicData.company_name ?? null);
    form.append("person_name", basicData.person_name ?? null);
    form.append("person_designation", basicData.person_designation ?? null);
    form.append("address", basicData.address ?? null);
    form.append("email", basicData.email ?? null);
    form.append("alternate_email", basicData.alternate_email ?? "");
    form.append("domain_types", JSON.stringify(basicData.domain_types) ?? null);
    form.append(
      "abstract_project_proposal",
      basicData.abstract_project_proposal ?? null
    );
    form.append(
      "upload_proposal",
      fileChanged ? basicData.upload_proposal ?? "" : ""
    );
    form.append("capital_investment", basicData.capital_investment ?? null);
    form.append("ex_man_power", basicData.ex_man_power ?? null);
    form.append(
      "product_profiles",
      JSON.stringify(basicData.product_profiles) ?? null
    );
    form.append("area_type", basicData.area_type ?? null);
    form.append("area_management", JSON.stringify(areatype) ?? null);
    form.append("any_other_flag", basicData.any_other_flag ?? 0);
    form.append("any_other_domain", basicData.any_other_domain ?? "");
    form.append("file_name", basicData.file_name ?? "");
    if (basicData.id) form.append("application_id", basicData.id);
    setIsActive(true);
    user_update_request(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        console.log(form);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Area Requirements" },
  ];

  const headlperText = {
    header: "* Indicates mandatory fields",
  };
  const handleChangedata = (id, name) => {
    setdefaultkey(id);

    if (name === basicData?.area_type) {
      getUpdate();
    } else if (name === "built_up_space") {
      setareaManage(builtup);
    } else if (name === "equipped_facility") {
      setareaManage(euip);
    } else if (name === "land_on") {
      setareaManage(landon);
    } else if (name === "joint_venture") {
      setareaManage(jointVenture);
    } else if (name === "own_land") {
      setareaManage(ownland);
    }
    setCount(addCount + 1);
  };
  const getUpdate = () => {
    setareaManage({
      area_type: basicData?.area_type,
      area_management: basicData?.area_management,
    });
  };

  const getApplication = () => {
    setIsActive(true);
    user_get_pplication().then((res) => {
      let data = res.data;
      let d = {
        ...res.data,
        any_other_flag:
          data.any_other_flag && data.any_other_flag !== "0"
            ? data.any_other_flag
            : false,
        any_other_domain:
          data.any_other_domain && data.any_other_flag !== ""
            ? data.any_other_domain
            : null,
      };
      setBasicData(d);
      setareaManage({
        area_type: res.data?.area_type,
        area_management: res.data?.area_management,
      });

      setKeyValue(1);
      getDomainTypes(res.data);
      setIsActive(false);
    });
    setCount(addCount + 1);
  };
  useEffect(() => {
    getApplication();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/dashboard");
    }
  };
  const handleFile = () => {
    setFileChanged(true);
  };
  console.log(basicData);

  return (
    <div className="page-bg" ref={myRef}>
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Update Company Proposal</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container form-section">
          <div className="itemc">
            {activeStep < 2 && (
              <>
                <div className="header-helper-text">{headlperText.header}</div>
                <div className="d-flex">
                  {navLabel.map((item, index) => (
                    <div
                      className={
                        index % 2 === 1
                          ? item.activeStep === activeStep
                            ? "headerbuttoneven active"
                            : "headerbuttoneven"
                          : item.activeStep === activeStep
                          ? "headerbuttonodd active"
                          : "headerbuttonodd"
                      }
                    >
                      <div
                        className={
                          index % 2 === 1 ? "indexCont even" : "indexCont "
                        }
                      >
                        {index + 1}
                      </div>
                      <div> {item.name}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="fomy form-inner-section">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  edit={true}
                  key={keyValue}
                  formData={basicData}
                  setDomain={setDomain}
                  update={true}
                  handlefile={handleFile}
                  domainData={domainData}
                />
              )}
              {activeStep === 1 && (
                <AreaDetails
                  {...props}
                  key={addCount}
                  data={areaData}
                  update={true}
                  defaultkey={defaultkey}
                  handleChangedata={handleChangedata}
                  setareaManage={setareaManage}
                  action={action}
                  areamange={areamange}
                />
              )}
              {activeStep === 2 && (
                <View {...props} basicData={basicData} action={action} />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
