import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { common_file_upload, is_email_registered } from "../../../user.api";
export default function AreaDetails(props) {
  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);

  const [defaultkey, setdefaultkey] = useState(props.defaultkey);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setFormData(props.data);
    setCount(count + 1);
  }, [props.data]);
  console.log(props.data);
  const handlepreview = (e) => {
    e.preventDefault();
    let emailData = { email: formData.email };
    let Data = formData;
    let selectedareaofinterest = [];
    props.areaOfInterest.forEach((item) => {
      if (item.checked) {
        selectedareaofinterest.push(item);
      }
    });
    Data = {
      ...Data,
      area_of_interest: selectedareaofinterest,
    };
    if (!props.update) {
      is_email_registered(emailData)
        .then((res) => {
          if (
            formData.photo === null ||
            formData.photo === undefined ||
            formData.photo === ""
          ) {
            setSnackMessage("Please choose a valid photo file");
            setIsSnackError(true);
            setSnackOpen(true);
          } else {
            let validExp = true;
            formData.work_experience.forEach((item) => {
              if (
                item.experience_certificate === null ||
                item.experience_certificate === undefined ||
                item.experience_certificate === ""
              ) {
                setSnackMessage(
                  `Please choose a valid experience certificate file for ${item.organization_name}`
                );
                setIsSnackError(true);
                setSnackOpen(true);
                validExp = false;
              }
            });

            if (validExp) {
              let validEduc = true;
              formData.educational_qualifications.forEach((item) => {
                if (
                  item.file_upload_certificate === null ||
                  item.file_upload_certificate === undefined ||
                  item.file_upload_certificate === ""
                ) {
                  setSnackMessage(
                    `Please choose a valid educational certificate file for ${item.examination_passed}`
                  );
                  setIsSnackError(true);
                  setSnackOpen(true);
                  validEduc = false;
                }
              });
              if (validEduc) {
                props.action(0, Data, "basicData");
              }
            }
          }
          // setSnackOpen(true);
        })
        .catch((error) => {
          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setSnackOpen(true);
          // setIsActive(false);
        });
    } else {
      if (
        formData.photo === null ||
        formData.photo === undefined ||
        formData.photo === ""
      ) {
        setSnackMessage("Please choose a valid photo file");
        setIsSnackError(true);
        setSnackOpen(true);
      } else {
        let validExp = true;
        formData.work_experience.forEach((item) => {
          if (
            item.experience_certificate === null ||
            item.experience_certificate === undefined ||
            item.experience_certificate === ""
          ) {
            setSnackMessage(
              `Please choose a valid experience certificate file for ${item.organization_name}`
            );
            setIsSnackError(true);
            setSnackOpen(true);
            validExp = false;
          }
        });

        if (validExp) {
          let validEduc = true;
          formData.educational_qualifications.forEach((item) => {
            if (
              item.file_upload_certificate === null ||
              item.file_upload_certificate === undefined ||
              item.file_upload_certificate === ""
            ) {
              setSnackMessage(
                `Please choose a valid educational certificate file for ${item.examination_passed}`
              );
              setIsSnackError(true);
              setSnackOpen(true);
              validEduc = false;
            }
          });
          if (validEduc) {
            props.action(0, Data, "basicData");
          }
        }
      }
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };

  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/profile-pic");
    // file.append("type", "expert-manpower/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.educational_qualifications;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.work_experience;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          experience_certificate: res.data,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        uploadFile(e);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        file_name: null,
      });
    }
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.educational_qualifications;
    if (type === "png" || type === "jpeg" || type === "jpg" || type === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          educational_qualifications: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        educational_qualifications: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let exp = formData.work_experience;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg" || type === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        expUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          file_name: null,
          experience_certificate: null,
        };
        setFormData({
          ...formData,
          work_experience: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        work_experience: exp,
      });
    }
  };

  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.educational_qualifications.push({
      examination_passed: "",
      board_of_university: "",
      year_of_pass: "",
      mark_grade: "",
      file_upload_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.educational_qualifications.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    let data = { ...formData };
    data.work_experience[i] = {
      ...data.work_experience[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const addExperience = (i) => {
    let data = { ...formData };

    data.work_experience.push({
      organization_name: "",
      experience: "",
      nature_of_work: "",
      designation: "",
      reason_for_leaving: "",
      experience_certificate: null,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.work_experience.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const addExpertice = (i) => {
    let data = { ...formData };

    data.expertise_area.push({
      expertise_name: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleCheckChange = (e, item) => {
    let data = [...props.areaOfInterest];

    data[item]["checked"] = e.target.checked;

    //setFormData({ ...formData, any_other_flag: false });

    props.setAreaOfInterest(data);
  };

  const handleValid = () => {
    let flags = false;
    if (props.areaOfInterest) {
      props.areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const textFieldChange = (e) => {
    let data = formData;

    data = {
      ...data,

      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };

  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.educational_qualifications[i] = {
      ...data.educational_qualifications[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.work_experience[i] = {
      ...data.work_experience[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };

  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: Number(e.target.value),
    };
    setFormData(data);
  };
  // const selectFieldChange = (e) => {
  //   let value = e.target.value;
  //   const selectedCat = props.category?.find(({ uuid }) => uuid === value);

  //   let data = formData;
  //   data = {
  //     ...data,

  //     category: value,
  //     category_name: selectedCat.cat_name,
  //   };
  //   setFormData(data);
  // };

  return (
    <div className="expert-man-power">
      <Loader open={isActive} />
      <form onSubmit={handlepreview}>
        <div className="col-xs-12 col-sm-12">
          <div className="header-label">Manpower Type</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="category"
            value={formData?.category ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option value="">Select Expert Manpower Type</option>

            <option
              value="Supervisor"
              name="Supervisor"
              // select={formData?.category_name === item.cat_name}
            >
              Supervisor
            </option>
            <option
              value="Worker"
              name="Worker"
              // select={formData?.category_name === item.cat_name}
            >
              Worker
            </option>
            <option
              value="Supervisor and Worker"
              name="Supervisor and Worker"
              // select={formData?.category_name === item.cat_name}
            >
              Supervisor and Worker
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12 mt-2">
          <CommonForm
            fieldType="text"
            label="Full Name"
            required
            name="full_name"
            value={formData?.full_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <CommonForm
              fieldType="text"
              label="Age"
              required
              name="age"
              maxLength="3"
              value={formData?.age ?? ""}
              onChange={(e) => {
                numberChange(e);
              }}
              {...props}
            />
          </div>
          <div className="col-xs-12 col-sm-6 mt-2 d-flex justify-content-start align-items-center">
            <div className="control-label ">Gender : </div>
            <div className="d-flex ">
              <CommonForm
                fieldType="radio"
                label="Male"
                required
                name="gender"
                value={1}
                checked={formData?.gender === 1}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Female"
                required
                name="gender"
                checked={formData?.gender === 2}
                value={2}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Other"
                required
                name="gender"
                checked={formData?.gender === 3}
                value={3}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Address"
            multiline
            required
            className="no-margin"
            name="address"
            value={formData?.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="email"
            label="Email"
            disabled={props.update}
            value={formData?.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            required
            name="email"
            {...props}
          />
        </div>
        <div className="header-label">Educational Qualification</div>
        {formData.educational_qualifications?.map((e, i) => (
          <div className="section-datas-cont mb-3">
            <div className="section-Cont">
              <div className="row">
                <div className="col-xs-12 col-sm-4">
                  <CommonForm
                    fieldType="text"
                    label="Examination passed"
                    required
                    name="examination_passed"
                    value={e?.examination_passed}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Board/University"
                    required
                    name="board_of_university"
                    value={e?.board_of_university}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-6 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Year of Passing"
                    required
                    name="year_of_pass"
                    minlength="4"
                    maxLength="4"
                    value={e?.year_of_pass}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-6 col-sm-2">
                  <CommonForm
                    fieldType="text"
                    label="Mark/grade"
                    required
                    minlength="1"
                    maxLength="4"
                    name="mark_grade"
                    value={e?.mark_grade}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-12">
                  <CommonForm
                    fieldType="file"
                    required={!e.file_name ? true : false}
                    label="Upload self attested photocopy of certificate"
                    name="file_upload_certificate"
                    {...props}
                    id="file_upload_certificate"
                    className="no-margin"
                    accept=".jpg,.jpeg,.png,.pdf"
                    type="file"
                    variant="outlined"
                    fileName={e?.file_name}
                    //filename={e?.file_name}
                    // onChange={handleFileChange}
                    onChange={(e) => {
                      handleEducationalFileChange(e, i);
                    }}
                  />
                  <div className="file-up-helper">
                    *Upload only .jpg , .jpeg, .png , .pdf format and Size
                    should be less than 1MB
                  </div>
                </div>
              </div>
            </div>

            <div className="more-btn-cnt">
              {formData.educational_qualifications.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addeducationalQualifications(i)}
                >
                  Add More
                </button>
              )}
              {formData.educational_qualifications.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeducationalQualifications(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="header-label">Experience Details</div>
        {formData.work_experience?.map((e, i) => (
          <div className="section-datas-cont">
            <div className="section-Cont">
              <div className="row">
                <div className="col-xs-12 col-sm-4">
                  <CommonForm
                    fieldType="text"
                    label="Organization with Address"
                    required
                    name="organization_name"
                    value={e?.organization_name}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Experience (Yrs.)"
                    required
                    // minlength="4"
                    maxLength="2"
                    name="experience"
                    value={e?.experience}
                    onChange={(e) => {
                      handleExperiancelNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Nature of  Work"
                    required
                    name="nature_of_work"
                    value={e?.nature_of_work}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-2">
                  <CommonForm
                    fieldType="text"
                    label="Designation"
                    required
                    name="designation"
                    value={e?.designation}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Reason for Leaving"
                    multiline
                    required
                    value={e?.reason_for_leaving}
                    onChange={(e) => {
                      handleExperienceChange(e, i);
                    }}
                    className="no-margin"
                    name="reason_for_leaving"
                    {...props}
                  />
                </div>
                <div className="col-12">
                  <CommonForm
                    fieldType="file"
                    required={!e.file_name ? true : false}
                    label="Upload self attested photocopy of certificate"
                    name="experience_certificate"
                    onChange={(e) => {
                      handleExperienceFileChange(e, i);
                    }}
                    {...props}
                    fileName={e?.file_name}
                    //filename={e?.file_name}
                    id="experience_certificate"
                    accept=".jpg,.jpeg,.png,.pdf"
                    type="file"
                    variant="outlined"
                    // onChange={handleFileChange}
                  />
                  <div className="file-up-helper">
                    *Upload only .jpg , .png, .jpeg , .pdf format and Size
                    should be less than 1MB
                  </div>
                </div>
              </div>
            </div>

            <div className="more-btn-cnt">
              {formData.work_experience.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addExperience(i)}
                >
                  Add More
                </button>
              )}
              {formData.work_experience.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeExperiance(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}

        {formData.expertise_area?.map((e, i) => (
          <div className="section-datas-cont">
            {/* <div className="col-xs-12 col-sm-12"> */}
            <CommonForm
              fieldType="text"
              label="Area of Expertise"
              required
              value={e?.expertise_name}
              onChange={(e) => {
                handleExperticeChange(e, i);
              }}
              className="no-margin"
              name="expertise_name"
              {...props}
            />
            {/* </div> */}

            <div className="more-btn-cnt">
              {formData.expertise_area.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addExpertice(i)}
                >
                  Add More
                </button>
              )}
              {formData.expertise_area.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeExpertice(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}

        {/* <div className="fieldset">
          <div className="header-label">Any other area of interest</div>
          <div
          // className="check-box-cont"
          >
            <div className="p-10 check-box-cont">
              {" "}
              {props.areaOfInterest?.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  value={item.uuid}
                  checked={item.checked}
                  required={!handleValid()}
                  onChange={(e) => handleCheckChange(e, index)}
                  label={item.area_of_interest_name}
                  {...props}
                />
              ))}
            </div>
          </div>
        </div> */}

        <div className="col-12">
          <CommonForm
            fieldType="file"
            required={!formData?.file_name ? true : false}
            label="Upload  your Photo"
            name="photo"
            {...props}
            id="photo"
            fileName={formData?.file_name}
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .jpeg, .png , format and Size should be less
            than 1MB
          </div>
        </div>

        <div className="footer-buttons">
          <button type="submit" className="primary-btn">
            SUBMIT TO PREVIEW
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
