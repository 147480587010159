import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import CustomTable from "../../../components/table/table";
import "./view.css";
import Snackbar from "../../../components/snackbar/snackbar";

import { user_company_list, super_comp_delete } from "../admin.api";
import Loader from "../../../components/loader/loader";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import jwtDecode from "jwt-decode";
const adminStatus = [{ id: 0, label: "Pending" }];
export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [status, setStatus] = useState(9);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  let statusData = [
    { id: 9, label: "All" },
    { id: 0, label: "Pending" },
    { id: 1, label: "Submitted for Acceptance" },
    {
      id: 4,
      label: "Approve",
    },
    { id: 5, label: "Reject" },
  ];

  const handleEdit = () => {};
  const handleView = (data) => {
    let path = "/view-application-international/" + data.user_id;
    props.history.push(path);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    super_comp_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleListData = () => {
    console.log(adminType);
    setIsActive(true);
    let param = {
      type: "international",
      status: status === 9 ? "" : status,
    };
    user_company_list(param, adminType).then((resp) => {
      setData(resp.data);
      setIsActive(false);
    });
  };
  const onSearch = () => {
    handleListData();
  };
  useEffect(() => {
    handleListData();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleListData();
    }
  };
  const handleNew = (e) => {
    props.history.push("/newrequest-application-international");
  };
  const handleChangeStatus = (e) => {
    let val = Number(e.target.value);
    console.log(val);
    setStatus(val);
  };

  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <h6>
          <b>Companies in International</b>
        </h6>
        <hr />

        <div className="row font-z">
          <div className="col-3 ">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleChangeStatus}
            >
              {statusData.map((item) => (
                <option
                  value={item.id}
                  key={item.id}
                  selected={item.id === status}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-1 d-flex">
            <SearchButton {...props} onClick={onSearch} />
          </div>
          {adminType === 1 && (
            <div className="col-8 d-flex justify-content-end">
              <button
                type="button"
                className=" btn btn-primary btn-sm"
                onClick={(e) => handleNew(e)}
              >
                Add New Company
              </button>
            </div>
          )}
        </div>
        <CustomTable
          {...props}
          columns={[
            { title: "Company Name", field: "company_name" },
            // { title: "Application ID", field: "id" },
            { title: "Phone Number", field: "phone_no" },
            { title: "Email", field: "email" },
            // { title: "Current Project Status", field: "projectstatus" },

            { title: "Status", field: "project_status", type: "regstatusType" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "delete", handleClick: handleDelete },
                  
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  // { type: "delete", handleClick: handleDelete },
                ]
          }
        />
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Company ?"
          data={activeData}
          message="Are you sure to delete this Company?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
