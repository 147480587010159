import React, { useState } from "react";
import "./style.css";
export default function FormOptionTable(props) {
  const [formvalues, setFormValues] = useState(props.formvalues);
  const handleChange = (e, item) => {
    let data = [...formvalues];
    if (props.formData.area_type === "land_on") {
      data[item]["area_in_acres"] = "";
    } else {
      data[item]["area_in_sqft"] = "";
    }

    let loc = props.data.filter((a) => a.location_id === e.target.value);
    //
    data[item] = {
      ...data[item],
      [e.target.name]: e.target.value,
      location: loc[0]?.location ? loc[0]?.location : "",
      maxArea:
        props.formData.area_type !== "land_on"
          ? loc[0]?.area_in_sqft
            ? loc[0]?.area_in_sqft
            : 0
          : loc[0]?.area_in_acres
          ? loc[0]?.area_in_acres
          : 0,
      area_sub_type_id: loc[0]?.id ? loc[0]?.id : "",
      area_management_id: loc[0]?.parent_id ? loc[0]?.parent_id : "",
    };
    setFormValues(data);
    setTimeout(() => {
      props.handleFormData(data);
    }, 100);
  };
  const handleText = (e, item) => {
    let data = [...formvalues];
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    let activeSelection = data[item];
    if (validNumber.test(e.target.value)){
    if (Number(activeSelection.maxArea) >= Number(e.target.value)) {
      if (Number(e.target.value >= 0)) {
        data[item] = {
          ...data[item],
          [e.target.name]: e.target.value,
        };
      }
    }}
    setFormValues(data);
    setTimeout(() => {
      props.handleFormData(data);
    }, 100);
  };
  //
  //
  return (
    <div>
      <div>Select Space as per Priority (Select Maximum 3 Locations)</div>

      <div className="table-container">
        <React.Fragment>
          <div className="container scrolltable">
            <table className="table-bordered">
              <thead className="bg-col">
                <tr>
                  <th className="noFieldTableWidth">No</th>
                  <th>Select Location</th>
                  {props.formData.area_type !== "land_on" ? (
                    <th> Required Area in sq.ft</th>
                  ) : (
                    <th> Required Area in acres</th>
                  )}
                  {/* <th> Rs/sq.ft</th> */}
                </tr>
              </thead>
              <tbody className="bg-col">
                {/* {formvalues?.map((item, index) => ( */}
                {[...Array(3)].map((e, i) => (
                  <tr>
                    <td className="bg-col">{i + 1}</td>
                    <td className="bg-col">
                      <select
                        id="location"
                        className="form-select selectbox"
                        required
                        disabled={props.disabled}
                        name="location_id"
                        onChange={(e) => handleChange(e, i)}
                        value={formvalues[i]?.location_id ?? ""}
                      >
                        <option value=""></option>
                        {props.data &&
                          props.data.map((loc) => (
                            <option value={loc.location_id}>
                              {loc.location}
                            </option>
                          ))}
                      </select>
                    </td>
                    {props.formData.area_type !== "land_on" ? (
                      <td className="bg-col">
                        <input
                          type="text"
                          className="form-control"
                          required
                          disabled={props.disabled}
                          min={0}
                          name="area_in_sqft"
                          onChange={(e) => handleText(e, i)}
                          value={formvalues[i]?.area_in_sqft ?? ""}
                        />
                      </td>
                    ) : (
                      <td className="bg-col">
                        <input
                          type="text"
                          className="form-control"
                          min={0}
                          required
                          disabled={props.disabled}
                          name="area_in_acres"
                          onChange={(e) => handleText(e, i)}
                          value={formvalues[i]?.area_in_acres ?? ""}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
