const home = { name: "<< Back to home", path: "/", isActive: true };
const myhome = { name: "Home", path: "/", isActive: false };

const events = {
  name: "Events",
  path: "/events",
  isActive: true,
};

const event_details = {
  name: "Event Details",
  path: null,
  isActive: true,
};
const expertManpower = {
  name: "Expert Manpower",
  path: "/expert-manpower",
  isActive: true,
};

const expertManpower_details = {
  name: "Expert Manpower Details",
  path: null,
  isActive: true,
};
const resoursePerssonal = {
  name: "Resource Personnel",
  path: "/resource-personal",
  isActive: true,
};

const resoursePerssonal_details = {
  name: "Resource Personnel Details",
  path: null,
  isActive: true,
};
module.exports = {
  home: [myhome],
  events: [myhome, events],
  eventDetails: [myhome, events, event_details],
  homeeventDetails: [myhome, events, event_details],
  expertManpower_details: [myhome, expertManpower, expertManpower_details],
  resoursePerssonalDetails: [
    myhome,
    resoursePerssonal,
    resoursePerssonal_details,
  ],
};
