import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../components/table/table";
import "./view.css";
import {
  studentInterndetails,
  studentintern_status_update,
} from "../../admin.api";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import moment from "moment";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

export default function View(props) {
  const [data, setData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const handleDownload = (dt) => {
    let path = `${result}${dt.certificate_photo}`;
    window.open(path, "_blank");
  };

  const getDetails = (id) => {
    studentInterndetails(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const saMessage = (status) => {
    Swal.fire({
      inputLabel: 'Message',
      input : 'textarea',
      showCancelButton: false,
      confirmButtonColor: '#0A223D',
      confirmButtonText: 'Submit',
      inputAttributes: {
        'aria-label': 'Type your message here',
    },
    inputValidator: (value) => {
      if (!value) {
        return 'Please describe the reason'
      }
    },
  showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let dat ={}
        if (adminType === 1) {
          if (status === 1) {
            dat = { status_id: status, accademic_training_id: selectedParam.id, admin_message: message.value };
          } else {
            dat = { status_id: status, accademic_training_id: selectedParam.id, user_message: message.value };
          }
        } else {
          dat = { status_id: status, accademic_training_id: selectedParam.id, super_admin_message: message.value };
        }
        // let data = { status_id: status, institution_id: selectedParam.id , message: message.value};
        setIsActive(true);
        studentintern_status_update(dat)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
            getDetails(selectedParam.id)
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
      }
    })
  }
  const handleAdminActions = (status) => {
    let dat = { status_id: status, accademic_training_id: selectedParam.id };
    setIsActive(true);
    studentintern_status_update(dat)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
        getDetails(selectedParam.id)
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/intershiptraining-list");
    }
  };
  console.log(data);
  return (
    <div>
      {/* <Loader open={isActive} /> */}
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Internship/Training Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Full Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.full_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">{`Date of Birth`}</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.date_of_birth &&
                moment(data.date_of_birth).format("DD-MMM-YYYY")}
              {/* && (
                <>{moment(data.date_of_birth).format("DD-MM-YYYY")}</>
              )} */}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.gender ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.address ?? ""}</div>
          </div>

          <div className="row">
            <div className="col-11">Educational Qualification</div>

            <div className="col-12 mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: "Examination passed ", field: "exam_passed" },
                  { title: "Board", field: "board" },
                  { title: "Year of Passing", field: "year_passed" },
                  { title: "Mark", field: "mark" },

                  {
                    title: "Photocopy of certificate",
                    field: "certificate_photo",
                    type: "download",
                  },
                ]}
                data={data?.intern_qualification ?? []}
                handleDownload={handleDownload}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
            {data?.intern_languages?.map((item, index) => (
                <span>
                  {index !== 0 && " | "}
                  {item.language}
                </span>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-3">Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img
                className="res_photo"
                src={`${result}${data?.file_upload_photo}`}
                alt=""
              />
            </div>
          </div>

          {adminType === 1 && (
            <>
              {data.status === 0 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(1)}
                  >
                    Submit for Acceptance
                  </button>

                  {/* <button type="button" className="btn btn-reject btn-danger">
              Reject 
            </button>  */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {data.status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(4)}
                  >
                    Approve
                  </button>
                  <br />
                  <button
                    type="button"
                    className="btn btn-reject  btn-danger "
                    onClick={(e) => handleAdminActions(5)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
