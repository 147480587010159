import React, { useEffect, useState } from "react";
import CustomIcons from "../../../../components/icons/kIcons.component";

export default function TechPropTile(props) {
  const [data, setData] = useState(props.data);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);

  const handleDownload = (e) => {
    let path = `${result}${data.file_upload_proposal}`;
    window.open(path, "_blank");
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  return (
    <div className="card proposal-gaps">
      <div className="row">
        <div className="col-12 col-md-10">
          <div className="user-tech-prop-tile-data-container">
            <div className="user-tech-prop-tile-title">
              {data.project_proposal_title}
            </div>
            <div className="user-tech-prop-tile-desc">
              {data.short_details_proposal}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2 ">
          <div className="user-tech-prop-tile-download-cont">
            <div
              className="user-tech-prop-tile-download"
              onClick={(e) => handleDownload(e)}
            >
              <CustomIcons iconName="filedownload" iconClass="allToCenter" />
              <div className="d-flex d-md-none downloadText-prop">
                Download Proposal
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
