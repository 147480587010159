import React, { useEffect, useState } from "react";
import Underline from "../../../../components/page/home/underline/underline";
import ProgressBar from "../../../../components/progressbar/progressbar";
import { public_accomplishments_list } from "../../public.api";

export default function Accomplishments(props) {
  const [data, setdata] = useState({});

  const handleEventList = () => {
    public_accomplishments_list().then((res) => {
      setdata(res.data ? res.data[0] : {});
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  return (
    <div>
      <div className="new-accomp-cont">
        <div className="new-accom-title">
          <div>
            {props.state.language === "ma"
              ? "ഞങ്ങളുടെ നേട്ടങ്ങൾ"
              : "Our Accomplishments"}
          </div>
        </div>

        <div className="acom-data">
          <div className="accom-rt">
            <div className="accom-title">
              <div className="accom-name-span">
                {props.state.language === "ma"
                  ? "കെസ്പെസിനുള്ളിലെ  കമ്പനികൾ"
                  : " Companies operating within kSpace"}
              </div>
            </div>
            <div>
              <div className="accom-items-cont">
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.no_companies_kspace ?? 0}
                    iconName="company"
                    label={
                      props.state.language === "ma"
                        ? "കമ്പനികളുടെ എണ്ണം"
                        : "Number of Companies"
                    }
                  />
                </div>
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.no_employees_kspace ?? 0}
                    iconName="employee"
                    label={
                      props.state.language === "ma"
                        ? "ജീവനക്കാരുടെ എണ്ണം"
                        : "Number of Employees"
                    }
                  />
                </div>

                <div className="progressCont">
                  <ProgressBar
                    progress={data?.capital_investment_kspace ?? 0}
                    iconName="invest"
                    label={
                      props.state.language === "ma"
                        ? "മൂലധന നിക്ഷേപം (കോടിയിൽ)"
                        : "Capital Investment (in Crore)"
                    }
                  />
                </div>
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.annual_turn_over_kspace ?? 0}
                    iconName="turnover"
                    label={
                      props.state.language === "ma"
                        ? "വാർഷിക ടേൺ ഓവർ (കോടിയിൽ)"
                        : "Annual Turn Over (in Crore)"
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="accom-left">
            <div className="accom-title">
              <div className="accom-name-span">
                {props.state.language === "ma"
                  ? "കേരളത്തിനുള്ളിലെ  കമ്പനികൾ"
                  : "Companies operating within Kerala"}
              </div>
            </div>
            <div className="">
              <div className="accom-items-cont">
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.no_companies_kerala ?? 0}
                    iconName="company"
                    label={
                      props.state.language === "ma"
                        ? "കമ്പനികളുടെ എണ്ണം"
                        : "Number of Companies"
                    }
                  />
                </div>
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.no_employees_kerala ?? 0}
                    iconName="employee"
                    label={
                      props.state.language === "ma"
                        ? "ജീവനക്കാരുടെ എണ്ണം"
                        : "Number of Employees"
                    }
                  />
                </div>

                <div className="progressCont">
                  <ProgressBar
                    progress={data?.capital_investment_kerala ?? 0}
                    iconName="invest"
                    label={
                      props.state.language === "ma"
                        ? "മൂലധന നിക്ഷേപം (കോടിയിൽ)"
                        : "Capital Investment (in Crore)"
                    }
                  />
                </div>
                <div className="progressCont">
                  <ProgressBar
                    progress={data?.annual_turn_over_kerala ?? 0}
                    iconName="turnover"
                    label={
                      props.state.language === "ma"
                        ? "വാർഷിക ടേൺ ഓവർ (കോടിയിൽ)"
                        : "Annual Turn Over (in Crore)"
                    }
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
