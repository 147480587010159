import React, { useState } from "react";
import Loader from "../../../components/loader/loader";

import Snackbar from "../../../components/snackbar/snackbar";
import { userReg } from "./auth.api";
import "./login.css";

export default function Login(props) {
  const [loginParams, setLoginParams] = useState({});
  const [submit, setSubmit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [newuser, setnewuser] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);
  const verifyUser = (e) => {
    e.preventDefault();
    setIsActive(true);
    setSubmit(true);
    userReg(loginParams)
      .then((res) => {
        if (res.new_user) {
          setnewuser(false);
          props.setPhone(loginParams.mobile_number);
          setSnackMessage(res.message);
          setIsSnackError(false);
          setIsActive(false);
          setSnackOpen(true);
        } else {
          setnewuser(true);
          setSnackMessage("This mobile number is already registered");
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        }
      })
      .catch((err) => {
        setSubmit(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleChange = (e) => {
    var ticket = e.target.value;
    var reg = new RegExp("^[0-9]*$");

    if (e.target.validity.patternMismatch) {
      e.target.setCustomValidity("Please enter valid 10 digit number");
    } else {
      e.target.setCustomValidity("");
    }

    if (reg.test(ticket)) {
      setLoginParams({ [e.target.name]: ticket });
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/verify");
    } else if (flag && newuser) {
      localStorage.clear();
      props.history.push("/login");
    }
  };
  return (
    <div className="login-container">
      <div className="login-section">
        <Loader open={isActive} />
        <div className="">
          <form onSubmit={verifyUser}>
            <div className="login-header">Verification</div>
            <div className="login-body d-flex justify-content-center align-items-center flex-column">
              <div>
                <img src="/resources/img/login/login-avatar.png" alt="" />
              </div>
              <div className="number-label ">Enter your Mobile Number</div>

              <div className="d-flex">
                <div className="">
                  <input
                    type="text"
                    value="+91"
                    className="number-prefix"
                    disabled
                  />
                </div>
                <div className="">
                  <input
                    type="tel"
                    className="number"
                    name="mobile_number"
                    maxLength="10"
                    minLength="10"
                    required
                    pattern="[0-9]{10}"
                    value={loginParams.mobile_number ?? ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="login-action">
              <button className="login-button" disabled={submit}>
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
