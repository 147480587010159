import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import TextField from "../../../../../components/inputs/textfield";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_banner_add } from "../../../admin.api";
import "./dialog.css";

let required = ["banner_image"];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isActive: false,
      fileChanged: false,
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    //
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      const form = new FormData();
      if (formData.banner_location_text_ma)
        form.append(
          "banner_location_text_ma",
          formData.banner_location_text_ma
        );
      if (formData.banner_location_text_en)
        form.append(
          "banner_location_text_en",
          formData.banner_location_text_en
        );
      if (formData.banner_main_text_en)
        form.append("banner_main_text_en", formData.banner_main_text_en);
      if (formData.banner_main_text_ma)
        form.append("banner_main_text_ma", formData.banner_main_text_ma);

      if (formData.banner_highlight_text_en)
        form.append(
          "banner_highlight_text_en",
          formData.banner_highlight_text_en
        );
      if (formData.banner_highlight_text_ma)
        form.append(
          "banner_highlight_text_ma",
          formData.banner_highlight_text_ma
        );
      form.append(
        "banner_image",
        this.state.fileChanged ? formData.banner_image : null
      );
      if (formData.id) form.append("banner_id", formData.id);

      admin_banner_add(form)
        .then((res) => {
          this.setState({
            submitted: true,
            isActive: false,
            snackMessage: res.message,
            isSnackError: false,
            snackOpen: true,
          });
        })
        .catch((error) => {
          this.setState({
            submitted: false,
            snackMessage: error.message,
            isSnackError: true,
            isActive: false,
            snackOpen: true,
          });
        });
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0]?.name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;

          if (height === 940 || width === 1920) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          this.setState({
            fileChanged: true,
            formData: {
              ...this.state.formData,
              [e.target.name]: e.target.files[0],
            },
          });
        } else {
          this.setState({
            snackMessage: "Incorrect image dimension",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }, 200);
    } else {
      this.setState({
        snackMessage: "File should be in .jpg / .jpeg / .png format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  render() {
    const { submitted, formData, open, edit, isActive } = this.state;
    const { view } = this.props;
    //
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <Loader open={isActive} />
              <div className="big-dia dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Banner" : view ? "Banner" : "Add Banner"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="book-form">
                      <div className="content-scroll">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            disabled={view}
                            label="Heading [Eng]"
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.banner_main_text_en ?? ""}
                            name="banner_main_text_en"
                            // required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Heading [Mal]"
                            disabled={view}
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.banner_main_text_ma ?? ""}
                            name="banner_main_text_ma"
                            // required
                          />
                        </div>
                        <div className="col-12 not-required">
                          <TextField
                            id="outlined-basic"
                            name="banner_highlight_text_en"
                            onChange={(e) => this.handleChange(e)}
                            // multiline
                            disabled={view}
                            label="Highlight Text [Eng]"
                            value={formData.banner_highlight_text_en ?? ""}
                            variant="outlined"
                            // required
                          />
                        </div>
                        <div className="col-12 not-required">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.banner_highlight_text_ma ?? ""}
                            // multiline
                            disabled={view}
                            label="Highlight Text [Mal]"
                            name="banner_highlight_text_ma"
                            variant="outlined"
                            // required
                          />
                        </div>
                        <div className="col-12 not-required">
                          <TextField
                            id="outlined-basic"
                            label="Location [Eng]"
                            disabled={view}
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.banner_location_text_en ?? ""}
                            name="banner_location_text_en"
                            // required
                          />
                        </div>
                        <div className="col-12 not-required">
                          <TextField
                            id="outlined-basic"
                            label="Location [Mal]"
                            disabled={view}
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.banner_location_text_ma ?? ""}
                            name="banner_location_text_ma"
                            // required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Image(Dimension:width=1920,height=940 format= .jpg , .jpeg ,.png)"
                            accept=".jpg ,.jpeg,.png"
                            name="banner_image"
                            disabled={view}
                            type="file"
                            variant="outlined"
                            // value={formData.banner_image ?? ""}
                            onChange={this.handleFileChange}
                            required
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
