import React from "react";

import "./resourse.css";

export default function ResourseTile(props) {
  const expertiseIn = (data) => {
    let expin = "";
    let dataArray = [];
    data.forEach((item, i) => {
      if (i > 0) {
        dataArray.push(" | ");
      }
      dataArray.push(item.expertise_name);
    });
    console.log(dataArray);
    expin = String(dataArray) || "";
    return expin;
  };
  return (
    <div className="resourse-tile">
      <div className="resource-image-cont">
        <img src={props.photo} alt="" className="resource-image" />
      </div>
      <div className="resource-desc-container">
        <div className="res-title">{props.title}</div>
        <div className="res-email">{props.email}</div>
        <div className="res-exp-heading">Expertise in</div>
        <p className="expertize-decs">
          {/* Mentor ,Consultant, Manager/ Executive */}
          {(props.expIn && props.expIn.length !== 0) && (
            <>
              <>
                {props.expIn.map((item, i) => (
                  <>
                    {item.expertise_name}{" "}
                    {i + 1 !== props.expIn.length && <>&nbsp;| &nbsp;</>}
                  </>
                ))}
              </>
            </>
          )}
        </p>
        <div className="exp-read-more">
          <button
            className="read-more-button"
            onClick={(e) => {
              props.history.push(props.path);
            }}
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  );
}
