import React, { useState } from "react";
import Loader from "../../../components/loader/loader";

import Snackbar from "../../../components/snackbar/snackbar";
import { userLogin } from "./auth.api";
import "./login.css";

export default function Login(props) {
  const [loginParams, setLoginParams] = useState({});
  const [submit, setSubmit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const verifyUser = (e) => {
    e.preventDefault();
    setIsActive(true);
    setSubmit(true);
    userLogin(loginParams)
      .then((res) => {
        props.setPhone(loginParams.mobile_number);
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((err) => {
        setSubmit(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleChange = (e) => {
    var ticket = e.target.value;
    var reg = new RegExp("^[0-9]*$");

    if (e.target.validity.patternMismatch) {
      e.target.setCustomValidity("Please enter valid 10 digit number");
    } else {
      e.target.setCustomValidity("");
    }

    if (reg.test(ticket)) {
      setLoginParams({ [e.target.name]: ticket });
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/otp");
    }
  };
  return (
    <>
      {" "}
      <div className="area">
        {/* <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> */}
        <Loader open={isActive} />

        <div className="login-container">
          <div className="login-section">
            <div className="">
              <form onSubmit={verifyUser}>
                <div className="login-header">Login</div>
                <div className="login-body d-flex justify-content-center align-items-center flex-column">
                  <div>
                    <img src="/resources/img/login/login-avatar.png" alt="" />
                  </div>
                  <div className="number-label ">
                    Enter your Registered Mobile Number
                  </div>

                  <div className="d-flex">
                    <div className="">
                      <input
                        type="text"
                        value="+91"
                        className="number-prefix"
                        disabled
                      />
                    </div>
                    <div className="">
                      <input
                        type="tel"
                        className="number"
                        name="mobile_number"
                        maxLength="10"
                        minLength="10"
                        pattern="[0-9]{10}"
                        required
                        value={loginParams.mobile_number ?? ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="login-action">
                  <button
                    className="login-button"
                    type="submit"
                    disabled={submit}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Snackbar
            message={snackMessage}
            error={isSnackError}
            open={snackOpen}
            close={snackbarClose}
          />
        </div>
      </div>
    </>
  );
}
