import React, { useEffect, useState } from "react";
import RadioSelect from "../../../../components/inputs/radio";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";
import Loader from "../../../../components/loader/loader";
import { FormValidate } from "../../../../components/formValidate";
import { admin_tech_prop_add } from "../../admin.api";
import "./view.css";
let required = [
  "short_details_proposal",
  "project_proposal_title",
  "file_upload_proposal",
];
export default function AddSpaceparkAdvisors(props) {
  const [formData, setFormData] = useState({});

  const [snackMessage, setSnackMessage] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let data = formData;
    data = { ...data, [name]: value };
    setFormData(data);
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "pdf" || type === "doc" || type === "docx") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: null,
        });
        setSnackOpen(true);
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        // file_name: e.target.files[0].name,
        [e.target.name]: null,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.file_upload_proposal === "" ||
      formData.file_upload_proposal === undefined ||
      formData.file_upload_proposal === null
    ) {
      setSnackMessage("Please choose a valid proposal file");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
    } else {
      const errors = FormValidate(formData, required);
      setIsActive(true);
      if (Object.keys(errors).length === 0) {
        const form = new FormData();
        form.append("short_details_proposal", formData.short_details_proposal);
        form.append("project_proposal_title", formData.project_proposal_title);
        form.append("file_upload_proposal", formData.file_upload_proposal);

        admin_tech_prop_add(form)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
        // console.log(formData);
      } else {
        setSnackMessage("Please fill all mandatory fields");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      }
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/transfer-proposal-list");
    }
  };
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Add Technology Transfer Proposal</b>
        </h6>
        <hr />
        <div className="row">
          <div className="col-8 ">
            <TextField
              fullwidth
              label="Proposal Title"
              required
              value={formData.project_proposal_title ?? ""}
              onChange={(e) => handleChange(e)}
              name="project_proposal_title"
              id="project_proposal_title"
              aria-describedby="emailHelp"
            />
          </div>

          <div className="col-8">
            <TextField
              required
              label="Proposal Description"
              onChange={(e) => handleChange(e)}
              name="short_details_proposal"
              value={formData.short_details_proposal}
              aria-describedby="emailHelp"
              multiline
              fullwidth
            />
          </div>

          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Upload Proposal"
              required
              onChange={(e) => handleFileChange(e)}
              id="file_upload_proposal"
              name="file_upload_proposal"
              accept=".pdf,.docx,.doc"
              type="file"
            />
            <small className="pers">
              *Upload only .pdf , .docx , .doc format and Size should be less
              than 5MB
            </small>
          </div>
          <div className="col-8">
            <center>
              <button
                type="button"
                className="btn btn-dark mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
