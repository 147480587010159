import "./events.css";
import "../home/home.css";
import Masonry from "react-responsive-masonry";
import { public_gallery_list } from "../public.api";

import { useState, memo, useCallback, useEffect } from "react";
import Loader from "../../../components/loader/loader";
import Footer from "../../../layout/userlayout/components/footer/footer";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
const GalleryImages = (props) => {
  let lang = props.state.language;
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [activeSelection, setActiveSelection] = useState(null);
  const fetchEvents = () => {
    public_gallery_list().then((res) => {
      setData(res.data);
      setUrl(res.current_url);
      setIsActive(false);
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  const settingColumns = useCallback(() => {
    if (window.innerWidth >= 1400) return 4;
    if (window.innerWidth >= 800) return 3;
    if (window.innerWidth >= 500) return 2;
    return 1;
  }, []);

  const [column, setColumn] = useState(() => settingColumns());
  let fromHome = sessionStorage.getItem("continueFrom");
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", () => setColumn(() => settingColumns()));

    return window.removeEventListener("resize", () =>
      setColumn(() => settingColumns())
    );
  }, [setColumn, settingColumns]);
  const handlePreview = (index, flag) => {
    if (flag) {
      setActiveSelection(index);
    } else {
      setActiveSelection(null);
    }
  };
  const keyBoardActions = (event) => {
    // setActiveSelection((c) => c);
    if (event.keyCode === 27) {
      handlePreview();
    }
    // } else if (event.keyCode === 37) {
    //   handleBackward(event);
    // } else if (event.keyCode === 39) {
    //   handleForward(event);
    // }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyBoardActions);

    return () => {
      document.removeEventListener("keydown", keyBoardActions);
    };
  }, []);
  const handleBackward = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setActiveSelection((c) => c);
    if (activeSelection) {
      let sel = Number(activeSelection);
      if (sel === 0) {
        setActiveSelection(`${data.length - 1}`);
      } else {
        setActiveSelection(`${sel - 1}`);
      }
    }
  };
  const handleForward = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setActiveSelection((c) => c);
    let max = data.length - 1;
    if (activeSelection) {
      let sel = Number(activeSelection);
      console.log(sel);
      console.log(max);
      if (sel === max) {
        setActiveSelection("0");
      } else {
        setActiveSelection(`${sel + 1}`);
      }
    }
  };
  return (
    <div>
      {fromHome === "gallery" && <BreadCrumbsComp section="home" />}
      <Loader open={isActive} />
      {activeSelection && (
        <div className="gallery-preview " onClick={() => handlePreview()}>
          <div className="w-100 p-3 position-relative">
            <img
              src={data[activeSelection][lang]?.images}
              className="w-100 curser-zoomout"
              alt=""
            />
            <div
              className="gallery-arrow left "
              onClick={(e) => handleBackward(e)}
            >
              &lt;
            </div>
            <div
              className="gallery-arrow right "
              onClick={(e) => handleForward(e)}
            >
              &gt;
            </div>
          </div>
        </div>
      )}
      <div>
        {/* ======================= Events  =================================================================== */}
        <div className="container-fluid event-bg">
          <div className="row m-55">
            {/* <h3>Gallery</h3> */}
            <h3>Images</h3>
          </div>
        </div>
        <div className=" mtb-25">
          <div className="container gal-i">
            <Masonry columnsCount={column} gutter="10px">
              {data &&
                data.map((image, i) => (
                  <img
                    key={i}
                    onClick={() => {
                      handlePreview(`${i}`, true);
                    }}
                    src={image[lang]?.images}
                    className="curser-zoomin"
                    style={{ width: "100%", display: "block" }}
                  />
                ))}
            </Masonry>
          </div>
        </div>
        {/* <Footer /> */}
        {/* =================================================================================================== */}
      </div>
    </div>
  );
};
export default memo(GalleryImages);
