import React, { useState, useEffect } from "react";
import CommonForm from "../../../components/commonform/commonForm";
import CustomIcons from "../../../components/icons/kIcons.component";
import { spaceParkAdvisorsList } from "../public.api";
import Loader from "../../../components/loader/loader";

import "./style.css";

export default function List(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const fetchEvents = () => {
    spaceParkAdvisorsList().then((res) => {
      setData(res.data);
      // setUrl(res.current_url);
      setIsActive(false);
    });
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  return (
    <div className="proj">
      <Loader open={isActive} />
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "സ്പേസ്പാർക്ക് ഉപദേശകർ"
              : "Spacepark Advisors "}
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="container adbox mt-3 ">
          <>
            {data?.filter((x) => x.is_executive_council_member === 0)?.length >
              0 && (
              <div className="hedp">Governing body of Kerala Spaceparks</div>
            )}

            <div className="row">
              {data?.map((item) => (
                <>
                  {item.is_executive_council_member === 0 &&
                    item.is_head_position === 1 && (
                      <div className="col-md-12  d-flex justify-content-center">
                        <div className="adviser-cards-container">
                          <center>
                            <div className="card tile">
                              <div className="card-body adv-bg">
                                <img
                                  src={`${result}/${item.photo}`}
                                  alt=""
                                  className="adviser_image chief"
                                />
                                <h6>
                                  <b>{item.name}</b>
                                </h6>
                                <p>{item.designation}</p>
                                <small>{item.email}</small>
                              </div>
                            </div>
                          </center>
                        </div>
                      </div>
                    )}
                </>
              ))}
            </div>
            <div className="row justify-content-center">
              {data?.map((item) => (
                <>
                  {item.is_head_position === 0 &&
                    item.is_executive_council_member === 0 && (
                      <div className="col-12 col-md-3">
                        <div className="adviser-cards-container">
                          <center>
                            <div className="card tile sub">
                              <div className="card-body adv-bg">
                                <img
                                  src={`${result}/${item.photo}`}
                                  alt=""
                                  className="adviser_image"
                                />
                                <h6>
                                  <b>{item.name}</b>
                                </h6>
                                <p>{item.designation}</p>
                                <small>{item.email}</small>
                              </div>
                            </div>
                          </center>
                        </div>
                      </div>
                    )}
                </>
              ))}
            </div>
          </>

          {/* ---------------------------------------------------- */}
          {data?.filter((x) => x.is_executive_council_member === 1)?.length >
            0 && (
            <div className="hedp">Executive Council of Kerala Spaceparks</div>
          )}

          <div className="row">
            {data?.map((item) => (
              <>
                {item.is_executive_council_member === 1 &&
                  item.is_head_position === 1 && (
                    <div className="col-md-12  d-flex justify-content-center">
                      <div className="adviser-cards-container">
                        <center>
                          <div className="card tile">
                            <div className="card-body adv-bg">
                              <img
                                src={`${result}/${item.photo}`}
                                alt=""
                                className="adviser_image chief"
                              />
                              <h6>
                                <b>{item.name}</b>
                              </h6>
                              <p>{item.designation}</p>
                              <small>{item.email}</small>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
          <div className="row justify-content-center">
            {data?.map((item) => (
              <>
                {item.is_head_position === 0 &&
                  item.is_executive_council_member === 1 && (
                    <div className="col-12 col-md-3">
                      <div className="adviser-cards-container">
                        <center>
                          <div className="card tile sub">
                            <div className="card-body adv-bg">
                              <img
                                src={`${result}/${item.photo}`}
                                alt=""
                                className="adviser_image"
                              />
                              <h6>
                                <b>{item.name}</b>
                              </h6>
                              <p>{item.designation}</p>
                              <small>{item.email}</small>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center w-100 py-5"
          style={{ fontSize: "18px" }}
        >
          No Advisors Found
        </div>
      )}
    </div>
  );
}
