import React, { useState } from "react";
import CommonForm from "../../../../../../components/commonform/commonForm";
import "./areastyle.css";
import "./style.css";
export default function JointVentureTable(props) {
  const [formvalues, setFormValues] = useState(props.formvalues);
  const handleChange = (e, item) => {
    let data = [...formvalues];
    let loc = props.data.filter((a) => a.id === parseInt(e.target.value));

    data[item] = {
      ...data[item],
      [e.target.name]: e.target.value,
      location: loc[0].locations,
      area_sub_type_id: loc[0].id,
    };
    setFormValues(data);
    setTimeout(() => {
      props.handleFormData(data);
    }, 100);
  };
  const handleText = (e, item) => {
    let data = [...formvalues];

    data[item] = {
      ...data[item],
      [e.target.name]: e.target.value,
    };
    setFormValues(data);
    setTimeout(() => {
      props.handleFormData(data);
    }, 100);
  };
  const handleNumber = (e, item) => {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(e.target.value)){
    let data = [...formvalues];

    data[item] = {
      ...data[item],
      [e.target.name]: e.target.value,
    };
    setFormValues(data);
    setTimeout(() => {
      props.handleFormData(data);
    }, 100);}
  };
  return (
    <div className="jointv">
      <div>Select Space as per Priority (Select Maximum 3 Locations)</div>

      <div className="table-container">
        <React.Fragment>
          <table className="table-bordered">
            <thead className="bg-col">
              <tr className="bg-col">
                <th className="noFieldTableWidth">No</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="bg-col">
              {/* {formvalues?.map((item, index) => ( */}
              {[...Array(3)].map((e, i) => (
                <tr>
                  <td className="bg-col">{i + 1}</td>
                  <td className="bg-col">
                    <div className="jointx row">
                      <div className="col-md-6">
                        <div className="control-label mylabel">Location</div>
                        <select
                          required
                          name="location_id"
                          disabled={props.disabled}
                          onChange={(e) => handleChange(e, i)}
                          value={formvalues[i]?.location_id ?? ""}
                          id="location"
                          className="form-select selectbox w-100"
                        >
                          <option value=""></option>
                          {props.data &&
                            props.data.map((item) => (
                              <option value={item.id}>{item.locations}</option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-6 ">
                        <div className="control-label mylabel">
                          Enter Area in sq.ft
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control w-100"
                            required
                            disabled={props.disabled}
                            name="area_in_sqft"
                          
                            onChange={(e) => handleNumber(e, i)}
                            value={formvalues[i]?.area_in_sqft ?? ""}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <CommonForm
                          fieldType="multiline"
                          name="investment_model"
                          disabled={props.disabled}
                          label="Investment Model"
                          onChange={(e) => handleText(e, i)}
                          value={formvalues[i]?.investment_model ?? ""}
                          reuired
                        />
                      </div>
                      <div className="col-md-6">
                        <CommonForm
                          fieldType="multiline"
                          name="management_plan"
                          label="Management Plan"
                          disabled={props.disabled}
                          onChange={(e) => handleText(e, i)}
                          value={formvalues[i]?.management_plan ?? ""}
                          reuired
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      </div>
    </div>
  );
}
