import React from "react";
import CustomTable from "../../../../../components/table/table";
import "../style.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

export default function View(props) {
  const handleEdit = () => {};
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  return (
    <div className="demox">
      <div className="conti view-containe">
        <h6>
          <b>Basic Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Company Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.company_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Designation</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.person_designation}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.address ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternative Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.alternate_email ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Abstract Project Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.abstract_project_proposal ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Product Profile</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.product_profiles?.map((item) => (
                <>
                  <div className="col-12 mb-1">
                    <b>{item.product_name ?? ""}</b>
                  </div>
                  <div className="col-12 mb-3 ">
                    <div>{ReactHtmlParser(item.product_desc)}</div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Domain Type</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData?.domain_types && (
                <>
                  {props.basicData?.domain_types?.map((dom, i) => (
                    <>
                    {i === 0 ? (
                      <>{dom.domain_name} {" "}</>
                    ) : (
                      <>|&nbsp;{dom.domain_name} </>
                    )}
                  </>
                  ))}
                </>
              )}
              {(props.basicData?.any_other_flag === "1" ||
                props.basicData?.any_other_flag) && (
                <>{props.basicData?.any_other_domain}</>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Capital Investment in lakhs</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.capital_investment ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Expected Manpower </div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.ex_man_power ?? ""}</div>
          </div>
          {/* <div className="row">
            <div className="col-3">Document Uploaded</div>
            <div className="col-1">:</div>
            <div className="col-7 ">{props.basicData?.file_name}</div>
          </div> */}
          {(props.update === true && typeof props?.basicData?.upload_proposal === "string") ? (
              <div className="row">
                <div className="col-3 field-titles">Document Uploaded</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(
                        `${result}${props?.basicData?.upload_proposal}`,
                        "_blank"
                      );
                    }}
                    className="detailFile"
                  >View</a>
                  
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Document Uploaded</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{props.basicData?.file_name}</div>
              </div>
            )}
        </div>

        <div className="footer-buttons">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(2);
            }}
          >
            GO BACK
          </div>
          <div
            className="primary-btn"
            onClick={(e) => {
              props.action(3);
            }}
          >
            REGISTER
          </div>
        </div>
      </div>
    </div>
  );
}
