import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SearchButton from "../../../components/buttons/search/search-btn";
import CustomTable from "../../../components/table/table";
import TextField from "../../../components/inputs/textfield";
import Snackbar from "../../../components/snackbar/snackbar";
import { admin_company_details, user_company_status } from "../admin.api";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import "./view.css";
import Loader from "../../../components/loader/loader";

export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [url, setUrl] = useState("");
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const eventParams = useParams();
  const handleListData = () => {
    admin_company_details(eventParams.id).then((resp) => {
      setData(resp.data);
      setIsActive(false);
    });
  };
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleDownload = (item) => {
    let path = `${result}${item.upload_proposal}`;
    window.open(path, "_blank");
  };
  useEffect(() => {
    handleListData();
  }, []);
  const activeStatus = (id) => {
    let status = Number(id);
    if (id === 0) {
      return "Pending";
    } else if (id === 1) {
      return "Submitted for Acceptance";
    } else if (id === 2) {
      return "Approved";
    } else if (id === 3) {
      return "Super Admin Reverted";
    } else if (id === 4) {
      return "Rejected";
    } else if (id === 5) {
      return "Reverted";
    } else {
      return null;
    }
  };
  const saMessage = (status) => {
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        console.log(message.value);
        let fdata = {};
        if (adminType === 1) {
          if (status === 1) {
            fdata = {
              status_id: status,
              company_id: data.id,
              admin_message: message.value,
            };
          } else {
            fdata = {
              status_id: status,
              company_id: data.id,
              user_message: message.value,
            };
          }
        } else {
          fdata = {
            status_id: status,
            company_id: data.id,
            super_admin_message: message.value,
          };
        }

        setIsActive(true);
        user_company_status(fdata)
          .then((res) => {
            setSnackMessage(res.message);
            setIsSnackError(false);
            setSnackOpen(true);
            setIsActive(false);
          })
          .catch((error) => {
            setSnackMessage(error.message);
            setIsSnackError(true);
            setSnackOpen(true);
            setIsActive(false);
          });
      }
    });
  };
  const handleAdminActions = (status) => {
    let fdata = { status_id: status, company_id: data.id };
    setIsActive(true);
    user_company_status(fdata)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/international-companies-list");
    }
  };
  const handleEdit = () => {};
  console.log(data);
  console.log(adminType);
  return (
    <div>
      <Loader open={isActive} />

      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Company Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Company Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.company_name}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.person_name}</div>
          </div>
          <div className="row">
            <div className="col-3">Contact Person Designation</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.person_designation}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternative Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.alternate_email}</div>
          </div>
          <div className="row">
            <div className="col-3">Abstract Project Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.abstract_project_proposal}</div>
          </div>
          <div className="row">
            <div className="col-3">Product Profile</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.product_profiles && data.product_profiles.length !== 0 && (
                <>
                  {data.product_profiles.map((item, i) => (
                    <div>
                      <b>{`${i + 1}. ${item.product_name}`}</b>
                      <div>{ReactHtmlParser(item.product_desc)}</div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Domain Type</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.domain_types && data.domain_types.length !== 0 && (
                <>
                  {data.domain_types.map((item, i) => (
                    <div>{`${i === 0 ? "" : " | "} ${item.domain_name}`}</div>
                  ))}
                </>
              )}
              {(data?.any_other_flag === 1 || data?.any_other_domain) && (
                <>{data?.any_other_domain}</>
              )}
            </div>
          </div>
          {/* {(data.any_other_flag === 1 || data.any_other_flag) && (
            <div className="row">
              <div className="col-3">Any Other Domain</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.any_other_domain}</div>
            </div>
          )} */}
          <div className="row">
            <div className="col-3">Uploaded Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <a
                style={{ cursor: "pointer" }}
                onClick={(e) => handleDownload(data)}
              >
                <b>Download File</b>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-3">Capital Investment expected in lakhs</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.capital_investment}</div>
          </div>
          <div className="row">
            <div className="col-3">Expected Manpower</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.ex_man_power}</div>
          </div>
          <div className="current row ">
            <div className="col-3">Current Application Status</div>
            <div className="col-1">:</div>
            <div className="col-7 blink_me1">
              {activeStatus(data?.project_status)}
            </div>
          </div>
          {data?.admin_message && data?.admin_message !== "" && (
            <div className="row">
              <div className="col-3">Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.admin_message}</div>
            </div>
          )}
          {data?.super_admin_message && data?.super_admin_message !== "" && (
            <div className="row">
              <div className="col-3">Super Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.super_admin_message}</div>
            </div>
          )}

          {adminType === 1 && (
            <>
              {(data?.project_status === 0 || data?.project_status === 3) && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(1)}
                  >
                    Submit for Acceptance
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-warning mr-2"
                    onClick={(e) => saMessage(5)}
                  >
                    Revert
                  </button>
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {data?.project_status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(2)}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-warning mx-2"
                    onClick={(e) => saMessage(3)}
                  >
                    Revert
                  </button>

                  <button
                    type="button"
                    className="btn btn-reject  btn-danger "
                    onClick={(e) => handleAdminActions(4)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
