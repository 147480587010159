import React, { useState, useRef } from "react";
import CommonForm from "../../../components/commonform/commonForm";
import { EmailValidate, FormValidate } from "../../../components/formValidate";
import Snackbar from "../../../components/snackbar/snackbar";
import { user_create_proposal } from "../user.api";
import View from "./viewDetails";
import "./style.css";
let required = [
  "name",
  "email",
  "phone_number",
  "address",
  "project_proposal_title",
  "short_details_proposal",
  "file_upload_proposal",
];
export default function Addproposal(props) {
  const [formData, setFormData] = useState({
    phone_number: props.phone,
  });
  const [isActive, setIsActive] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [keyvalue, setKeyvalue] = useState(0);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [activestep, setactivestep] = useState(1);
  const myRef = useRef(null);
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "doc" || type === "docx" || type === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
        // if (props.update) {
        //   props.handlefile();
        // }
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          file_name: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        file_name: null,
        [e.target.name]: null,
      });
    }
  };

  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setEmailError(erroremail);
    }
    let name = e.target.name;
    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.file_upload_proposal === "" ||
      formData.file_upload_proposal === undefined ||
      formData.file_upload_proposal === null
    ) {
      setSnackMessage("Please Choose a valid proposal file");
      setIsSnackError(true);
      setIsActive(false);
      setSnackOpen(true);
    } else {
      const form = new FormData();

      form.append("name", formData.name);
      form.append("email", formData.email ?? null);
      form.append("mobile", formData.phone_number ?? null);
      form.append("address", formData.address ?? null);
      form.append(
        "project_proposal_title",
        formData.project_proposal_title ?? null
      );
      form.append(
        "short_details_proposal",
        formData.short_details_proposal ?? null
      );
      form.append(
        "file_upload_proposal",
        formData.file_upload_proposal ?? null
      );
      setIsActive(true);
      user_create_proposal(form)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          setSnackOpen(true);
          setIsActive(false);
          console.log(form);
        })
        .catch((error) => {
          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setIsActive(false);
          setSnackOpen(true);
        });
    }
    setSubmitted(true);
    console.log("hi");
  };

  const action = (e, id) => {
    myRef.current.scrollIntoView();
    if (id === 1) {
      setactivestep(1);
    } else if (id === 3) {
      handleSubmit(e);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      // setFormData({});
      setactivestep(1);
      props.history.push("/");
    }
  };
  const [submitted, setSubmitted] = useState(false);
  return (
    <div className="proj" ref={myRef}>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Project Proposal</div>
        </div>
      </div>
      <div className="formbg form-bg">
      <div className="container form-section">
      {activestep === 1 && (
        <form onSubmit={handleSubmit}>
          <div className="p-5">
            <CommonForm
              fieldType="text"
              label="Name"
              required
              name="name"
              value={formData.name ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="email"
              label="Email"
              required
              name="email"
              value={formData.email ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="number"
              label="Phone Number"
              disabled
              required
              name="phone_number"
              value={formData.phone_number ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="text"
              label="Address"
              multiline
              required
              name="address"
              value={formData.address ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="text"
              label="Proposal Title"
              required
              name="project_proposal_title"
              value={formData.project_proposal_title ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="text"
              label="Short Details  of Proposal"
              required
              multiline
              name="short_details_proposal"
              value={formData.short_details_proposal ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
            <CommonForm
              fieldType="file"
              required
              label="Upload  Your Proposal"
              name="file_upload_proposal"
              {...props}
              id="upload_proposal"
              accept=".pdf,.doc"
              type="file"
              variant="outlined"
              onChange={handleFileChange}
            />
            <div className=" helpy file-up-helper">
              *Upload only .pdf , .docx , .doc format and Size should be less
              than 5MB
            </div>
            <div className="footer-buttons mt-3">
              <button
                className="btn primary-btn"
                // onClick={(e) => handlePreview(e)}
                type="submit"
                disabled={submitted}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      )}
     
      {activestep === 2 && (
        <View action={action} formData={formData} isActive={isActive} />
      )}
       </div>
      </div>
    </div>
  );
}
