import React, { useEffect } from "react";
import { useState } from "react";
// import CustomTable from "../../../../../components/table/table";
import Loader from "../../../components/loader/loader";

import "./style.css";

export default function View(props) {
  const [isActive, setIsActive] = useState(props.isActive);
  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container mex">
        <div className="pager">
          <h6>
            <b>Basic Details</b>
          </h6>
          <hr />
          <div className="data">
            <div className="row">
              <div className="col-3">Name</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.formData.name}</div>
            </div>
            <div className="row">
              <div className="col-3">Email</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.formData.email}</div>
            </div>
            <div className="row">
              <div className="col-3">Phone Number</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.formData.phone_number}</div>
            </div>
            <div className="row">
              <div className="col-3">Address</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.formData.address}</div>
            </div>
            <div className="row">
              <div className="col-3">Proposal Title</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {props.formData.project_proposal_title}
              </div>
            </div>
            <div className="row">
              <div className="col-3">Short Details of Proposal</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {props.formData.short_details_proposal}
              </div>
            </div>
            <div className="row">
              <div className="col-3">Uploaded Proposal</div>
              <div className="col-1">:</div>
              <div className="col-7">{props.formData.file_name}</div>
            </div>
          </div>

          <hr />

          <div className="footer-buttons mt-3">
            <div
              className="secondary-btn"
              onClick={(e) => {
                props.action(e, 1);
              }}
            >
              GO BACK
            </div>
            <div
              className="primary-btn"
              onClick={(e) => {
                props.action(e, 3);
              }}
            >
              SUBMIT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
