import React, { Component } from "react";

class bannerTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseurl: props.baseUrl,
      data: props.data,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      baseurl: props.baseUrl,
      data: props.data,
    });
  };
  render() {
    const { baseurl, data } = this.state;
    let lang = this.props.state.language;

    return (
      <div className="w-100">
        {data && (
          <div className="banner">
            <div>
              <img
                src={`${data[lang]?.image}`}
                className="w-100 banner-img"
                alt=""
              />
            </div>

            {/* <div className="bannerTitleContainer">
              <div className="banner-text">
                <span className="BannerTitleHighlight">
                  {data[lang]?.highlightText}
                </span>
                <span className="BannerTitle">{data[lang]?.Title}</span>

                <div className="BannerSubTitle">{data[lang]?.LocationText}</div>
              </div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default bannerTile;
