import React, { Component } from "react";
import "./snackbar.style.css";

import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

class Snackbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Message: props.message,
      isError: props.error,
      open: props.open,
      path: props.pathTo,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      Message: props.message,
      isError: props.error,
      open: props.open,
      path: props.pathTo,
    });
  };

  render() {
    const { Message, isError, open, path } = this.state;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="snackbar">
                <div>
                  <div id="title" className={isError ? "error " : "success"}>
                    <div className="snackIconContainer">
                      {isError ? (
                        <AiOutlineCloseCircle className="snackIcon" />
                      ) : (
                        <AiOutlineCheckCircle className="snackIcon" />
                      )}
                    </div>
                  </div>

                  <div className="snackTitle">
                    {isError ? "Oops !" : "Success !"}
                  </div>
                  <div className="snackmessageContainer">
                    <p className="snackbar-msg-p">{Message ?? ""}</p>
                  </div>
                  <div className="snackbuttonContainer">
                    {path === null || path === undefined || path === "" ? (
                      <button
                        className={
                          isError
                            ? "error snackButtonClass"
                            : "success snackButtonClass"
                        }
                        onClick={(e) => this.props.close(isError)}
                      >
                        OK
                      </button>
                    ) : (
                      <Link to={path}>
                        <button
                          className={
                            isError
                              ? "error snackButtonClass"
                              : "success snackButtonClass"
                          }
                          // onClick={(e) => this.props.close(isError)}
                        >
                          CONTINUE
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Snackbar;
