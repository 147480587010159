import React from "react";

import testdata from "./mock-data.json";
import jwtDecode from "jwt-decode";
import "./admin.css";
import DashTile from "../../components/admin/dashtitle/dahtile";
import { dash_info } from "./admin.api";
import { useEffect, useState } from "react";

export default function Admin(props) {
  const [count, setCount] = useState([]);

  const handleEventList = () => {
    dash_info().then((res) => {
      let tdata = res.data;
      console.log(tdata);

      setCount(tdata);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const data1 = [
    {
      label: "New Company Kerala",
      count: count["companies-kerala"],
      link: "/kerala-companies-list",
    },
    {
      label: "New Company India",
      count: count["companies-india"],
      link: "/india-companies-list",
    },
    {
      label: "New Company International",
      count: count["companies-international"],
      link: "/international-companies-list",
    },
    {
      label: "New Company Kspace",
      count: count["companies-kspace"],
      link: "/companies-in-kspace",
    },
    {
      label: "New Applications",
      count: count.new_application,
      link: "/company-request-list",
    },
    // {
    //   label: "For Clarification",
    //   count: 20,
    // },
    {
      label: "Resource Personnel",
      count: count.resource_person,
      link: "/resource-list",
    },
  ];

  const data2 = [
    // {
    //   label: "New Company",
    //   count: 5,
    //   link: "/company-request-list",
    // },
    {
      label: "New Applications",
      count: count.new_application,
      link: "/company-request-list",
    },
    // {
    //   label: "For Clarification",
    //   count: 20,
    // },
    {
      label: "Resource Personnel",
      count: count.resource_person,
      link: "/resource-list",
    },
  ];

  let adminType = Number(jwtDecode(props.state.payload).user_type);
  let data = adminType === 3 ? data2 : data1;
  return (
    <div>
      <div className="admin-dash-data-tiles">
        {data.map((item, index) => (
          <DashTile
            label={item.label}
            count={item.count}
            link={item.link}
            index={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
