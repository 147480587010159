import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import { public_news_details, public_news_list } from "../public.api";
import "./style.css";

export default function Newsdetail(props) {
  let fromHome = sessionStorage.getItem("continueFrom");
  let lang = props.state.language;
  const newsParams = useParams();
  const [data, setData] = useState(props.data);
  const [othNews, setOtherNews] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [url, setUrl] = useState(props.url);
  const fetchEventDetails = (id) => {
    let newsData = [];
    setIsActive(true);
    public_news_details(id).then((res) => {
      newsData = res.data[0];
      setIsActive(false);
      setData(newsData);
      setUrl(res.current_url);
    });
  };
  const fetchOther = () => {
    public_news_list().then((res) => {
      setOtherNews(res.data);
      // setIsActive(false);
      // setUrl(res.current_url);
    });
  };
  useEffect(() => {
    fetchEventDetails(newsParams.id);
  }, []);
  useEffect(() => {
    fetchOther();
  }, []);

  const gotoDetails = (id) => {
    let path = `/news-detail/${id}`;
    props.history.push(path);
    fetchEventDetails(id);
  };
  const handleLink = (linkurl) => {
    let urlLower = linkurl.toLowerCase();
    let urlStart = urlLower.substring(0, 4);
    if (urlStart === "http") {
      window.open(`${urlLower}`, "_blank");
    } else {
      window.open(`//${urlLower}`, "_blank");
    }
  };
  return (
    <>
      {fromHome === "news" && <BreadCrumbsComp section="home" />}
      <Loader open={isActive} />
      <div className="page-bg1 mynews">
        <div>
          <div className="dash-banner">
            <div className="tit-txt">Notification</div>
          </div>
        </div>
        <div className="container newspad">
          <div className="row">
            <div className="col-md-8 deta">
              <h5>{`${data?.[lang]?.Title}`}</h5>
              <p>{`${data?.[lang]?.Description}`}</p>
              {data?.[lang]?.link && (
                <>
                  {" "}
                  <b>For More Details</b>
                  <br />
                  <div className="col-12">
                    <a
                      href="#"
                      onClick={(e) => {
                        // window.open(`//${data[lang]?.link}`, "_blank");
                        handleLink(data[lang]?.link);
                      }}
                      // target="_blank"
                      // rel="noopener"
                    >
                      <p className="newslink">{`${data[lang]?.link}`}</p>
                    </a>
                  </div>
                </>
              )}
            </div>
            {/* <div className="col-md-1 vertical">
        </div> */}
            <div className="col-md-4 morenews">
              <div className="row">
                <h6>Other Notifications</h6>
                <div className="col-12">
                  <hr />
                </div>
                {othNews.map((item) => (
                  <>
                    {item[lang]?.uuid !== newsParams.id && (
                      <div className="col-12 mb-2">
                        <div className="card">
                          <div className="card-body">
                            {item[lang]?.Title}
                            <div className="">
                              <a
                                href="#"
                                onClick={(e) => gotoDetails(item[lang]?.uuid)}
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
