import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  user_company_details,
  user_institutions_detail,
  user_resource_detail,
  user_expert_detail,
  user_get_pplication,
} from "../../../user.api";
import ReactHtmlParser from "react-html-parser";
import CustomTable from "../../../../../components/table/table";

const DashProfileDetails = (props) => {
  console.log(props);
  const [data, setData] = useState({});
  const [userId, setUserId] = useState(props?.data?.user_id);
  const [id, setId] = useState(props?.data?.id);
  const [userRole, setUserRole] = useState(props?.userType);

  // console.log(props?.data?.userType)
  useEffect(() => {
    if (userRole == 4 || userRole == 5 || userRole == 6) {
      user_company_details(userId).then((res) => {
        setData(res?.data);
      });
    } else if (userRole == 8) {
      user_institutions_detail(id).then((res) => {
        setData(res?.data);
      });
    } else if (userRole == 9) {
      user_resource_detail(userId).then((res) => {
        setData(res?.data[0]);
      });
    } else if (userRole == 10) {
      user_expert_detail(userId).then((res) => {
        setData(res?.data[0]);
      });
    } else if (userRole == 0) {
      user_get_pplication().then((res) => {
        setData(res.data);
      });
    }
  }, []);

  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const fileDownload = (data) => {
    let url = `${result}/${data}`;
    window.open(url, "_blank");
  };
  console.log(userRole, data);
  return (
    <>
      <div className="dahContent-header-section">Application Details</div>

      <div className="dash-details-body">
        <div className="dash-datas">
          <div className="dash-items-data">
            {data?.company_name && (
              <div className="row">
                <div className="col-3">Company Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.company_name}</div>
              </div>
            )}
            {data?.category && (
              <div className="row">
                <div className="col-3">Manpower Type</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.category}</div>
              </div>
            )}
            {data?.full_name && (
              <div className="row">
                <div className="col-3">Full Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.full_name}</div>
              </div>
            )}
            {data?.gender && data?.gender !== "" && (
              <div className="row">
                <div className="col-3">Gender</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.gender == 1
                    ? "Male"
                    : data?.gender == 2
                    ? "Female"
                    : "Others"}
                </div>
              </div>
            )}
            {data?.institution_name && (
              <div className="row">
                <div className="col-3">Institution Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.institution_name}</div>
              </div>
            )}
            {data?.headquaters_detail && (
              <div className="row">
                <div className="col-3">HQ Details</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.headquaters_detail}</div>
              </div>
            )}
            {data?.registration_date && (
              <div className="row">
                <div className="col-3">Registration Date</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.registration_date}</div>
              </div>
            )}
            {data?.contact_person_details && (
              <div className="row">
                <div className="col-3">Contact Person Details</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.contact_person_details}</div>
              </div>
            )}
            {data?.file_registration_proof &&
              data?.file_registration_proof !== "" && (
                <div className="row">
                  <div className="col-3">Registration Proof</div>
                  <div className="col-1">:</div>
                  <div className="col-8">
                    <button
                      onClick={() => {
                        fileDownload(data?.file_registration_proof);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}

            {data?.total_students && (
              <div className="row">
                <div className="col-3">Total Students</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.total_students}</div>
              </div>
            )}
            {data?.person_name && (
              <div className="row">
                <div className="col-3">Contact Person Name</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.person_name}</div>
              </div>
            )}
            {data?.age && (
              <div className="row">
                <div className="col-3">Age</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.age}</div>
              </div>
            )}
            {data?.person_designation && (
              <div className="row">
                <div className="col-3">Contact Person Designation</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.person_designation}</div>
              </div>
            )}
            {data?.address && (
              <div className="row">
                <div className="col-3">Address</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.address}</div>
              </div>
            )}
            {data?.email && (
              <div className="row">
                <div className="col-3">Email</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.email}</div>
              </div>
            )}
            {data?.phone_no && (
              <div className="row">
                <div className="col-3">Phone no</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.phone_no}</div>
              </div>
            )}
            {data?.alternate_email && (
              <div className="row">
                <div className="col-3">Alternative Email</div>
                <div className="col-1">:</div>
                <div className="col-8 ovr">{data?.alternate_email}</div>
              </div>
            )}
            {data?.abstract_project_proposal && (
              <div className="row">
                <div className="col-3">Abstract Project Proposal</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.abstract_project_proposal}</div>
              </div>
            )}
            {data?.product_profiles && data?.product_profiles.length > 0 && (
              <div className="row">
                <div className="col-3">Product Profile</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.product_profiles.map((item) => (
                    <>
                      <div className="col-12 mb-1">
                        <b>{item.product_name ?? ""} </b>{" "}
                        {ReactHtmlParser(item.product_desc)}
                      </div>
                      {/* <div className="col-12 mb-3 ">
                          <div>{ReactHtmlParser(item.product_desc)}</div>
                        </div> */}
                    </>
                  ))}
                </div>
              </div>
            )}
            {data?.courses && data?.courses.length > 0 && (
              <div className="row">
                <div className="col-3">Courses Offered</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.courses.map((item) => (
                    <>
                      <div className="col-12 mb-1">
                        <b>{item.course_name ?? ""} </b>{" "}
                      </div>
                      {/* <div className="col-12 mb-3 ">
                          <div>{ReactHtmlParser(item.product_desc)}</div>
                        </div> */}
                    </>
                  ))}
                </div>
              </div>
            )}
            {data?.domain_types && data?.domain_types?.length > 0 && (
              <div className="row">
                <div className="col-3">Domain Type</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.domain_types && (
                    <>
                      {data?.domain_types.map((dom, i) => (
                        <>
                          {i !== 0 && <> |&nbsp; </>}
                          {dom.domain_name}{" "}
                        </>
                      ))}
                    </>
                  )}
                  {(data?.any_other_flag === 1 ||
                    (data?.any_other_flag &&
                      data?.any_other_domain !== "null")) && (
                    <>{data?.any_other_domain}</>
                  )}
                </div>
              </div>
            )}
            {data?.area_of_expertise && data?.domain_types?.length > 0 && (
              <div className="row">
                <div className="col-3">Area of Expertise</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.area_of_expertise && (
                    <>
                      {data?.area_of_expertise.map((dom, i) => (
                        <>
                          {i === 0 ? (
                            <>{dom.expertise_name} </>
                          ) : (
                            <> |&nbsp; {dom.expertise_name} </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.languages && data?.languages?.length > 0 && (
              <div className="row">
                <div className="col-3">Languages Known</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.languages && (
                    <>
                      {data?.languages?.map((dom, i) => (
                        <>
                          {i !== 0 && <> |&nbsp; </>}
                          {dom.language_name}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.area_of_expertise && data?.area_of_expertise?.length > 0 && (
              <div className="row">
                <div className="col-3">Area of Expertise</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.area_of_expertise && (
                    <>
                      {data?.area_of_expertise?.map((dom, i) => (
                        <>
                          {i === 0 ? (
                            <>{dom.expertise_name} </>
                          ) : (
                            <> |&nbsp; {dom.expertise_name} </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
            {data?.education && data?.education?.length > 0 && (
              <div className="row">
                <div className="col-3">Educational Qualifications</div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      {
                        title: "Examination Passed",
                        field: "examination_passed",
                      },

                      {
                        title: "Board / University",
                        field: "board_of_university",
                      },
                      {
                        title: "Year of Pass",
                        field: "year_of_pass",
                      },
                      { title: "Marks / Grade", field: "mark_grade" },
                    ]}
                    data={data?.education ?? []}
                  />
                </div>
              </div>
            )}
            {data?.experience && data?.experience?.length > 0 && (
              <div className="row">
                <div className="col-3">Experience </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      {
                        title: "Organization Name",
                        field: "organization_name",
                      },

                      {
                        title: "Designation",
                        field: "designation",
                      },
                      {
                        title: "Experience",
                        field: "experience",
                      },
                      { title: "Nature of Work", field: "nature_of_work" },
                    ]}
                    data={data?.experience ?? []}
                  />
                </div>
              </div>
            )}
            {data?.capital_investment && (
              <div className="row">
                <div className="col-3">Capital Investment in lakhs</div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.capital_investment ?? ""}</div>
              </div>
            )}
            {data?.travell_interest && (
              <div className="row">
                <div className="col-3">Willing to travel Across india?</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.travell_interest == 0 ? "No" : "Yes"}
                </div>
              </div>
            )}
            {data?.desc_of_current_work && (
              <div className="row">
                <div className="col-3">Current work details</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {data?.desc_of_current_work ?? "--"}
                </div>
              </div>
            )}
            {data?.ex_man_power && (
              <div className="row">
                <div className="col-3">Expected Manpower </div>
                <div className="col-1">:</div>
                <div className="col-8">{data?.ex_man_power ?? ""}</div>
              </div>
            )}
            {data?.file_name && (
              <div className="row">
                <div className="col-3">Document Uploaded</div>
                <div className="col-1">:</div>
                <div className="col-8 ">{data?.file_name}</div>
              </div>
            )}
            {data?.file_name && userRole == 8 && (
              <div className="row">
                <div className="col-3">Institutional Activities</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  <button
                    onClick={() => {
                      fileDownload(data?.file_name);
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            )}
            {data?.notes_institutions && (
              <div className="row">
                <div className="col-3">Institution Activities</div>
                <div className="col-1">:</div>
                <div className="col-8 ">{data?.notes_institutions}</div>
              </div>
            )}
          </div>
          {data?.area_management && data?.area_management?.length > 0 && (
            <div>
              <div className="dahContent-header-section">Area Requirements</div>
            </div>
          )}

          <div className="dash-items-datas">
            {data?.area_type === "built_up_space" && (
              <div className="row">
                <div className="col-3">Built-up space on Rent per Month</div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area in sq.ft", field: "area_in_sqft" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "equipped_facility" && (
              <div className="row">
                <div className="col-3">Equipped Facility per Month </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area in sq.ft", field: "area_in_sqft" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "land_on" && (
              <div className="row">
                <div className="col-3">Land on 33 years Lease </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      {
                        title: "No",
                        field: "area_management_id",
                        type: "dynId",
                      },
                      { title: "Location", field: "location" },
                      { title: "Area  in Acres", field: "area_in_acres" },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
            {data?.area_type === "joint_venture" && (
              <div className="row">
                <div className="col-3">Joint Venture</div>
                <div className="col-1">:</div>
                <div className="col-8 venure">
                  {data?.area_management.map((item, i) => (
                    <div
                      className={`row venture ${
                        i !== data?.area_management.length - 1
                          ? "joint-view-border"
                          : ""
                      }`}
                    >
                      <div className="col-12 mb-1">
                        {/* <label>Location&nbsp;:&nbsp;</label> */}
                        <b>{item.location}</b>
                      </div>
                      <div className="col-12 mb-1">
                        <label>
                          <b>
                            {" "}
                            Area&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                          </b>
                        </label>
                        {item.area_in_sqft}
                      </div>
                      <div className="col-12 mb-1 text-justify">
                        <label>
                          <b>Investment Model</b>&nbsp;:&nbsp;
                        </label>
                        {item.investment_model}
                      </div>
                      <div className="col-12 mb-2 pb-1 text-justify">
                        <label>
                          <b>Management Plan</b>&nbsp;:&nbsp;
                        </label>
                        {item.management_plan}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {data?.area_type === "own_land" && (
              <div className="row">
                <div className="col-3">Own land </div>
                <div className="col-1">:</div>
                <div className="col-8 overflow-auto">
                  <CustomTable
                    className="areatable"
                    {...props}
                    columns={[
                      { title: "Location", field: "location" },
                      { title: "Available Area", field: "area_in_sqft" },
                      { title: "Land Address", field: "land_address" },
                      // { title: "Available Area", field: "area" },
                      {
                        title: "Nature Of Holding",
                        field: "nature_of_holding",
                      },
                    ]}
                    data={data?.area_management ?? []}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="goto-my-profile">
          <div
            className="dashusermyprof"
            onClick={(e) => props.handleMenus("application")}
          >
            My Profile
          </div>
        </div>
      </div>
    </>
  );
};

export default DashProfileDetails;
