import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import { user_company_domain_list, create_company_request } from "../admin.api";

import BasicDetails from "../common/addCompany/addCompany";

import "./style.css";

export default function NewCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [basicData, setBasicData] = useState({});

  const [domainData, setDomain] = useState([]);
  const [addCount, setCount] = useState(1);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      setDomain(res.data);
      setCount(addCount + 1);
      setIsActive(false);
    });
  };
  const action = (id, data, key) => {
    if (id === 0) {
      setBasicData({ ...data });
      //
      setCount(addCount + 1);

      handleSubmit(data);
    }
  };
  const handleSubmit = (data) => {
    const form = new FormData();
    console.log(domainData);
    form.append("company_name", data.company_name ?? null);
    form.append("phone_number", data.mobile_number);
    form.append("company_type", "kerala");
    form.append("person_name", data.person_name ?? null);
    form.append("person_designation", data.person_designation ?? null);
    form.append("address", data.address ?? null);
    form.append("email", data.email ?? null);
    form.append("alternate_email", data.alternate_email ?? "");

    form.append(
      "abstract_project_proposal",
      data.abstract_project_proposal ?? null
    );
    form.append("upload_proposal", data.upload_proposal ?? "");
    form.append("capital_investment", data.capital_investment ?? null);
    form.append("ex_man_power", data.ex_man_power ?? null);
    // form.append("product_profiles", data.product_profiles ?? null);

    form.append("any_other_flag", data.any_other_flag ?? null);
    form.append("any_other_domain", data.any_other_domain ?? null);
    form.append("file_name", data.file_name ?? "");
    if (data.id) form.append("application_id", data.id);
    data.product_profiles.forEach((item, index) => {
      form.append(
        `product_profiles[${index}][product_name]`,
        item.product_name
      );
      form.append(
        `product_profiles[${index}][product_desc]`,
        item.product_desc
      );
    });

    domainData.forEach((item, index) => {
      if (item.checked) {
        form.append(`domain_types[${index}][domain_name]`, item.domain_name);
        form.append(`domain_types[${index}][id]`, item.id);
      }
    });

    setIsActive(true);
    create_company_request(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  useEffect(() => {
    if (domainData.length === 0) {
      getDomainTypes();
    }
    // handleChangedata("3");
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});

      setDomain([]);
      props.history.push("/kerala-companies-list");
    }
  };
  console.log(basicData);
  return (
    <>
      <Loader open={isActive} />
      <BasicDetails
        {...props}
        title="Register a company in Kerala"
        data={basicData}
        action={action}
        formData={basicData}
        setDomain={setDomain}
        domainData={domainData}
      />

      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </>
  );
}
