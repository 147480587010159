import React, { useEffect, useState } from "react";

import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../../components/table/table";
import "./view.css";
import { studentInternList, admin_intern_delete } from "../../admin.api";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleProposalList = () => {
    setIsActive(true);
    studentInternList().then((res) => {
      let tdata = res.data
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      console.log(data)
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleProposalList();
  }, []);
  const handleView = (data) => {
    let path = "/intershiptraining-view/" + data.id;
    props.history.push(path);
  };
  const handleEdit = () => {};
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_intern_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleProposalList();
    }
  };
  // console.log(data[0])
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <h6>
          <b>Internship / Training Requests</b>
        </h6>
        <hr />
        {/* --------------admin----- */}

        <CustomTable
          {...props}
          
          columns={[
            { title: "Full Name", field: "full_name" },
            { title: "Institution" ,type:"intinst" }, //this is a one off way. do not copy this piece of code to reuse
            { title: "Address", field: "address" },
            { title: "Date of Birth", field: "date_of_birth", type: "date" },
            { title: "Status", field: "status", type: "commonStatusInternship" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },

                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  // { type: "delete", handleClick: handleDelete },
                ]
          }
        />

        {/* <CustomTable
            {...props}
            columns={[
              { title: "Proposal Title", field: "project_title" },
              { title: "Project Guide", field: "guide" },
              { title: "Short Detail", field: "detail" },

              { title: "Status", field: "status" },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },

              { type: "delete", handleClick: handleDelete },
            ]}
            handleDownload={handleDownload}
          /> */}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Institution Proposal?"
          data={activeData}
          message="Are you sure to delete this Proposal?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
