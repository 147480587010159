import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/loader";
import Snackbar from "../../../../components/snackbar/snackbar";
import { user_company_domain_list, user_new_request } from "../../user.api";
import {
  builtup,
  euip,
  jointVenture,
  landon,
  ownland,
} from "./components/areadata";
import AreaDetails from "./components/areaDetails";
import BasicDetails from "./components/basicDetails";
import View from "./components/viewDetails";
import "./style.css";

export default function NewCompanyRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultkey, setdefaultkey] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const myRef = useRef(null);
  const [basicData, setBasicData] = useState({
    mobile_number: props.phone,
  });
  const [areaData, setAreaData] = useState({});
  const [domainData, setDomain] = useState([]);
  const [addCount, setCount] = useState(1);
  const [data, setData] = useState({});
  const [openView, setOpenView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [areamange, setareaManage] = useState({ ...builtup, area_type: "" });
  const getDomainTypes = () => {
    setIsActive(true);
    user_company_domain_list().then((res) => {
      setDomain(res.data);
      setCount(addCount + 1);
      setIsActive(false);
    });
  };
  const action = (id, data, key) => {
    myRef.current.scrollIntoView();
    if (id === 0) {
      let dom = [];
      domainData.forEach((item) => {
        if (item.checked)
          dom.push({ domain_name: item.domain_name, id: item.id });
      });
      setActiveStep(1);
      setBasicData({ ...basicData, ...data, domain_types: dom });
      //
    } else if (id === 2) {
      setActiveStep(2);
      setdefaultkey(key);
      setareaManage(data);
      setBasicData({ ...basicData, ...data });
    } else if (id === 1) {
      setareaManage(data);
      setBasicData({ ...basicData, ...data });
      setActiveStep(0);
    } else if (id === 3) {
      setareaManage({
        area_type: basicData.area_type,
        area_management: basicData.area_management,
      });
      // setBasicData({...data})
      setActiveStep(1);
    } else if (id === 4) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    let areatype = [];
    if (basicData.area_management) {
      basicData?.area_management?.forEach((item, index) => {
        if (item.location_id) {
          areatype[index] = item;
        }
      });
    }
    const form = new FormData();

    form.append("company_name", basicData.company_name ?? null);
    form.append("mobile_number", basicData.mobile_number);
    form.append("person_name", basicData.person_name ?? null);
    form.append("person_designation", basicData.person_designation ?? null);
    form.append("address", basicData.address ?? null);
    form.append("email", basicData.email ?? null);
    form.append("alternate_email", basicData.alternate_email ?? "");
    basicData.domain_types.forEach((item, index) => {
      form.append(`domain_types[${index}][domain_name]`, item.domain_name);
      form.append(`domain_types[${index}][id]`, item.id);
    });
    // form.append("domain_types", JSON.stringify(basicData.domain_types) ?? null);
    form.append(
      "abstract_project_proposal",
      basicData.abstract_project_proposal ?? null
    );
    form.append("upload_proposal", basicData.upload_proposal ?? "");
    form.append("capital_investment", basicData.capital_investment ?? null);
    form.append("ex_man_power", basicData.ex_man_power ?? null);
    basicData.product_profiles.forEach((item, index) => {
      // console.log(item)
      form.append(
        `product_profiles[${index}][product_name]`,
        item.product_name
      );
      form.append(
        `product_profiles[${index}][product_desc]`,
        item.product_desc
      );
    });
    // form.append(
    //   "product_profiles",
    //   JSON.stringify(basicData.product_profiles) ?? null
    // );
    form.append("area_type", basicData.area_type ?? null);
    basicData.area_management.forEach((item, index) => {
      console.log(item);

      {
        item.location_id &&
          item.location_id !== null &&
          form.append(
            `area_management[${index}][location_id]`,
            item.location_id
          );
      }
      {
        item.location &&
          item.location !== null &&
          form.append(`area_management[${index}][location]`, item.location);
      }
      {
        item.land_address &&
          item.land_address !== null &&
          form.append(
            `area_management[${index}][land_address]`,
            item.land_address
          );
      }
      {
        item.area_in_sqft &&
          item.area_in_sqft !== null &&
          form.append(
            `area_management[${index}][area_in_sqft]`,
            item.area_in_sqft
          );
      }
      {
        item.investment_model &&
          item.investment_model !== null &&
          form.append(
            `area_management[${index}][investment_model]`,
            item.investment_model
          );
      }
      {
        item.management_plan &&
          item.management_plan !== null &&
          form.append(
            `area_management[${index}][management_plan]`,
            item.management_plan
          );
      }
      {
        item.area_in_acres &&
          item.area_in_acres !== null &&
          form.append(
            `area_management[${index}][area_in_acres]`,
            item.area_in_acres
          );
      }
      {
        item.nature_of_holding &&
          item.nature_of_holding !== null &&
          form.append(
            `area_management[${index}][nature_of_holding]`,
            item.nature_of_holding
          );
      }
      
      {
        item.area_management_id &&
          item.area_management_id !== null &&
          form.append(
            `area_management[${index}][area_management_id]`,
            item.area_management_id
          );
      }

      // form.append(`product_profiles[${index}][product_name]`, item.domain_name);
      // form.append(`product_profiles[${index}][product_desc]`, item.id);
    });
    // form.append("area_management", JSON.stringify(areatype) ?? null);
    form.append("any_other_flag", basicData.any_other_flag ?? 0);
    form.append("any_other_domain", basicData.any_other_domain ?? "");
    form.append("file_name", basicData.file_name ?? "");
    if (basicData.id) form.append("application_id", basicData.id);

    setIsActive(true);
    user_new_request(form)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const navLabel = [
    { id: 0, activeStep: 0, name: "Basic Information" },
    { id: 1, activeStep: 1, name: "Area Requirements" },
  ];

  const headlperText = {
    header: "",
  };
  const handleChangedata = (id) => {
    setdefaultkey(id);
    if (id === "0") {
      setareaManage(builtup);
    } else if (id === "1") {
      setareaManage(euip);
    } else if (id === "2") {
      setareaManage(landon);
    } else if (id === "3") {
      setareaManage(jointVenture);
    } else if (id === "4") {
      setareaManage(ownland);
    }
    setCount(addCount + 1);
  };
  useEffect(() => {
    if (domainData.length === 0) {
      getDomainTypes();
    }
    // handleChangedata("3");
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setActiveStep(0);
      setBasicData({});
      handleChangedata(0);
      setDomain([]);
      props.logout();
    }
  };
  console.log(isSnackError);
  return (
    <div className="page-bg" ref={myRef}>
      <div>
        <Loader open={isActive} />
        <div className="dash-banner ">
          <div className="tit-txt">New Company Proposal</div>
        </div>
      </div>
      <div className="formbg form-bg">
        <div className="container  form-section ">
          <div className="itemc">
            {activeStep < 2 && (
              <>
                <div className="header-helper-text">{headlperText.header}</div>
                <div className="d-flex">
                  {navLabel.map((item, index) => (
                    <div
                      className={
                        index % 2 === 1
                          ? item.activeStep === activeStep
                            ? "headerbuttoneven active"
                            : "headerbuttoneven"
                          : item.activeStep === activeStep
                          ? "headerbuttonodd active"
                          : "headerbuttonodd"
                      }
                    >
                      <div
                        className={
                          index % 2 === 1 ? "indexCont even" : "indexCont "
                        }
                      >
                        {index + 1}
                      </div>
                      <div className="mobw">{item.name}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="fomy form-inner-section ">
              {activeStep === 0 && (
                <BasicDetails
                  {...props}
                  data={basicData}
                  action={action}
                  formData={basicData}
                  setDomain={setDomain}
                  domainData={domainData}
                />
              )}
              {activeStep === 1 && (
                <AreaDetails
                  {...props}
                  key={addCount}
                  data={areaData}
                  defaultkey={defaultkey}
                  handleChangedata={handleChangedata}
                  setareaManage={setareaManage}
                  action={action}
                  areamange={areamange}
                />
              )}
              {activeStep === 2 && (
                <View {...props} basicData={basicData} action={action} />
              )}
            </div>
            <Snackbar
              message={snackMessage}
              error={isSnackError}
              open={snackOpen}
              close={snackbarClose}
            />
            {/* <CommonForm
          action={action}
          buttons={buttons}
          activeStep={activeStep}
          labelData={labelData}
          data={data}
          headlperText={headlperText}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
