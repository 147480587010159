import React, { Component } from "react";
import { BrowserRouter as Router, useLocation, Switch } from "react-router-dom";
import AsyncComponent from "../hoc/async.component";
import UpdateCompanyRequest from "../pages/user/new_user/start_company/company_reg_form_update";
import AdminRoute from "./components/admin";
import AdminAuthRoute from "./components/adminAuth";
import AuthRoute from "./components/auth";
import ErrorRoute from "./components/error";
import PrivateRoute from "./components/private";
import PublicRoute from "./components/public";
import RegRoute from "./components/regroute";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Layout

import Layout from "../layout/userlayout/layout.component";
import AdminLayout from "../layout/adminlayout/layout.component";

// Pages

// Auth Pages
import AdminLogin from "../pages/auth/login/adminlogin";
import Login from "../pages/auth/login/login";
import SignUp from "../pages/auth/login/signup";
import Otp from "../pages/auth/otp/otp";
import Verify from "../pages/auth/verify/verify";

// User Pages

import DashBoard from "../pages/user/dashboard/dashboard";
import Tenders from "../pages/user/tenders/tenders";
import Publications from "../pages/user/publications/publications";
import ProjectProposal from "../pages/user/project_proposal/addproposal";

import ProposalDirectory from "../pages/user/proposaldirectory/directory";
import ProfessionalBodies from "../pages/user/professional_bodies/list";
import ResourcePersonalList from "../pages/user/resource_personal/list";
import ResourcePersonalDetails from "../pages/user/resource_personal/details/details";
import ResourcePersonalReg from "../pages/user/resource_personal/manage/regform";
// -----------
import ExpertManpowerList from "../pages/user/expert_manpower/list";
import ExpertManpowerDetails from "../pages/user/expert_manpower/details/details";
import ExpertManpowerReg from "../pages/user/expert_manpower/manage/regform";
// ----------------
import TrainingReg from "../pages/user/academic_institution/training/manage/regform";
import TrainingList from "../pages/user/academic_institution/training/list";
import Contact from "../pages/user/contact/contact";
import TrainingDetail from "../pages/user/academic_institution/training/detail";
import StudentProposal from "../pages/user/academic_institution/proposal/proposallist";
import InstituteList from "../pages/user/academic_institution/institution/list";
import BusinessProposalList from "../pages/user/project_proposal/list";
import TechTransProposalList from "../pages/user/technology_proposal/list";
import InstituteReg from "../pages/user/academic_institution/institution/manage/regform";
import ProposalReg from "../pages/user/academic_institution/proposal/manage/regform";
import ProposalList from "../pages/user/academic_institution/proposal/list";
// ------------
import News from "../pages/public/latestnews/newsdetail";
import NewCompanyRequest from "../pages/user/new_user/start_company/company_reg_form";
import DirectoryofCompany from "../pages/user/new_user/companies_international/view";
import Registrationkerala from "../pages/user/new_user/companies_kerala/registrationform";
import Directorykerala from "../pages/user/new_user/companies_kerala/view";
import Registrationindia from "../pages/user/new_user/companies_india/registrationform";
import Directoryindia from "../pages/user/new_user/companies_india/view";
import Registrationkspace from "../pages/user/new_user/companies_kspace/registrationform";
import Directorykspace from "../pages/user/new_user/companies_kspace/view";
///  admin ages

import AdminDashboard from "../pages/admin/admin_dashborad";
import RequestList from "../pages/admin/companyRequest/companyRequestlist";
import RequestView from "../pages/admin/companyRequest/companyRequestview";
import SuperRequestList from "../pages/admin/companyRequest/superAdmin/companyRequestview";
import BannerList from "../pages/admin/homepageManagement/banners/bannerList";
import BannerAdd from "../pages/admin/homepageManagement/banners/bannerAdd";
import AccomplishmentList from "../pages/admin/homepageManagement/accomplishment/accomplishmentList";
import ScrollList from "../pages/admin/homepageManagement/scrolling/scrollingList";
import ScopeList from "../pages/admin/homepageManagement/scopeActivity/scopeActivitylist";
import QuoteList from "../pages/admin/homepageManagement/quotes/quotesList";
import MaintextList from "../pages/admin/homepageManagement/aboutusMaintext/maintextList";
import OrganizationList from "../pages/admin/homepageManagement/aboutusOrganization/organizationList";
import DomainList from "../pages/admin/domain/domainList";
import ProfessionalBodiesList from "../pages/admin/professionalBodies/professionalBodiesList";

import CompaniesKeralaList from "../pages/admin/companiesKerala/companiesList";
import CompaniesNewKspace from "../pages/admin/companiesKspace/add";
import CompaniesNewKerala from "../pages/admin/companiesKerala/add";
import CompaniesNewIndia from "../pages/admin/companiesIndia/add";
import CompaniesNewInternational from "../pages/admin/companiesInternational/add";
import CompaniesNewViewKerala from "../pages/admin/companiesKerala/view";
import CompaniesNewViewKspace from "../pages/admin/companiesKspace/view";
import CompaniesNewViewIndia from "../pages/admin/companiesIndia/view";
import CompaniesNewViewInternational from "../pages/admin/companiesInternational/view";
import CompaniesInKspace from "../pages/admin/companiesKspace/companiesUnderKspace";
import CompaniesIndiaList from "../pages/admin/companiesIndia/companiesList";
import CompaniesInternationalList from "../pages/admin/companiesInternational/companiesList";
import ResourceList from "../pages/admin/resourcePerson/resourceList";
import ResourceView from "../pages/admin/resourcePerson/view";
import ResourceAdd from "../pages/admin/resourcePerson/add";
import ManpowerList from "../pages/admin/manpower/manpowerList";
import ManpowerAdd from "../pages/admin/manpower/add";
import ManpowerView from "../pages/admin/manpower/view";
import ProfessionalAdd from "../pages/admin/professionalBodies/add";
import ProfessionalEdit from "../pages/admin/professionalBodies/edit";
import ProfessionalView from "../pages/admin/professionalBodies/view";
import TenderList from "../pages/admin/marketResearch/tender/tenderList";
import CareerList from "../pages/admin/marketResearch/career/careerList";
import PublicationList from "../pages/admin/marketResearch/publication/publicationList";
import ProjectProposalList from "../pages/admin/buisnessIdeas/projectProposal/projectList";
import ProjectProposalView from "../pages/admin/buisnessIdeas/projectProposal/view";
import TransferProposalList from "../pages/admin/buisnessIdeas/transferProposal/transferList";
import TransferProposalView from "../pages/admin/buisnessIdeas/transferProposal/view";
import AcademicInstitutionList from "../pages/admin/academicInstitution/academicInstitution/academicInstitutionList";
import AcademicInstitutionView from "../pages/admin/academicInstitution/academicInstitution/view";
import AcademicInstitutionAdd from "../pages/admin/academicInstitution/academicInstitution/add";
import InstitutionProposalList from "../pages/admin/academicInstitution/institutionProposal/institutionProposalList";
import InstitutionProposalView from "../pages/admin/academicInstitution/institutionProposal/view";
import IntershipTrainingList from "../pages/admin/academicInstitution/intershipTraining/intershipTrainingList";
import IntershipTrainingView from "../pages/admin/academicInstitution/intershipTraining/view";
import IntershipTrainingAdd from "../pages/admin/academicInstitution/intershipTraining/add";
import AreaManagement from "../pages/admin/areamanagement/areamanagement";
import BuiltUp from "../pages/admin/areamanagement/builtupSpace";
import EquipedFacilty from "../pages/admin/areamanagement/euipedfacility";
import LandOnLease from "../pages/admin/areamanagement/landon";
import JoinrVenture from "../pages/admin/areamanagement/jointventure";
import OwnLand from "../pages/admin/areamanagement/ownland";

import EventList from "../pages/admin/events/eventlist";
import EventView from "../pages/admin/events/eventdetails";
import EventBooking from "../pages/admin/events/eventbooking";
import GalleryList from "../pages/admin/gallery/images/images";

import VideoList from "../pages/admin/gallery/videos/videos";
import AdvisorsList from "../pages/admin/advisors/advisorslist";
import AdvisorsAdd from "../pages/admin/advisors/add";
import TechPropAdd from "../pages/admin/buisnessIdeas/transferProposal/add";
import AdvisorsEdit from "../pages/admin/advisors/edit.js";
import AdvisorsView from "../pages/admin/advisors/view";
import AdminView from "../pages/admin/components/view/view";
import Updatereg from "../pages/user/new_user/companies_kspace/updatereg";
import Academyupdate from "../pages/user/academic_institution/institution/manage/updatereg";
import IndiaCompanyRequest from "../pages/user/new_user/companies_india/updaterej";
import keralaCompanyRequest from "../pages/user/new_user/companies_kerala/updatereg";
// Public Pages

import Home from "../pages/public/home/home";
import Events from "../pages/public/events/events";
import GalleryImages from "../pages/public/gallery/gallery";
import Advisors from "../pages/public/advisors/list";
import GoverningBody from "../pages/public/governingBody/list";
import ExecutiveCouncil from "../pages/public/executiveCouncil/list";
import CoreTeam from "../pages/public/coreTeam/list";
import GalleryVideo from "../pages/public/gallery/video";
import Careers from "../pages/public/careers/careers";
import EventDetails from "../pages/public/events/eventsDetails";
import VerifiedRoute from "./components/verifiedRoute";
import Disclaimer from "../pages/public/disclaimer/disclaimer";

import ScrollToTop from "../hoc/scrollToTop";
import Terms from "../pages/public/termsConditions/disclaimer";
import PrivacyPolicy from "../pages/public/privacyPolicy/disclaimer";
import GovOrders from "../pages/public/g0Orders/tenders";
import Resourceupdate from "../pages/user/resource_personal/manage/updatereg";
import Expertupdate from "../pages/user/expert_manpower/manage/updatereg";

// export default function KspaceRouter(props) {
//   let location = useLocation();

class KspaceRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { pathname: null };
  }

  updateRoute(pathname) {
    this.setState({ pathname });
  }
  render() {
    return (
      <>
        <ScrollToTop onRouteChange={this.updateRoute.bind(this)} />
        <Switch>
          {/* Pages that everyone have access */}

          <PublicRoute path="/" exact {...this.props} component={Home} />
          <PublicRoute path="/home" {...this.props} exact component={Home} />
          <PublicRoute
            path="/events"
            {...this.props}
            exact
            component={Events}
          />
          <PublicRoute
            path="/advisors"
            {...this.props}
            exact
            component={Advisors}
          />
          <PublicRoute
            path="/governing-body"
            {...this.props}
            exact
            component={GoverningBody}
          />
          <PublicRoute
            path="/executive-council"
            {...this.props}
            exact
            component={ExecutiveCouncil}
          />
          <PublicRoute
            path="/core-team"
            {...this.props}
            exact
            component={CoreTeam}
          />
          <PublicRoute
            path="/careers"
            {...this.props}
            exact
            component={Careers}
          />
          <PublicRoute
            path="/terms&conditions"
            {...this.props}
            exact
            component={Terms}
          />
          <PublicRoute
            path="/privacy-policy"
            {...this.props}
            exact
            component={PrivacyPolicy}
          />
          <PublicRoute
            path="/orders"
            {...this.props}
            exact
            component={GovOrders}
          />
          <PublicRoute
            path="/disclaimer"
            {...this.props}
            exact
            component={Disclaimer}
          />
          {/* --------------------------- */}

          <PrivateRoute
            path="/training-list"
            {...this.props}
            exact
            component={TrainingList}
          />
          <PublicRoute
            path="/training-detail/:id"
            {...this.props}
            exact
            component={TrainingDetail}
          />
          <PublicRoute
            path="/institute-list"
            {...this.props}
            exact
            component={InstituteList}
          />
          <PublicRoute
            path="/student-proposal-directory"
            {...this.props}
            exact
            component={ProposalDirectory}
          />
          <PublicRoute
            path="/professional-bodies"
            {...this.props}
            exact
            component={ProfessionalBodies}
          />

          <PublicRoute
            path="/contact"
            {...this.props}
            exact
            component={Contact}
          />
          <PublicRoute
            path="/proposal-list"
            {...this.props}
            exact
            component={ProposalList}
          />
          {/* ----------------------- */}
          <PublicRoute
            path="/gallery/images"
            {...this.props}
            exact
            component={GalleryImages}
          />

          <PublicRoute
            path="/gallery/video"
            {...this.props}
            exact
            component={GalleryVideo}
          />
          <PublicRoute
            path="/event/:id"
            {...this.props}
            exact
            component={EventDetails}
          />
          <PublicRoute
            path="/news-detail/:id"
            {...this.props}
            exact
            component={News}
          />
          <PublicRoute
            path="/directory-international"
            {...this.props}
            exact
            component={DirectoryofCompany}
          />
          <PublicRoute
            path="/directory-india"
            {...this.props}
            exact
            component={Directoryindia}
          />
          <PublicRoute
            path="/directory-kerala"
            {...this.props}
            exact
            component={Directorykerala}
          />
          <PublicRoute
            path="/directory-kspace"
            {...this.props}
            exact
            component={Directorykspace}
          />
          {/* /////////////// Verified Users Only */}
          <VerifiedRoute
            path="/submit-proposal"
            component={ProjectProposal}
            exact
            {...this.props}
          />

          {/* //////////////////////////////////////// */}
          <RegRoute
            path="/resource-personal-reg"
            component={ResourcePersonalReg}
            exact
            {...this.props}
          />
          <RegRoute
            path="/expert-manpower-reg"
            component={ExpertManpowerReg}
            exact
            {...this.props}
          />

          {/* <RegRoute
            path="/training-reg"
            component={TrainingReg } exact {...this.props} 
          />   */}
          <RegRoute
            path="/register-kerala"
            component={Registrationkerala}
            exact
            {...this.props}
          />

          <PrivateRoute
            path="/training-reg"
            specialAccess={[8]}
            component={TrainingReg}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/proposal-submit"
            specialAccess={[8]}
            component={ProposalReg}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-kspace"
            // specialAccess={[8]}
            component={Updatereg}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-kerala"
            // specialAccess={[8]}
            component={keralaCompanyRequest}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-india"
            // specialAccess={[8]}
            component={IndiaCompanyRequest}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-academy"
            // specialAccess={[8]}
            component={Academyupdate}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-resource"
            // specialAccess={[8]}
            component={Resourceupdate}
            exact
            {...this.props}
          />
          <PrivateRoute
            path="/update-manpower"
            // specialAccess={[8]}
            component={Expertupdate}
            exact
            {...this.props}
          />
          <RegRoute
            path="/institute-reg"
            component={InstituteReg}
            exact
            {...this.props}
          />

          <RegRoute
            path="/register-india"
            component={Registrationindia}
            exact
            {...this.props}
          />

          <RegRoute
            path="/register-kspace"
            component={Registrationkspace}
            exact
            {...this.props}
          />

          <RegRoute
            path="/new-company"
            component={NewCompanyRequest}
            exact
            {...this.props}
          />

          <PrivateRoute
            path="/dashboard"
            component={DashBoard}
            {...this.props}
            exact
          />
          {/* <PrivateRoute path="/directoryofcompanies" component={DirectoryofCompany } {...this.props} exact /> */}
          <PrivateRoute
            path="/tenders"
            component={Tenders}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/publications"
            component={Publications}
            {...this.props}
            exact
          />

          <PrivateRoute
            path="/resource-personal"
            component={ResourcePersonalList}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/resource-personal/:id"
            component={ResourcePersonalDetails}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/expert-manpower"
            component={ExpertManpowerList}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/business-proposal-list"
            component={BusinessProposalList}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/tech-transfer-proposal-list"
            component={TechTransProposalList}
            {...this.props}
            exact
          />
          <PrivateRoute
            path="/expert-manpower/:id"
            component={ExpertManpowerDetails}
            {...this.props}
            exact
          />

          {/* <PrivateRoute
            path="/resource-personal-reg"
            component={ResourcePersonalReg } {...this.props} exact
          /> */}
          {/* <PrivateRoute path="/news-detail" component={News } {...this.props} exact /> */}

          {/* <PrivateRoute
            path="/new-company"
            component={NewCompanyRequest } {...this.props} exact
          /> */}
          <PrivateRoute
            path="/update-company"
            component={UpdateCompanyRequest}
            {...this.props}
            exact
          />

          <AdminRoute
            path="/professionalbodies-list"
            component={ProfessionalBodiesList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/company-request-list"
            component={RequestList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/superadmin-company-request-view/:id"
            component={SuperRequestList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/company-request-view/:id"
            component={RequestView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/academicinstitution-list"
            component={AcademicInstitutionList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/academicinstitution-view/:id"
            component={AcademicInstitutionView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/institution-add"
            component={AcademicInstitutionAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/institutionproposal-list"
            component={InstitutionProposalList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/institutionproposal-view/:id"
            component={InstitutionProposalView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/intershiptraining-list"
            component={IntershipTrainingList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/intershiptraining-view/:id"
            component={IntershipTrainingView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/intershiptraining-add"
            component={IntershipTrainingAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/banner-list"
            component={BannerList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/banner-add"
            component={BannerAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/scrolling-list"
            component={ScrollList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/accomplishment-list"
            component={AccomplishmentList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/scope-activity-list"
            component={ScopeList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/quotes-list"
            component={QuoteList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/maintext-list"
            component={MaintextList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/organization-list"
            component={OrganizationList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/domain-list"
            component={DomainList}
            {...this.props}
            exact
          />

          <AdminRoute
            path="/kerala-companies-list"
            component={CompaniesKeralaList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/newrequest-application-kspace"
            component={CompaniesNewKspace}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/newrequest-application-kerala"
            component={CompaniesNewKerala}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/newrequest-application-india"
            component={CompaniesNewIndia}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/newrequest-application-international"
            component={CompaniesNewInternational}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/view-application-kerala/:id"
            component={CompaniesNewViewKerala}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/view-application-india/:id"
            component={CompaniesNewViewIndia}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/view-application-international/:id"
            component={CompaniesNewViewInternational}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/view-application-kspace/:id"
            component={CompaniesNewViewKspace}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/companies-in-kspace"
            component={CompaniesInKspace}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/india-companies-list"
            component={CompaniesIndiaList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/international-companies-list"
            component={CompaniesInternationalList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/resource-list"
            component={ResourceList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/resource-view/:id"
            component={ResourceView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/resource-add"
            component={ResourceAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/manpower-list"
            component={ManpowerList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/manpower-add"
            component={ManpowerAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/manpower-view/:id"
            component={ManpowerView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/professional-add"
            component={ProfessionalAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/professional-edit"
            component={ProfessionalEdit}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/professional-view"
            component={ProfessionalView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/tender-list"
            component={TenderList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/career-list"
            component={CareerList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/publication-list"
            component={PublicationList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/project-proposal-list"
            component={ProjectProposalList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/project-proposal-view/:id"
            component={ProjectProposalView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/transfer-proposal-list"
            component={TransferProposalList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/technology-proposal-view/:id"
            component={TransferProposalView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/event-list"
            component={EventList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/event-list/:id"
            component={EventView}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/event-booking"
            component={EventBooking}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/images"
            component={GalleryList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/videos"
            component={VideoList}
            {...this.props}
            exact
          />

          <AdminRoute
            path="/advisors-list"
            component={AdvisorsList}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/advisors-add"
            component={AdvisorsAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/tech-prop-add"
            component={TechPropAdd}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/advisors-edit/:id"
            component={AdvisorsEdit}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/advisors-view/:id"
            component={AdvisorsView}
            {...this.props}
            exact
          />

          <AdminRoute
            path="/builtup-space"
            component={BuiltUp}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/equip-facility"
            component={EquipedFacilty}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/land-lease"
            component={LandOnLease}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/joint-venture"
            component={JoinrVenture}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/own-land"
            component={OwnLand}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/admin-dashboard"
            component={AdminDashboard}
            {...this.props}
            exact
          />
          <AdminRoute
            path="/admin-view"
            component={AdminView}
            {...this.props}
            exact
          />

          <AuthRoute
            path="/registration"
            component={SignUp}
            {...this.props}
            exact
          />
          <AuthRoute path="/login" component={Login} {...this.props} exact />
          <AuthRoute path="/verify" component={Verify} {...this.props} exact />
          <AuthRoute path="/otp" component={Otp} {...this.props} exact />
          <AdminAuthRoute
            path="/admin-login"
            component={AdminLogin}
            {...this.props}
            exact
          />
          <ErrorRoute path="*" {...this.props} />

          {/* ----------------------------------------------------------------------------------------------------------------- */}
        </Switch>
      </>
    );
  }
}
export default KspaceRouter;
