import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import TextField from "../../../../../components/inputs/textfield";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_orgstre_add, admin_org_add } from "../../../admin.api";
import "./dialog.css";

let requiredMembers = [
  "designation_ma",
  "designation_en",
  "title_ma",
  "title_en",
  "parent_id",
  "image",
];
let requiredCore = [
  "designation_ma",
  "designation_en",
  "title_ma",
  "title_en",
  "main_category",
  "main_category_ma",
  "image",
];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      options: props.options,
      isMembers: props.isMembers,
      edit: props.edit,
      submitted: false,
      fileChanged: false,
      isActive: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      options: props.options,
      formData: props.formData,
      isMembers: props.isMembers,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    let required = this.props.isMembers ? requiredMembers : requiredCore;

    const error = FormValidate(this.state.formData, required);
    //
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      const form = new FormData();
      form.append("designation_ma", formData.designation_ma);
      form.append("designation_en", formData.designation_en);
      form.append("title_ma", formData.title_ma);
      form.append("title_en", formData.title_en);
      this.props.isMembers && form.append("parent_id", formData.parent_id);
      !this.props.isMembers &&
        form.append("main_category", formData.main_category);
      !this.props.isMembers &&
        form.append("main_category_ma", formData.main_category_ma);

      form.append("image", this.state.fileChanged ? formData.image : null);
      if (this.props.isMembers) {
        if (formData.id) form.append("organization_id", formData.id);
      } else {
        if (formData.id) form.append("main_cat_id", formData.id);
      }

      if (this.props.isMembers) {
        admin_org_add(form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_orgstre_add(form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              isActive: false,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;

          if (height === 400 || width === 400) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          this.setState({
            fileChanged: true,
            formData: {
              ...this.state.formData,
              [e.target.name]: e.target.files[0],
            },
          });
        } else {
          this.setState({
            snackMessage: "Incorrect image dimension",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }, 200);
    } else {
      this.setState({
        snackMessage: "File should be in .jpg / .jpeg / .png format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleSelect = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        parent_id: e.target.value,
      },
    });
  };
  render() {
    const { submitted, formData, open, edit, options, isActive, isMembers } =
      this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <Loader open={isActive} />
              <div className="big-dia dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {isMembers
                        ? edit
                          ? "Edit Member"
                          : view
                          ? "Member Details"
                          : "Add New Member"
                        : edit
                        ? "Edit Organizational Structure"
                        : view
                        ? "Organizational Structure"
                        : "Add Organizational Structure"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="book-form">
                      <div className="row content-scroll">
                        <React.Fragment>
                          {isMembers ? (
                            <div className="col-12">
                              <label className="control-label mylabel">
                                Structure
                              </label>
                              <select
                                className="form-select input-group.mandatory"
                                aria-label="Default select example"
                                name="main_category"
                                // value={formData.main_category ?? ""}
                                disabled={view}
                                onClick={(e) => this.handleSelect(e)}
                              >
                                <option value={0}>Select Structure</option>
                                {options.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.main_category}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="col-6">
                                <TextField
                                  id="outlined-basic"
                                  onChange={(e) => this.handleChange(e)}
                                  label="Structure Name [EN]"
                                  name="main_category"
                                  disabled={view}
                                  value={formData.main_category ?? ""}
                                  variant="outlined"
                                  required
                                />
                              </div>
                              <div className="col-6">
                                <TextField
                                  id="outlined-basic"
                                  onChange={(e) => this.handleChange(e)}
                                  label="Structure Name [MA]"
                                  name="main_category_ma"
                                  disabled={view}
                                  value={formData.main_category_ma ?? ""}
                                  variant="outlined"
                                  required
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            label={
                              isMembers ? "Name [Eng]" : "Chief Name [Eng]"
                            }
                            name="title_en"
                            disabled={view}
                            value={formData.title_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            label={
                              isMembers ? "Name [Mal]" : "Chief Name [Mal]"
                            }
                            name="title_ma"
                            disabled={view}
                            value={formData.title_ma ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            label="Designation[Eng]"
                            name="designation_en"
                            value={formData.designation_en ?? ""}
                            variant="outlined"
                            disabled={view}
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            label="Designation[Mal]"
                            name="designation_ma"
                            value={formData.designation_ma ?? ""}
                            variant="outlined"
                            disabled={view}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Image(Dimension : width=400,height=400 , format: .jpg, .jpeg, .png)"
                            name="image"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            variant="outlined"
                            disabled={view}
                            // value={formData.banner_image ?? ""}
                            onChange={(e) => this.handleFileChange(e)}
                            required
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
