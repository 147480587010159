import React from "react";
import CustomTable from "../../../../../../components/table/table";
import "../style.css";

export default function View(props) {
  const handleEdit = () => {};

  return (
    <div>
      <div className="view-container">
        <h6>
          <b>Basic Details</b>
        </h6>

        <hr />

        <div className="data">
          <div className="row">
            <div className="col-3">Proposal Title</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.project_title ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Project Guide 1</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.project_guide1 ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Project Guide 2</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.project_guide2 ?? ""}</div>
          </div>
          {/* <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.email ?? ""}</div>
          </div> */}
          <div className="row">
            <div className="col-12">Student Details</div>

            <div className="col-12">
              <>
                <CustomTable
                  {...props}
                  columns={[
                    { title: "Student Name", field: "student_name" },
                    { title: "Semester", field: "semester" },
                    { title: "Branch", field: "branch" },
                    { title: "Previous Year Mark", field: "mark" },
                    { title: "Photo", field: "file_name" },
                  ]}
                  data={props.basicData?.students}
                />
              </>
            </div>
          </div>

          <div className="row">
            <div className="col-3">Short Details of Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {props.basicData.short_proposal_details ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Uploaded your Proposal</div>
            <div className="col-1">:</div>
            <div className="col-7">{props.basicData.file_name ?? ""}</div>
          </div>
        </div>

        <hr />

        <div className="footer-buttons mt-3">
          <div
            className="secondary-btn"
            onClick={(e) => {
              props.action(1);
            }}
          >
            GO BACK
          </div>
          <div
            className="primary-btn"
            onClick={(e) => {
              props.action(2);
            }}
          >
            REGISTER
          </div>
        </div>
      </div>
    </div>
  );
}
