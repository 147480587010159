import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { admin_event_view } from "../admin.api";
import "./view.css";
import jwtDecode from "jwt-decode";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [url, setUrl] = useState("");
  const eventParams = useParams();
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const fetchEventDetails = () => {
    setIsActive(true);
    admin_event_view(eventParams.id).then((res) => {
      setData(res.data);
      setIsActive(false);
      setUrl(res.current_url);
    });
  };
  useEffect(() => {
    fetchEventDetails();
  }, []);

  const handleEdit = () => {};
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container ">
        <h6 className="d-flex align-items-center">
          <button
            style={{
              backgroundColor: "#0a223d",
              color: "#fff",
              border: "solid 1px #0a223d",
            }}
            type="button"
            className="btn btn-warning btn-sm  mr-2"
            onClick={(e) => {
              props.history.push("/event-list/");
            }}
          >
            Back
          </button>
          <b>Event Details</b>
        </h6>

        <hr />
        <div className="data ">
          <div className="row d-flex align-items-center">
            <div className="col-3">Event Name</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.event_title_en}
              <hr />
              {data.event_title_ma}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Organiser Type</div>
            <div className="col-1">:</div>
            <div className="col-7">kSpace</div>
          </div> */}
          <div className="row  d-flex align-items-center">
            <div className="col-3">Organizer Name</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.oraganized_by_en}
              <hr />
              {data.oraganized_by_ma}
            </div>
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-3">Event Description</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.events_desc_en}
              <hr />
              {data.events_desc_ma}
            </div>
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-3">Location</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data.location_en}
              <hr />
              {data.location_ma}
            </div>
          </div>
          {data.event_title_timing && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Event Timing</div>
              <div className="col-1">:</div>
              <div className="col-7">{data.event_title_timing}</div>
            </div>
          )}
          {data.event_date && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Event Date</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {data.event_date
                  ? moment(data.event_date).format("DD-MMM-YYYY")
                  : ""}
              </div>
            </div>
          )}
          {data.booking_start_date && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Event Booking Start Date</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {data.booking_start_date
                  ? moment(data.booking_start_date).format("DD-MMM-YYYY")
                  : ""}
              </div>
            </div>
          )}
          {data.booking_close_date && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Event Booking End Date </div>
              <div className="col-1">:</div>
              <div className="col-7">
                {data.booking_close_date
                  ? moment(data.booking_close_date).format("DD-MMM-YYYY")
                  : ""}
              </div>
            </div>
          )}
          {data.event_organized_type === "kspace" && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Fee</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{data.paid}</div>
            </div>
          )}
          {data.bank_details && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Bank Details</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{data.bank_details}</div>
            </div>
          )}
          {data.event_contact_no && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Contact Number</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{data.event_contact_no}</div>
            </div>
          )}
          {data.total_seats && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Total Seats</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{data.total_seats}</div>
            </div>
          )}
          {data.available_seats && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Available Seats</div>
              <div className="col-1">:</div>
              <div className="col-7 ">{data.available_seats}</div>
            </div>
          )}
          {data.event_image && (
            <div className="row  d-flex align-items-center">
              <div className="col-3">Event Image</div>
              <div className="col-1">:</div>
              <div className="col-7 ">
                <img
                  className="res_photo"
                  src={`${url}/${data.event_image}`}
                  alt="event image"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
